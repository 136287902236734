import { COMPANIES_RESPONSE } from "../actions/companies";

const companiesReducer = (state = [], action) => {
  switch (action.type) {
    case COMPANIES_RESPONSE:
      return action.cart_items;
    default:
      return state;
  }
};

export default companiesReducer;
