import React from "react";
import PropTypes from "prop-types";
import "./AddToCart.styl";
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import Icon from "../Icon/Icon";
import { cBlue } from "../../styles/colors";

// Statuses
const START = "START";
const LOADING = "LOADING";
const ADDED = "ADDED";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";

class AddToCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      added: props.added
    };
    this.interval = null;
  }

  runAnimation() {
    let i = 2;
    this.setState({ status: LOADING });
    if (this.props.onClick) this.props.onClick();
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      switch (i) {
        case 2:
          this.setState({ added: true, status: SUCCESS });
          break;
        case 3:
          this.setState({ status: ADDED });
          break;
        case 4:
          clearInterval(this.interval);
          break;
        case 5:
          this.setState({ status: ERROR });
          break;
      }
      i++;
    }, 500);
  }

  render() {
    const { added, status } = this.state;

    let { className, color, ...buttonProps } = this.props;

    const getColor = {
      START: color,
      ERROR: "red",
      LOADING: added ? "green" : color,
      SUCCESS: "green",
      ADDED: "green"
    };

    delete buttonProps.added;
    const loadingColor = getColor[status] == "grey" ? cBlue : "white";

    const getIcon = {
      START: "shopping-cart",
      ERROR: "exclamation-triangle",
      LOADING: <Loading color={loadingColor} duration="500ms" />,
      SUCCESS: [
        <Icon face="check" className="Icon-check" />,
        <Icon face="cart-plus" className="Icon-cart" />
      ],
      ADDED: [
        <Icon face="check" className="Icon-check" />,
        <Icon face="cart-plus" className="Icon-cart" />
      ]
    };
    const icon = getIcon[status];
    const buttonColor = getColor[status];
    return (
      <div className="AddToCart">
        <Button
          size="fullwidth"
          {...buttonProps}
          className={`status-${status} ${className || ""}`}
          color={buttonColor}
          icon={icon}
          onClick={e => this.runAnimation()}
        />
      </div>
    );
  }
}
AddToCart.defaultProps = {
  status: START,
  color: "grey",
  label: "Add To Cart",
  kind: "primary",
  size: "fullwidth",
  height: "normal",
  added: false
};
AddToCart.propTypes = {
  /** status: string  */
  status: PropTypes.string,
  /** color: string  */
  color: PropTypes.string,
  /** label: string  */
  label: PropTypes.string,
  /** kind: string  */
  kind: PropTypes.string,
  /** size: string  */
  size: PropTypes.string,
  /** height: string  */
  height: PropTypes.string,
  /** added: bool  */
  added: PropTypes.bool
};
export default AddToCart;
