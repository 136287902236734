import Select from "react-select";
import React from "react";
import PropTypes from "prop-types";
import "./SelectMenu.styl";
import { theme } from "./lib/menuStyles";

const SelectMenu = props => {
  const {
    label,
    value,
    placeholder,
    name,
    error,
    disabled,
    className,
    options
  } = props;
  // Is there an error
  let errorElement = "";
  if (error) {
    errorElement = <p className="error-description">{error}</p>;
  }

  const labelElement = label ? <label>{label}</label> : "";
  return (
    <div className={`SelectMenu ${disabled ? "disabled" : ""}`}>
      {labelElement}
      <Select
        {...props}
        classNamePrefix="Select"
        className={`Select ${className || ""}`}
        theme={theme}
        menuPlacement="auto"
        isDisabled={disabled}
      />
      {errorElement}
    </div>
  );
};
SelectMenu.defaultProps = {};
SelectMenu.propTypes = {
  /** value of the input  */
  value: PropTypes.string,
  /** label of the input  */
  label: PropTypes.string,
  /** error of the input  */
  error: PropTypes.string,
  /** placeholder of the input  */
  placeholder: PropTypes.string,
  /** is the input disabled  */
  disabled: PropTypes.bool,
  /** extra styling  */
  className: PropTypes.string,
  /** name, used in form data  */
  name: PropTypes.string,
  /** options array  */
  options: PropTypes.array.isRequired
};
export default SelectMenu;
