export default [
  {
    label: "Date Created",
    key: "created_on",
    sorted: true
  },
  {
    label: "Quote Name",
    key: "quote_name",
    sorted: true
  },
  {
    label: "Quote ID",
    key: "id",
    sorted: true
  },
  {
    label: "Created By",
    key: "created_by",
    sorted: true
  },
  {
    label: "End User",
    key: "end_user",
    sorted: true
  },
  {
    label: "Total Price",
    key: "total_price",
    align: "right",
    sorted: true
  },
  {
    label: "Last Updated",
    key: "last_updated",
    align: "right",
    sorted: true
  },
  {
    label: "Exp Date",
    key: "exp_date",
    align: "right",
    sorted: true
  },
  {
    label: "Status",
    key: "status",
    sorted: true
  }
];
