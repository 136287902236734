import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import "./AccessoriesPanel.styl";

class AccessoriesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="AccessoriesPanel">
        <h2>
          <span>Add Accessories</span>
        </h2>
        <div className="inner">Accessories</div>
      </div>
    );
  }
}
AccessoriesPanel.defaultProps = {};
AccessoriesPanel.propTypes = {};
export default AccessoriesPanel;
