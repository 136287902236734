import React from "react";
import PropTypes from "prop-types";
import "./Loading.styl";

class Loading extends React.Component {
  render() {
    return (
      <div className="Loading">
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke={this.props.color}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur={this.props.duration}
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
Loading.defaultProps = {
  duration: "1s"
};
Loading.propTypes = {
  /** duration: 1s or 200ms */
  duration: PropTypes.string
};
export default Loading;
