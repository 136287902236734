import React from "react";
import PropTypes from "prop-types";
import "./MobileNavIcon.styl";

class MobileNavIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const open = this.props.open
      ? "is-active"
      : "";
    return (<div className="MobileNavIcon" onClick={this.props.onClick}>
      <button className={`hamburger  hamburger--spin js-hamburger ${open}`} type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </div>);
  }
}
MobileNavIcon.defaultProps = {};
MobileNavIcon.propTypes = {
  /** open: bool  */
  // open: PropTypes.bool.isRequired,
};
export default MobileNavIcon;
