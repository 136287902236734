import React from "react";
import PropTypes from "prop-types";
import "./MobileNav.styl";

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="MobileNav">{this.props.children}</div>;
  }
}
MobileNav.defaultProps = {};
MobileNav.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default MobileNav;
