import rootReducer from "./reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { compose, createStore, applyMiddleware } from "redux";

const loggerMiddleware = createLogger();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const appliedMiddleware =
  process.env.NODE_ENV !== "production"
    ? applyMiddleware(thunkMiddleware, loggerMiddleware)
    : applyMiddleware(thunkMiddleware);

const enhancer = composeEnhancers(appliedMiddleware);

export default createStore(rootReducer, enhancer);
