const cBlue = "#2375BB";
const xBlue = "#005394";
const white = "#fff";

const menuStyles = {
  clearIndicator: (provided, state) => {
    // console.log("clearIndicator: ", provided, state);
    return {
      ...provided
    };
  },
  container: (provided, state) => {
    // console.log("container: ", provided, state);
    return {
      ...provided,
      boxSizing: "border-box",
      direction: null,
      pointerEvents: null,
      position: "relative",
      borderColor: cBlue
    };
  },
  group: (provided, state) => {
    // console.log("group: ", provided, state);
    return {
      ...provided
    };
  },
  groupHeading: (provided, state) => {
    // console.log("groupHeading: ", provided, state);
    return {
      ...provided
    };
  },
  indicatorsContainer: (provided, state) => {
    // console.log("indicatorsContainer: ", provided, state);
    return {
      ...provided,
      alignItems: "center",
      alignSelf: "stretch",
      boxSizing: "border-box",
      display: "flex",
      flexShrink: 0
    };
  },
  indicatorSeparator: (provided, state) => {
    // console.log("indicatorSeparator: ", provided, state);
    return {
      ...provided,
      alignSelf: "stretch",
      backgroundColor: "transparent", //"hsl(0, 0%, 80%)",
      boxSizing: "border-box",
      marginBottom: 8,
      marginTop: 8,
      width: 1
    };
  },
  input: (provided, state) => {
    // console.log("input: ", provided, state);
    return {
      ...provided,
      boxSizing: "border-box",
      color: "hsl(0, 0%, 20%)",
      margin: 2,
      paddingBottom: 2,
      paddingTop: 2,
      visibility: "visible",
      borderColor: cBlue
    };
  },
  loadingIndicator: (provided, state) => {
    // console.log("loadingIndicator: ", provided, state);
    return {
      ...provided
    };
  },
  loadingMessage: (provided, state) => {
    // console.log("loadingMessage: ", provided, state);
    return {
      ...provided
    };
  },
  menu: (provided, state) => {
    // console.log("menu: ", provided, state);
    return {
      ...provided,
      backgroundColor: "hsl(0, 0%, 100%)",
      borderRadius: 5,
      boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
      boxSizing: "border-box",
      marginBottom: 8,
      marginTop: 8,
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 99
    };
  },
  menuList: (provided, state) => {
    // console.log("menuList: ", provided, state);
    return {
      ...provided,
      WebkitOverflowScrolling: "touch",
      boxSizing: "border-box",
      maxHeight: 300,
      overflowY: "auto",
      paddingBottom: 4,
      paddingTop: 4,
      position: "relative"
    };
  },
  menuPortal: (provided, state) => {
    // console.log("menuPortal: ", provided, state);
    return {
      ...provided
    };
  },
  multiValue: (provided, state) => {
    // console.log("multiValue: ", provided, state);
    return {
      ...provided
    };
  },
  multiValueLabel: (provided, state) => {
    // console.log("multiValueLabel: ", provided, state);
    return {
      ...provided
    };
  },
  multiValueRemove: (provided, state) => {
    // console.log("multiValueRemove: ", provided, state);
    return {
      ...provided
    };
  },
  noOptionsMessage: (provided, state) => {
    // console.log("noOptionsMessage: ", provided, state);
    return {
      ...provided
    };
  },
  option: (provided, state) => {
    // console.log("option: ", provided, state);
    return {
      ...provided,
      ":active": {
        backgroundColor: xBlue
      },
      ":hover": {
        backgroundColor: cBlue,
        color: "#fff"
      },
      WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      backgroundColor: "#fff",
      boxSizing: "border-box",
      color: "inherit",
      cursor: "default",
      display: "block",
      fontSize: "14px",
      padding: "8px 12px",
      userSelect: "none",
      width: "100%",
      maxHeight: "1000"
    };
  },
  placeholder: (provided, state) => {
    // console.log("placeholder: ", provided, state);
    return {
      ...provided,
      boxSizing: "border-box",
      color: "hsl(0, 0%, 50%)",
      marginLeft: 2,
      marginRight: 2,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)"
    };
  },
  singleValue: (provided, state) => {
    // console.log("singleValue: ", provided, state);
    return {
      ...provided
    };
  },
  valueContainer: (provided, state) => {
    // console.log("valueContainer: ", provided, state);
    return {
      ...provided,
      WebkitOverflowScrolling: "touch",
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      overflow: "hidden",
      padding: "2px 8px",
      position: "relative"
    };
  }
};

const theme = theme => {
  const obj = {
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary25: "#2375BB",
      primary: "#999",
      primary50: "#005394",
      neutral90: "#f00"
    }
  };
  return obj;
};

export {
  menuStyles,
  theme
};
