import React from "react";
import ReactDOM from "react-dom";
import {AppContainer} from "react-hot-loader";
import {Provider} from "react-redux";
import DemoApp from "./DemoApp/DemoApp";
import store from "./store";

const render = Component => {
  ReactDOM.render(<Provider store={store}>
    <Component/>
  </Provider>, document.getElementById("react-root"));
};

render(DemoApp);
if (module.hot && process.env.NODE_ENV === "development") {
  module.hot.accept("./DemoApp/DemoApp", () => {
    // eslint-disable-next-line
    const App = require("./DemoApp/DemoApp").default;

    render(App);
  });
}
