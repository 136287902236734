import React from "react";
import PropTypes from "prop-types";
import "./AddressSearchSuggestions.styl";

class AddressSearchSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data } = this.props;
    const list = data.map((company, i) => {
      return <div key={i}>{company.name}</div>;
    });

    return <div className={`AddressSearchSuggestions`}>{list}</div>;
  }
}
AddressSearchSuggestions.defaultProps = {};
AddressSearchSuggestions.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default AddressSearchSuggestions;
