const filterByTextColumn = (quotes, input, value) => {
  return quotes.filter(quote => {
    if (!quote[input]) return false;
    return quote[input].toLowerCase().search(value.toLowerCase()) > -1;
  });
};

const filterByPrice = (quotes, value) => {
  return quotes.filter(quote => {
    return quote["total_price"].toLowerCase().search(value.toLowerCase()) > -1;
  });
};

const filterByDate = (quotes, input, minDate, maxDate) => {
  return quotes.filter(quote => {
    const columnDate = new Date(quote[input]);
    const minDate = new Date(minDate);
    const max = new Date(max);
    const isMin = minDate ? columnDate >= minDate : true;
    const isMax = max ? columnDate <= max : true;
    return isMax && isMin;
  });
};

const find = (value, searchTerm) => value.toLowerCase().search(searchTerm) > -1;

const filterByTextAll = (quotes, searchTerm) => {
  searchTerm = searchTerm.toLowerCase();
  return quotes.filter(quote => {
    return (
      find(quote["quote_name"], searchTerm) ||
      find(quote["created_by"], searchTerm) ||
      find(quote["quote_id"], searchTerm) ||
      find(quote["end_user"], searchTerm)
    );
  });
};

export default (quotes, filter) => {
  let { input, value } = filter;
  if (!input || !value) return quotes;
  switch (input) {
    case "default":
    case "keyword":
      return filterByTextAll(quotes, value);
    case "quote_name":
    case "created_by":
    case "quote_id":
      return filterByTextColumn(quotes, input, value);
    case "total_price":
      return filterByPrice(quotes, value);
    case "last_updated":
    case "exp_date":
      return filterByDate(quotes, input, value);
  }
};
