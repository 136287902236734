import {
  QUOTE_ACTIVITY_PANEL,
  NOTIFICATION_PANEL,
  NOTES_PANEL,
  ADDRESS_PANEL,
  SWITCH_CART_PANEL,
  SELECT_WAREHOUSE_PANEL,
  SELECT_REPLACEMENT_PANEL,
  SPLIT_LINE_PANEL,
  CART_SETTINGS_PANEL,
  ACCESSORIES_PANEL,
  QUICK_ORDER_PANEL
} from "../actions/panel";

const panelReducer = (state = [], action) => {
  if (action.type === state.open) return { open: null, id: null };
  const { id } = action;
  switch (action.type) {
    case QUICK_ORDER_PANEL:
      return { open: QUICK_ORDER_PANEL, id };
    case QUOTE_ACTIVITY_PANEL:
      return { open: QUOTE_ACTIVITY_PANEL, id };
    case ACCESSORIES_PANEL:
      return { open: ACCESSORIES_PANEL, id };
    case NOTIFICATION_PANEL:
      return { open: NOTIFICATION_PANEL, id };
    case ADDRESS_PANEL:
      return { open: ADDRESS_PANEL, id };
    case SWITCH_CART_PANEL:
      return { open: SWITCH_CART_PANEL, id };
    case SELECT_WAREHOUSE_PANEL:
      return { open: SELECT_WAREHOUSE_PANEL, id };
    case SELECT_REPLACEMENT_PANEL:
      return { open: SELECT_REPLACEMENT_PANEL, id };
    case SPLIT_LINE_PANEL:
      return { open: SPLIT_LINE_PANEL, id };
    case CART_SETTINGS_PANEL:
      return { open: CART_SETTINGS_PANEL, id };
    case NOTES_PANEL:
      return { open: NOTES_PANEL, id };
    default:
      return { open: null, id: null };
  }
};

export default panelReducer;
