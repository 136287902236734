import {
  CARTITEMS_RESPONSE,
  CARTITEMS_ADD,
  CARTITEMS_CHANGE_QTY,
  CARTITEMS_TOGGLE_ACCESSORIES,
  CARTITEMS_ADD_END_USER,
  CARTITEMS_ADD_WAREHOUSE
} from "../actions/cart_items";

const cartItemsReducer = (state = [], action) => {
  const { qty, sku, end_user, warehouse } = action;
  switch (action.type) {
    case CARTITEMS_RESPONSE:
      return action.cart_items;
    case CARTITEMS_CHANGE_QTY:
      return state.map((s, i) => {
        if (s.sku === sku) s.user_qty = qty;
        return s;
      });
    case CARTITEMS_TOGGLE_ACCESSORIES:
      return state.map((item, index) => {
        if (item.sku === sku)
          return { ...item, showAfterRow: !item.showAfterRow };
        return item;
      });
    case CARTITEMS_ADD_END_USER:
      return state.map((item, index) => {
        if (item.sku === sku) return { ...item, end_user };
        return item;
      });
    case CARTITEMS_ADD_WAREHOUSE:
      return state.map((item, index) => {
        if (item.sku === sku) return { ...item, warehouse };
        return item;
      });
    case CARTITEMS_ADD:
      return [action.new_item, ...state];
    default:
      return state;
  }
};

export default cartItemsReducer;
