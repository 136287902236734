import React from "react";
import PropTypes from "prop-types";
import AddQtyToCart from "../AddQtyToCart/AddQtyToCart";
import ProductFlag from "../ProductFlag/ProductFlag";
import InStock from "../InStock/InStock";
import { Currency } from "../../lib/formatters";
import "./ProductListing.styl";
import Icon from "../Icon/Icon";

const flags = (
  <div className="product-flags">
    <ProductFlag label="Special Pricing" color="green" />
    <ProductFlag label="Free Shipping" color="orange" />
  </div>
);

const ProductListing = props => {
  const {
    href,
    imageSrc,
    name,
    price,
    user_price,
    qty,
    user_qty,
    vpn,
    sku,
    showFlags
  } = props;
  return (
    <div className="ProductListing">
      <div className="left">
        <div className="image-wrap">
          <img src={imageSrc} alt={name} />
        </div>
      </div>
      <div className="mid">
        {showFlags && flags}
        <p className="product-name">{name}</p>
        <div className="id-numbers">
          <div className="group">
            <InStock qty={qty} />
            <div>
              <b>VPN:</b>
              {vpn}
            </div>
            <div>
              <b>SKU:</b>
              {sku}
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="price">
          <Currency>{price || user_price}</Currency>
        </div>
        <InStock qty={qty} />
        <AddQtyToCart label="ADD" height="short" qty={1} />
      </div>
    </div>
  );
};
ProductListing.defaultProps = {
  showFlags: true,
  user_qty: 1
};
ProductListing.propTypes = {
  /** string: the url for the link out  */
  href: PropTypes.string,
  /** string: the url for the image  */
  imageSrc: PropTypes.string.isRequired,
  /** number: the price  */
  price: PropTypes.number,
  /** number: the in stock  */
  qty: PropTypes.number,
  /** string: Name of the product  */
  name: PropTypes.string,
  /** bool: Should Product Flags be shown.  */
  showFlags: PropTypes.bool
};
export default ProductListing;
