import React from "react";
import PropTypes from "prop-types";
import "./NotificationItem.styl";
import {TimeAgo} from "../../lib/formatters";

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {subject, thumb, message, quote_id, created_on} = this.props;
    return (<div className={`NotificationItem NotificationId-${quote_id}`}>
      <div className="profile">
        <img src={thumb} alt={subject}/>
      </div>
      <div className="text">
        <div className="subject">{subject}</div>
        <div className="message">{message}</div>
      </div>
      <div className="date">
        <TimeAgo>{created_on}</TimeAgo>
      </div>
    </div>);
  }
}
NotificationItem.defaultProps = {};
NotificationItem.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default NotificationItem;
