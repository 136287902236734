import React from "react";
import PropTypes from "prop-types";
import "./QuoteInfo.styl";
import TextInput from "../TextInput/TextInput";
import DatePicker from "../DatePicker/DatePicker";
import RadioElement from "../RadioElement/RadioElement";
import SelectMenu from "../SelectMenu/SelectMenu";
import MarkupInput from "../MarkupInput/MarkupInput";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

class QuoteInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {created_by, quote_id, exp_date} = this.props.quote;
    return (<div className="QuoteInfo">
      <h4>Quote Info</h4>
      <div className="inputCont col2">
        <label>Quote ID:</label>
        <span>{quote_id || "QUO-GVX11630"}</span>
      </div>
      <div className="inputCont">
        <label>Created by *</label>
        <TextInput placeholder="Created by" value={created_by} required={true}/>
      </div>
      <div className="inputCont">
        <label>Expiration Date *</label>
        <DatePicker value={exp_date}/>
      </div>
      <div className="inputCont">
        <label>Profit Value *</label>
        <div className="col2 profitValue">
          <RadioElement label="Entire Quote"/>
          <RadioElement label="Line Item"/>
        </div>
        <div className="col2 profitValue">
          <MarkupInput/>
        </div>
      </div>
      <div className="inputCont">
        <label>Security</label>
        <div className="col2 security">
          <div className="left">
            <label>Shared quote:</label>
          </div>
          <div className="right">
            <ToggleSwitch/>
          </div>
        </div>
      </div>
    </div>);
  }
}
QuoteInfo.defaultProps = {};
QuoteInfo.propTypes = {};
export default QuoteInfo;
