import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Accordion from "../Accordion/Accordion";
import Icon from "../Icon/Icon";
import "./MobileTable.styl";
import moment from "moment";
import { labels, icons, colors } from "../../lib/statuses";
import { TimeAgo, Currency, DateFormat } from "../../lib/formatters";

class MobileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sortTable = () => {
    const { sortCol, sortAsc, data } = this.props;
    if (!data[0]) return [];
    const keys = Object.keys(data[0]);
    return data.sort((a, b) =>
      a[keys[sortCol]] > b[keys[sortCol]]
        ? sortAsc
          ? 1
          : -1
        : sortAsc
        ? -1
        : 1
    );
  };

  render() {
    const {
      selectable,
      page,
      limit,
      selectedRows,
      beforeRow,
      afterRow
    } = this.props;
    const { dispatch } = this.props;
    const allData = this.sortTable();
    const data = allData.slice((page - 1) * limit, (page - 1) * limit + limit);

    const Top = this.props.top || <div>Top</div>;
    const Bottom = this.props.bottom || <div>Bottom</div>;
    const items = data.map((d, index) => {
      const top = <Top {...d} dispatch={dispatch} />;
      const bottom = (
        <Bottom rowClick={this.props.rowClick} {...d} dispatch={dispatch} />
      );
      return (
        <div className="MobileTableItem" key={index}>
          <Accordion
            visible={top}
            hidden={bottom}
            visibleButton={this.props.visibleButton}
          />
        </div>
      );
    });
    return <div className="MobileTable">{items}</div>;
  }
}
MobileTable.defaultProps = {};
MobileTable.propTypes = {
  /** data: array, tablular data, an array of objects.  Both top and bottom get each object in the array */
  data: PropTypes.array.isRequired,
  /** visibleButton: bool */
  visibleButton: PropTypes.bool,
  /** top: function, returning a react component, to represent the in the visible/top elements in the accordion  */
  top: PropTypes.func,
  /** bottom: function, returning a react component, to represent the hidden/bottom element in the accordion  */
  bottom: PropTypes.func,
  /** sortCol: number  */
  sortCol: PropTypes.number,
  /** page: number  */
  page: PropTypes.number,
  /** limit: number */
  limit: PropTypes.number,
  /** sortAsc: bool  */
  sortAsc: PropTypes.bool
};
export default connect(state => state)(MobileTable);
