import { combineReducers } from "redux";
import paginationReducer from "./pagination";
import quotesReducer from "./quotes";
import productsReducer from "./products";
import bundlesReducer from "./bundles";
import oneQuoteReducer from "./one_quote";
import oneProductReducer from "./one_product";
import quoteLinesReducer from "./quote_lines";
import companiesReducer from "./companies";
import cartItemsReducer from "./cart_items";
import notificationsReducer from "./notifications";
import panelReducer from "./panel";
import filterReducer from "./filters";

export default combineReducers({
  one_quote: oneQuoteReducer,
  one_product: oneProductReducer,
  quotes: quotesReducer,
  bundles: bundlesReducer,
  products: productsReducer,
  quote_lines: quoteLinesReducer,
  cart_items: cartItemsReducer,
  notifications: notificationsReducer,
  pagination: paginationReducer,
  filter: filterReducer,
  activePanel: panelReducer,
  companies: companiesReducer
});
