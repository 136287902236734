import { ONE_PRODUCT_RESPONSE } from "../actions/products";

const oneProductReducer = (state = [], action) => {
  switch (action.type) {
    case ONE_PRODUCT_RESPONSE:
      return action.one_product;
    default:
      return state;
  }
};

export default oneProductReducer;
