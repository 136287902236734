import React from "react";
import PropTypes from "prop-types";
import MobileCart from "../MobileCart/MobileCart";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import "./CartPanel.styl";

class CartPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="CartPanel">
      <h2>
        <div className="switchCarts" onClick={this.props.switchCartClick}>
          <Icon face="sync" color="blue" fontSize={12}/>
          <span>Switch Carts</span>
        </div>
        <span>Cart Name</span>
      </h2>
      <div className="inner">
        <MobileCart data={this.props.data}/>
      </div>
    </div>);
  }
}
CartPanel.defaultProps = {};
CartPanel.propTypes = {};
export default CartPanel;
