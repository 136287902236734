import React from "react"
import PropTypes from "prop-types"
import "./CheckoutFooter.styl"

class CheckoutFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="CheckoutFooter">
      <div className="inner">
        <div className="left">+ SALES TERMS AND CONDITIONS</div>
        <div className="right">Copyright &copy; 2019 Ingram Micro. All rights Reserved.</div>
      </div>
    </div>)
  }
}
CheckoutFooter.defaultProps = {}
CheckoutFooter.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
}
export default CheckoutFooter
