import {NOTIFICATIONS_RESPONSE} from "../actions/notifications";

const notificationsReducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATIONS_RESPONSE:
      return action.notifications;
    default:
      return state;
  }
};

export default notificationsReducer;
