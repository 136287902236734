import React from "react";
import PropTypes from "prop-types";
import "./QuoteEndUser.styl";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";

class QuoteEndUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false
    };
  }
  render() {
    const {on} = this.state;
    return (<div className="QuoteEndUser">
      <div className="col2">
        <div className="left">
          <h4>End User</h4>
        </div>
        <div className="right">
          <ToggleSwitch on={on} onToggle={() => {
              this.setState({
                on: !on
              });
            }}/>
        </div>
      </div>
      <div className={`form ${on
          ? "on"
          : ""}`}>
        <TextInput label="Name"/>
        <TextInput label="Company"/>
        <TextInput label="Address" area={true}/>
        <TextInput label="Phone"/>
        <TextInput label="Email" type="email"/>
        <Button icon="user" label="ADDRESS BOOK" kind="secondary"/>
      </div>
    </div>);
  }
}
QuoteEndUser.defaultProps = {};
QuoteEndUser.propTypes = {};
export default QuoteEndUser;
