import React from "react";
import PropTypes from "prop-types";
import CouponInput from "../CouponInput/CouponInput";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import { Currency } from "../../lib/formatters";
import "./CartTotal.styl";

class CartTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: null
    };
  }

  applyCouponCode = couponCode => {
    this.setState({ couponCode });
  };

  clearCouponCode = () => this.setState({ couponCode: null });

  render() {
    const {
      shippableSubtotal,
      backorderedSubtotal,
      numItems,
      total,
      checkoutClick,
      addToQuoteClick
    } = this.props;

    const { couponCode } = this.state;

    const couponCodeHtml = couponCode ? (
      <div className="couponCode">
        <div className=" col2">
          <span className="codeText">
            <Icon
              face="times"
              onClick={() => {
                this.clearCouponCode();
              }}
            />
            Code&nbsp;{couponCode}
          </span>
          <span className="right">
            <Currency>{-124.67}</Currency>
          </span>
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <div className="CartTotal">
        <div className="inner">
          <h3 className="summary">Summary ({numItems}&nbsp;items)</h3>
          <div className="col2 shipTotal">
            <div className="left">Shippable Product Subtotal</div>
            <div className="right">
              <Currency>{shippableSubtotal}</Currency>
            </div>
          </div>
          <div className="col2 backorderTotal">
            <div className="left">*Backordered Product Subtotal</div>
            <div className="right">
              <Currency>{backorderedSubtotal}</Currency>
            </div>
          </div>
          {couponCodeHtml}
          <div className="col2 total">
            <div className="left">Subtotal</div>
            <div className="right">
              <Currency>{total}</Currency>
            </div>
          </div>
          <div className="notice">
            Congratulations, you qualify for FREE shipping
          </div>
          <div className="buttons">
            <Button
              color="orange"
              label="Checkout"
              onClick={checkoutClick}
              icon="arrow-alt-circle-right"
            />
          </div>
          <div className="continueShopping">
            <a href="/product/J930299110">continue shopping</a>
          </div>
        </div>
        <CouponInput couponCode={couponCode} onApply={this.applyCouponCode} />
      </div>
    );
  }
}
CartTotal.defaultProps = {};
CartTotal.propTypes = {
  /** shippableSubtotal: number  */
  shippableSubtotal: PropTypes.number.isRequired,
  /** backorderedSubtotal: number  */
  backorderedSubtotal: PropTypes.number.isRequired,
  /** total: number  */
  total: PropTypes.number.isRequired,
  /** checkoutClick: function  */
  checkoutClick: PropTypes.func,
  /** addToQuoteClick: function  */
  addToQuoteClick: PropTypes.func
};
export default CartTotal;
