import React from "react";
import PropTypes from "prop-types";
import "./FixedHeader.styl";

class FixedHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  handleScroll = (e) => {
    this.setState({
      visible: window.scrollY >= 426
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {visible} = this.state;
    return (<div className={`FixedHeader ${visible
        ? "visible"
        : ""}`}>
      {this.props.children}
    </div>);
  }
}
FixedHeader.defaultProps = {};
FixedHeader.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default FixedHeader;
