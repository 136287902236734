import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import "./NumberInput.styl";

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0
    };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.value !== prevState.value) return { value: newProps.value };
    else return null;
  }

  onChange = e => {
    let { value } = e.target;
    value = parseInt(value);
    if (value < 0) value = 0;
    if (isNaN(value)) value = 0;
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      this.setState({
        value
      });
    }
  };

  clickUp = e => {
    e.stopPropagation();
    let { value } = this.state;
    value = parseInt(value);
    if (isNaN(value)) value = 0;
    else value = value + 1;
    if (this.props.clickUp) {
      this.props.clickUp(value);
    } else {
      this.setState({
        value
      });
    }
  };

  clickDown = e => {
    e.stopPropagation();
    let { value } = this.state;
    value = parseInt(value);
    if (isNaN(value)) value = 0;
    else value = value - 1;
    if (value < 0) value = 0;
    if (this.props.clickDown) {
      this.props.clickDown(value);
    } else {
      this.setState({
        value
      });
    }
  };

  render() {
    return (
      <div className="NumberInput">
        <input
          type="text"
          value={this.state.value}
          className="input"
          onChange={this.onChange}
        />
        <div className="arrowButtons">
          <Icon
            face="chevron-up"
            color="blue"
            onClick={e => this.clickUp(e)}
            className="up"
          />
          <Icon
            face="chevron-down"
            color="blue"
            onClick={e => this.clickDown(e)}
            className="down"
          />
        </div>
        <div className="mobile">
          <Icon
            face="plus"
            color="blue"
            onClick={e => this.clickUp(e)}
            className="plus"
          />
          <Icon
            face="minus"
            color="blue"
            onClick={e => this.clickDown(e)}
            className="minus"
          />
        </div>
      </div>
    );
  }
}
NumberInput.defaultProps = {};
NumberInput.propTypes = {
  /** something: string  */
  // value: PropTypes.number,
  /** clickUp: function: fires when the up arrow is clicked  */
  clickUp: PropTypes.func,
  /** clickDown: function: fires when the down arrow is clicked  */
  clickDown: PropTypes.func
};
export default NumberInput;
