import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import ProductDetailHeader from "../../components/ProductDetailHeader/ProductDetailHeader";
import Tabs from "../../components/Tabs/Tabs";
import SlidePanel from "../../components/SlidePanel/SlidePanel";
import QuoteLines from "../../components/QuoteLines/QuoteLines";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import ProductRecommendations from "../../components/ProductRecommendations/ProductRecommendations";
import ProductInfo from "../../components/ProductInfo/ProductInfo";
import ProductListing from "../../components/ProductListing/ProductListing";
import Personalization from "../../components/Personalization/Personalization";
import Carousel from "../../components/Carousel/Carousel";
import Accordion from "../../components/Accordion/Accordion";
import SliderItem from "../../components/Carousel/SliderItem";
import "./ProductDetail.styl";
import { getOneProduct, getProducts } from "../../actions/products";
import { Helmet } from "react-helmet";

const TechSpecs = () => {
  return (
    <div className="TechSpecs">
      <div className="left">
        <h5>Power Desciption</h5>
        <div className="spec">
          <div className="left">Operating Power Consumption</div>
          <div className="right">51W</div>
        </div>
        <h5>Warranty</h5>
        <div className="spec">
          <div className="left">Limited Warranty</div>
          <div className="right">36 Month</div>
        </div>
        <h5>Miscellaneous</h5>
        <div className="spec">
          <div className="left">Environmentally Friendly</div>
          <div className="right">Yes</div>
        </div>
        <div className="spec">
          <div className="left">Environmental Certiciation</div>
          <div className="right">RoHS</div>
        </div>
        <div className="spec">
          <div className="left">Package Contents</div>
          <div className="right">
            <ul>
              <li>QHD LCD Monitor</li>
              <li>Stand</li>
              <li>Cables: D-Sub cable, HDMI cable, Power cable</li>
              <li>User Documentation</li>
            </ul>
          </div>
        </div>
        <h5>Interface/Ports</h5>
        <div className="spec">
          <div className="left">HDMI</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">VGA</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">DVI</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">Display Port</div>
          <div className="right">Yes</div>
        </div>
        <h5>Video</h5>
        <div className="spec">
          <div className="left">Native Contrast Ratio</div>
          <div className="right">3,000 : 1</div>
        </div>
        <div className="spec">
          <div className="left">Maximum Resolution</div>
          <div className="right">2560 x 1440</div>
        </div>
        <div className="spec">
          <div className="left">Color Supported</div>
          <div className="right">1.07 Billion Colors</div>
        </div>
        <div className="spec">
          <div className="left">Color Gamut</div>
          <div className="right">sRGB</div>
        </div>
        <div className="spec">
          <div className="left">Tearing Prevention Technology</div>
          <div className="right">Free Sync</div>
        </div>
        <div className="spec">
          <div className="left">Dynamic Contrast Ratio</div>
          <div className="right">20,000,000 : 1</div>
        </div>
        <div className="spec">
          <div className="left">Brightness</div>
          <div className="right">
            250 cd/m<sup>2</sup>
          </div>
        </div>
        <h5>General Information</h5>
        <div className="spec">
          <div className="left">Brand Name</div>
          <div className="right">Philips</div>
        </div>
        <div className="spec">
          <div className="left">Product Name</div>
          <div className="right">QHD LCD Monitor</div>
        </div>
        <div className="spec">
          <div className="left">Marketing Information</div>
          <div className="right">
            <p className="bold">Expanding your experience</p>
            <p>This Phillips 32" monitor with Quad HD 2560 x 1440 pixels.</p>
            <p className="bold">Superb Picture Quality</p>
            <ul>
              <li>Crystal clear images with Quad HD 2560 x 1440 pixels.</li>
              <li>
                1.074 billion colors for smooth color gradations and detail.
              </li>
              <li>
                Effortlessly smooth gameplay with AMD FreeSync<sup>TM</sup>
                technology.
              </li>
              <li>Smart Image gaame mode optimized for gamers</li>
              <li>VA display gives awesome images with wide viewing angle</li>
              <li>Low Bluye Mode for easy on the eyes productivity.</li>
              <li>Less eye fatigue with Flicker-free technology.</li>
            </ul>
            <p className="bold">Features designed for you</p>
            <ul>
              <li>HDMI-ready for Full HD entertainment</li>
              <li>Display Port connection for maximum visuals</li>
            </ul>
            <p className="bold">Greener everyday</p>
            <ul>
              <li>Eco-friendly materials meet major international standards</li>
              <li>Low power consumption saves energy</li>
              <li>Mercury Free eco-friendly display</li>
            </ul>
          </div>
        </div>
        <div className="spec">
          <div className="left">Manufacturer Part Number</div>
          <div className="right">326E8FJSB</div>
        </div>
        <div className="spec">
          <div className="left">Prodcut Line</div>
          <div className="right">E-Line</div>
        </div>
        <div className="spec">
          <div className="left">Product Type</div>
          <div className="right">LCD Monitor</div>
        </div>
        <div className="spec">
          <div className="left">Manufacturer Website Address</div>
          <div className="right">
            <a href="http://phillips.com">https://phillips.com</a>
          </div>
        </div>
        <div className="spec">
          <div className="left">Product Model</div>
          <div className="right">326E8FJSB</div>
        </div>

        <h5>Physical Characteristics</h5>
        <div className="spec">
          <div className="left">Height</div>
          <div className="right">17.09" (434 mm)</div>
        </div>
        <div className="spec">
          <div className="left">Weight with Stgand (Approximate)</div>
          <div className="right">6.77 kg</div>
        </div>
        <div className="spec">
          <div className="left">Width</div>
          <div className="right">28.78" (731 mm)</div>
        </div>
        <div className="spec">
          <div className="left">Color</div>
          <div className="right">Glossy Black</div>
        </div>
        <div className="spec">
          <div className="left">Weight (Approximate)</div>
          <div className="right">6.46 kg</div>
        </div>
        <div className="spec">
          <div className="left">Width with Stand</div>
          <div className="right">28.78" (731 mmm)</div>
        </div>
        <div className="spec">
          <div className="left">Depth</div>
          <div className="right">2.13" (54 mm)</div>
        </div>
        <div className="spec">
          <div className="left">Depth with Stand</div>
          <div className="right">8.78" (223 mm)</div>
        </div>
        <div className="spec">
          <div className="left">Height with Stand</div>
          <div className="right">20.83" (529 mm)</div>
        </div>
      </div>

      <div className="right">
        <h5>Technical Information</h5>
        <div className="spec">
          <div className="left">Screen Size</div>
          <div className="right">31.5"</div>
        </div>
        <div className="spec">
          <div className="left">Response Time</div>
          <div className="right">5 ms GTG</div>
        </div>
        <div className="spec">
          <div className="left">Number of Screens</div>
          <div className="right">1</div>
        </div>
        <div className="spec">
          <div className="left">Pixel Pitch</div>
          <div className="right">10.75 mil (0.27 mm)</div>
        </div>
        <div className="spec">
          <div className="left">Screen Mode</div>
          <div className="right">WQHD</div>
        </div>
        <div className="spec">
          <div className="left">Vertical Frequency</div>
          <div className="right">50 Hz to 76 Hz</div>
        </div>
        <div className="spec">
          <div className="left">Horizontal Frequency</div>
          <div className="right">30 kHz to 99 kHz</div>
        </div>
        <div className="spec">
          <div className="left">Backlight Technology</div>
          <div className="right">WLED</div>
        </div>
        <div className="spec">
          <div className="left">Aspect Ratio</div>
          <div className="right">16:9</div>
        </div>
        <h5>Basic</h5>
        <div className="spec">
          <div className="left">Environmental Certification</div>
          <div className="right">RoHS</div>
        </div>
        <div className="spec">
          <div className="left">Display Port</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">Response Time</div>
          <div className="right">5 ms GTG</div>
        </div>
        <div className="spec">
          <div className="left">Product Type</div>
          <div className="right">LCD Monitor</div>
        </div>
        <div className="spec">
          <div className="left">Screen Mode</div>
          <div className="right">WQHD</div>
        </div>
        <div className="spec">
          <div className="left">Color</div>
          <div className="right">Glossy Black</div>
        </div>
        <div className="spec">
          <div className="left">Brightness</div>
          <div className="right">
            250 cd/m<sup>2</sup>
          </div>
        </div>
        <div className="spec">
          <div className="left">Backlight Technology</div>
          <div className="right">WLED</div>
        </div>
        <div className="spec">
          <div className="left">Screen Size</div>
          <div className="right">31.5"</div>
        </div>
        <div className="spec">
          <div className="left">HDMI</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">Maximum Resolution</div>
          <div className="right">2560 x 1440</div>
        </div>
        <div className="spec">
          <div className="left">Color Supported</div>
          <div className="right">1.07 Billion Colors</div>
        </div>
        <div className="spec">
          <div className="left">Aspect Ratio</div>
          <div className="right">16:9</div>
        </div>
        <div className="spec">
          <div className="left">DVI</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">VGA</div>
          <div className="right">Oui</div>
        </div>
        <div className="spec">
          <div className="left">Brand Name</div>
          <div className="right">Phillips</div>
        </div>
        <div className="spec">
          <div className="left">Environmental Certification</div>
          <div className="right">WEEE</div>
        </div>
        <div className="spec">
          <div className="left">Environmentally Friendly</div>
          <div className="right">Yes</div>
        </div>
        <div className="spec">
          <div className="left">Number of Screens</div>
          <div className="right">1</div>
        </div>
      </div>
    </div>
  );
};

const PricingInfo = () => (
  <div className="PricingInfo">
    <p className="bold">Price Conditions</p>
    <p>There are no Price Conditions to display.</p>
    <p className="bold">Limitations And Restrictions</p>
    <p>There are no Limitations or Restrictions to display.</p>
  </div>
);

const Description = () => (
  <div className="Description">
    <p className="bold">Expand your experience</p>
    <p>
      This Philips 32" monitor boasts Quad HD picture quality for superb detail.
      With an expensive crystal-clear QHD wide viewing angle display, see the
      big picture with all the details.
    </p>
    <p className="bold">Superb Picture Quality</p>
    <ul>
      <li>Crystalclear images with Quad HD 2560 x 1440 pixels.</li>
      <li>1.074 nillion colors for smooth</li>
      <li>
        Effortlessly smooth gameplay with AMD FreSync<sup>TM</sup> technology
      </li>
      <li>SmartImage game mode optimized for gamers</li>
      <li>VA display gives awesome images with wide viewing angle</li>
      <li>Low Blue Mode for easy on the yees productivity</li>
      <li>Less eye fatigue with Flicker-free technology</li>
    </ul>
    <p className="bold">Features designed for you</p>
    <ul>
      <li>HDMI-ready for full HD entertainment</li>
      <li>DisplayPort connection for miximum visuals</li>
    </ul>
    <p className="bold">Greener everyday</p>
    <ul>
      <li>Eco-friendly materials meet major international standards</li>
      <li>Low power consumption saves energy bills</li>
      <li>mercury Free eco-friendly display</li>
    </ul>
  </div>
);

const Warranty = () => (
  <div className="Warranty">
    <p className="bold">Warranty</p>
    <p>Warranty information</p>
  </div>
);

const ProductDescription = () => {
  return (
    <div className="ProductDescription">
      <Accordion
        open={true}
        visible={<h3> Description</h3>}
        hidden={<Description html={"a"} />}
      />
      <Accordion
        visible={<h3> Technical Specs</h3>}
        hidden={<TechSpecs />}
        open={true}
      />
      <Accordion
        visible={<h3> Package & Warranty Information</h3>}
        hidden={<Warranty />}
      />
      <Accordion
        visible={<h3> Government & Educational Pricing</h3>}
        hidden={<PricingInfo />}
      />
    </div>
  );
};

const Bundles = () => {
  return (
    <div className="Bundles">
      <Carousel slidesToShow={4} slidesToScroll={1}>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
        <SliderItem>
          <ProductListing
            name="NETGEAR – BASIC CBU INSIGHT 8P 1G HI-POE+ CLOUD SW POE+ APP"
            imageSrc="/images/products/bundle1.png"
            price={140.98}
            qty={120}
            vpn={"CBV21RT-9N"}
            sku={"8X9980"}
          />
        </SliderItem>
      </Carousel>
    </div>
  );
};

const Accessories = props => {
  const { products } = props;
  return (
    <div className="Accessories">
      <Carousel slidesToShow={4} slidesToScroll={1}>
        {products.map((product, i) => {
          return (
            <SliderItem key={i}>
              <ProductListing {...product} />
            </SliderItem>
          );
        })}
      </Carousel>
    </div>
  );
};

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // activityPanelOpen: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.dispatch(getOneProduct(id));
    this.props.dispatch(getProducts());
  }

  toggleActivityPanel = () => {
    this.setState({
      // activityPanelOpen: !this.state.activityPanelOpen
    });
  };

  render() {
    const { one_product, products, history } = this.props;
    return (
      <div className="ProductDetail">
        <Helmet title="Ingram Micro - Product" />
        <Header history={history} />
        <ProductDetailHeader {...one_product} />
        <main>
          <div className="col2">
            <div className="left">
              <ProductCarousel />
            </div>
            <div className="right">
              <ProductInfo {...one_product} />
              <Tabs tabs={["Bundles", "Accessories"]} activeTab={0}>
                <Bundles />
                <Accessories products={products} />
              </Tabs>
            </div>
          </div>
          <ProductDescription />
          <ProductRecommendations
            heading="Recently Viewed"
            products={products}
          />
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(state => state)(ProductDetail);
