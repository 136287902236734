import API_BASE_URL from "../lib/apiEndpoint";

export const CARTITEMS_RESPONSE = "CARTITEMS_RESPONSE";
export const CARTITEMS_ADD = "CARTITEMS_ADD";
export const CARTITEMS_ADD_END_USER = "CARTITEMS_ADD_END_USER";
export const CARTITEMS_CHANGE_QTY = "CARTITEMS_CHANGE_QTY";
export const CARTITEMS_TOGGLE_ACCESSORIES = "CARTITEMS_TOGGLE_ACCESSORIES";
export const CARTITEMS_ADD_WAREHOUSE = "CARTITEMS_ADD_WAREHOUSE";

export const cartItemsResponse = data => ({
  type: CARTITEMS_RESPONSE,
  cart_items: data
});

export const cartItemsChangeQty = (sku, qty) => ({
  type: CARTITEMS_CHANGE_QTY,
  sku,
  qty
});

export const cartItemsToggleAccessories = sku => ({
  type: CARTITEMS_TOGGLE_ACCESSORIES,
  sku
});

export const cartItemsAddEndUser = end_user => {
  return (dispatch, getStore) => {
    const store = getStore();
    dispatch(cartItemsAddEndUserWId(end_user, store.activePanel.id));
    return;
  };
};

export const cartItemsAddEndUserWId = (end_user, id) => ({
  type: CARTITEMS_ADD_END_USER,
  end_user,
  sku: id
});

export const cartItemsAddWarehouse = (warehouse, id) => ({
  type: CARTITEMS_ADD_WAREHOUSE,
  warehouse,
  sku: id
});

export const getCartItems = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/cart_items`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(cartItemsResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};

export const addCartItem = item => {
  const new_item = item;
  return { type: CARTITEMS_ADD, new_item };
};
