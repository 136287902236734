import { BUNDLES_RESPONSE } from "../actions/bundles";

const bundlesReducer = (state = [], action) => {
  switch (action.type) {
    case BUNDLES_RESPONSE:
      return action.bundles;
    default:
      return state;
  }
};

export default bundlesReducer;
