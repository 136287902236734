import {ONE_QUOTE_RESPONSE} from "../actions/quotes";

const oneQuoteReducer = (state = [], action) => {
  switch (action.type) {
    case ONE_QUOTE_RESPONSE:
      return action.one_quote;
    default:
      return state;
  }
};

export default oneQuoteReducer;
