import React from "react";
import Notifier from "../components/Notifier/Notifier";
import { selectReplacementPanel, splitLinePanel } from "../actions/panel";

export const rowNotices = (data, dispatch) => {
  let notifyHtml = [];
  if (data.warehouse && data.user_qty > data.warehouse.qty) {
    notifyHtml.push(
      <Notifier
        key="1"
        heading="Out of Stock"
        message={
          <div>
            Your requested quantity is greater than available stock. You can:{" "}
            <a href="#" onClick={() => dispatch(selectReplacementPanel())}>
              Select a replacement
            </a>{" "}
            or{" "}
            <a href="#" onClick={() => dispatch(splitLinePanel())}>
              Split the line.
            </a>
          </div>
        }
        status="warn"
      />
    );
  }
  if (!data.warehouse || !data.warehouse.name) {
    notifyHtml.push(
      <Notifier
        key="2"
        heading="Attention"
        message="Selection of warehouse location is required."
        status="error"
      />
    );
  }
  return notifyHtml;
};
