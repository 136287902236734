import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon/Icon";
import classnames from "classnames";
import "./SlidePanel.styl";

class SlidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, leftSide, className } = this.props;
    const classes = classnames({ open, leftSide });
    return (
      <div
        className={`SlidePanel ${classes} ${className || ""}`}
        onScroll={e => {
          e.cancelBubble = true;
        }}
      >
        <div className="close">
          <Icon face="times" onClick={() => this.props.closeClick()} />
        </div>
        <div className="inner">{this.props.children}</div>
      </div>
    );
  }
}
SlidePanel.defaultProps = {
  open: false,
  leftSide: false
};
SlidePanel.propTypes = {
  /** open: bool, is the panel visible  */
  open: PropTypes.bool,
  /** leftSide: bool, is the panel visible  */
  leftSide: PropTypes.bool
};
export default SlidePanel;
