import {QUOTELINES_RESPONSE} from "../actions/quote_lines";

const quoteLinesReducer = (state = [], action) => {
  switch (action.type) {
    case QUOTELINES_RESPONSE:
      return action.quote_lines;
    default:
      return state;
  }
};

export default quoteLinesReducer;
