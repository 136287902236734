import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import "./CheckoutHeader.styl";

const logo = require("../../../statics/images/logo.svg");

class CheckoutHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="CheckoutHeader">
        <div className="topLine">
          <div className="right">
            Contact us at
            <a href="mailto: help@ingrammicro.com">help@ingrammicro.com</a> or
            <a href="tel:382-094-1444">382-094-1444</a>
          </div>
        </div>
        <div className="midLine">
          <div className="left">
            <div
              className="logo"
              dangerouslySetInnerHTML={{
                __html: logo
              }}
            />
            <h2 className="heading">{this.props.heading}</h2>
          </div>
        </div>
        <div className="botLine">
          <div className="left">
            <div className="navButton">
              <Button
                kind="tertiary"
                size="large"
                icon="arrow-left"
                label={this.props.backLabel}
                href={this.props.backHref}
              />
            </div>
          </div>
          <div className="right">
            <Button label="Hold Order" icon="clock" kind="tertiary" />
            <Button label="Cancel Order" icon="trash-alt" kind="tertiary" />
          </div>
        </div>
      </div>
    );
  }
}
CheckoutHeader.defaultProps = {};
CheckoutHeader.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default CheckoutHeader;
