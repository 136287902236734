import React from "react";
import PropTypes from "prop-types";
import TextInput from "../TextInput/TextInput";
import SelectMenu from "../SelectMenu/SelectMenu";

import "./MarkupInput.styl";

const currencies = [
  {
    label: "USD",
    value: "USD"
  }, {
    label: "%",
    value: "PCT"
  }
];

class MarkupInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {value} = this.props;
    return (<div className="MarkupInput">
      <TextInput value={value} className="noRightSide"/>
      <SelectMenu options={currencies} className="noLeftSide" placeholder="USD" initialValue="USD" isSearchable={false}/>
    </div>);
  }
}
MarkupInput.defaultProps = {};
MarkupInput.propTypes = {
  /** value: number  */
  value: PropTypes.number
};
export default MarkupInput;
