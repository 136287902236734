import React from "react"
import PropTypes from "prop-types"
import "./SuggestedCategories.styl"

class SuggestedCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="SuggestedCategories">
      <h3>Suggested Categories</h3>
      <ol>
        <li>Laptops > Apple</li>
        <li>Laptop Computers</li>
        <li>Desktop Computers</li>
        <li>Communications</li>
        <li>Tablet PCs</li>
        <li>Computer Systems</li>
        <li>Mobile Phones</li>
      </ol>
    </div>) } } SuggestedCategories.defaultProps = {}
    SuggestedCategories.propTypes = {
      /** something: string  */
      // something: PropTypes.string.isRequired,
    }
    export default SuggestedCategories