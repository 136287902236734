import React from "react";
import PropTypes from "prop-types";
import "./ProductDetailHeader.styl";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Button from "../../components/Button/Button";
import ProductFlag from "../../components/ProductFlag/ProductFlag";

const SubNav = props => (
  <Breadcrumbs
    trail={[
      {
        link: "/",
        label: "Home"
      },
      {
        link: "/products",
        label: "Products"
      },
      {
        link: "/products/monitors",
        label: "Computer Monitors"
      },
      {
        link: "/companies/1",
        label: "Lenovo"
      },
      {
        link: null,
        label: "Lenovo ThinkPad"
      }
    ]}
  />
);

class ProductDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { name, vendor_name, vpn, sku, upc } = this.props;
    return (
      <div className="ProductDetailHeader">
        <div className="inner">
          <div className="left">
            <SubNav />
          </div>
          <div className="right">
            <div className="actionButtons">
              <Button
                kind="tertiary"
                label="Add To Favorites"
                icon="heart"
                color="red"
                iconStyle="light"
              />
              <Button
                kind="tertiary"
                label="Email"
                icon="envelope"
                color="blue"
                iconStyle="light"
              />
              <Button
                kind="tertiary"
                label="Print"
                icon="print"
                color="blue"
                iconStyle="light"
              />
            </div>
          </div>
        </div>
        <div className="inner">
          <div className="left">
            <h1 className="productName">{name}</h1>
            <div className="productSkus">
              <span>
                By&nbsp;
                <a href="#">{vendor_name}</a>
              </span>
              <span>
                <b>VPN:&nbsp;</b>
                {vpn}
              </span>
              <span>
                <b>UPC:&nbsp;</b>
                {upc}
              </span>
              <span>
                <b>SKU:&nbsp;</b>
                {sku}
              </span>
            </div>
          </div>
          <div className="right">
            <div className="productFlags">
              <ProductFlag label="Special Pricing" color="green" />
              <ProductFlag label="Clearance" color="red" />
              <ProductFlag label="Return Limitation" color="yellow" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ProductDetailHeader.defaultProps = {};
ProductDetailHeader.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default ProductDetailHeader;
