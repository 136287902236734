import React from "react";
import PropTypes from "prop-types";
import MobileSwitchCarts from "../MobileSwitchCarts/MobileSwitchCarts";
import "./SwitchCartPanel.styl";

class SwitchCartsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="SwitchCartsPanel">
        <h2>Switch Carts</h2>
        <div className="inner">
          <MobileSwitchCarts data={this.props.data} />
        </div>
      </div>
    );
  }
}
SwitchCartsPanel.defaultProps = {};
SwitchCartsPanel.propTypes = {};
export default SwitchCartsPanel;
