import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../NumberInput/NumberInput";
import AddToCart from "../AddToCart/AddToCart";
import {Currency} from "../../lib/formatters";
import "./ProductSuggestion.styl";

class ProductSuggestion extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      imageSrc,
      vpn,
      sku,
      name,
      user_price,
      qty
    } = this.props;

    return (<div className="ProductSuggestion">
      <div className="group">
        <div className="image-wrap">
          <img src={imageSrc} alt={name}/>
        </div>
      </div>
      <div className="info">
        <p className="product-name small">{name}</p>
        <div className="id-numbers">
          <span>VPN:&nbsp;</span>
          <span>{vpn}</span>
          <span className="spacer">|</span>
          <span>SKU:&nbsp;</span>
          <span>{sku}</span>
        </div>
      </div>
    </div>);
  }
}
ProductSuggestion.defaultProps = {
  addClick: () => {}
};
ProductSuggestion.propTypes = {
  /** imageSrc: string  */
  imageSrc: PropTypes.string.isRequired,
  /** name: string  */
  name: PropTypes.string.isRequired,
  /** vpn: string  */
  vpn: PropTypes.string.isRequired,
  /** sku: string  */
  sku: PropTypes.string.isRequired,
  /** user_price: string  */
  user_price: PropTypes.number.isRequired,
  /** qty: string  */
  qty: PropTypes.number.isRequired,
  /** qty: string  */
  addClick: PropTypes.func
};
export default ProductSuggestion;
