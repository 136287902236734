import React from "react";
import PropTypes from "prop-types";
import "./DemoApp.styl";
import { BrowserRouter as Router, Route } from "react-router-dom";
import QuoteList from "./QuoteList/QuoteList";
import Cart from "./Cart/Cart";
import CartCheckout from "./CartCheckout/CartCheckout";
import Search from "./Search/Search";
import CheckoutConfirm from "./CheckoutConfirm/CheckoutConfirm";
import QuoteDetail from "./QuoteDetail/QuoteDetail";
import ProductDetail from "./ProductDetail/ProductDetail";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pageUpdated = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <Router onEnter={this.pageUpdated}>
        <div>
          <Route exact={true} path="/" component={Search} />
          <Route exact={true} path="/quotes" component={QuoteList} />
          <Route exact={true} path="/checkout" component={CartCheckout} />
          <Route exact={true} path="/confirm" component={CheckoutConfirm} />
          <Route exact={true} path="/cart" component={Cart} />
          <Route exact={true} path="/search" component={Search} />
          <Route path="/product/:id?" component={ProductDetail} />
          <Route path="/quotes/create/:id?" component={QuoteDetail} />
        </div>
      </Router>
    );
  }
}
App.defaultProps = {};
App.propTypes = {};
export default App;
