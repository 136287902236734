import React from "react";
import Accordion from "../Accordion/Accordion";
import Button from "../Button/Button";
import QuoteSearch from "../QuoteSearch/QuoteSearch";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import "./QuoteListHeader.styl";
const MOBILE_WIDTH = 768;

class QuoteListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: null
    };
  }

  componentDidMount() {
    // We want the drawer open by default on desktop and closed by default on mobile.
    let resizeTimeout;
    const resizeThrottler = () => {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(function() {
          resizeTimeout = null;
          resizeHandler();
        }, 66);
      }
    };

    const resizeHandler = () => {
      if (window.innerWidth > MOBILE_WIDTH && this.state.view !== "desktop") {
        this.setState({ view: "desktop" });
      }
      if (window.innerWidth <= MOBILE_WIDTH && this.state.view !== "mobile") {
        this.setState({ view: "mobile" });
      }
    };

    window.addEventListener("resize", resizeThrottler, false);
    resizeHandler();
  }

  render() {
    const isDesktop = this.state.view === "desktop";
    return (
      <div className="QuoteListHeader">
        <Accordion
          justButton={true}
          open={isDesktop}
          visible={
            <div className="headerLine">
              {" "}
              <div className="left">
                <h1>Quotes</h1>
              </div>
              <div className="right">
                <Button
                  label={isDesktop ? "Create Quote" : ""}
                  icon="file-plus"
                  onClick={() => {
                    this.props.history.push("/quotes/create");
                  }}
                  size={isDesktop ? "large" : "square"}
                />
              </div>
            </div>
          }
          hidden={<QuoteSearch desktop={isDesktop} />}
          openButton={<Icon face="filter" color="blue" />}
        />
      </div>
    );
  }
}
QuoteListHeader.defaultProps = {};
QuoteListHeader.propTypes = {};
export default QuoteListHeader;
