import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../NumberInput/NumberInput";
import Button from "../Button/Button";
import { connect } from "react-redux";
import InStock from "../InStock/InStock";
import { Currency } from "../../lib/formatters";
import { addressPanel } from "../../actions/panel";
import Icon from "../Icon/Icon";
import "./CartItemInfo.styl";
import { FLAGS } from "../../../data";
import ProductFlag from "../ProductFlag/ProductFlag";

class CartItemInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      imageSrc,
      qty,
      vpn,
      user_price,
      sku,
      upc,
      name,
      flags
    } = this.props;
    const { dispatch } = this.props;

    const productFlags = flags.map((f, index) => (
      <ProductFlag {...FLAGS[f]} key={index} />
    ));

    return (
      <div className="CartItemInfo">
        <div className="inner">
          <div className="group">
            <div className="image-wrap">
              <img src={imageSrc} alt={name} />
            </div>
          </div>
          <div className="group">
            <p className="product-name">{name}</p>
            <div className="id-numbers">
              <span>VPN:&nbsp;</span>
              <span>{vpn}</span>
              <span className="spacer">|</span>
              <span>SKU:&nbsp;</span>
              <span>{sku}</span>
              <span className="spacer">|</span>
              <span>UPC:&nbsp;</span>
              <span>{upc}</span>
            </div>
            <div className="productFlags">{productFlags}</div>
          </div>
        </div>
      </div>
    );
  }
}
CartItemInfo.defaultProps = {};
CartItemInfo.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default connect(state => state)(CartItemInfo);
