import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./QuoteDetailActions.styl";
import Button from "../../components/Button/Button";
import { QUOTE_ACTIVITY_PANEL, quoteActivityPanel } from "../../actions/panel";

class QuoteDetailActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="QuoteDetailActions">
        <div className="inner">
          <div className="left">
            <div>
              <Button
                label="VIEW ALL QUOTE ACTIVITY"
                kind="tertiary"
                size="fullwidth"
                className="viewAll"
                onClick={() => this.props.dispatch(quoteActivityPanel())}
              />
            </div>
          </div>
          <div className="right">
            <Button
              label="IMPORT"
              icon="file-plus"
              kind="tertiary"
              size="small"
            />
            <Button label="PRINT" icon="print" kind="tertiary" size="small" />
          </div>
        </div>
      </div>
    );
  }
}
QuoteDetailActions.defaultProps = {};
QuoteDetailActions.propTypes = {};
export default connect(state => state)(QuoteDetailActions);
