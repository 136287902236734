import React from "react";
import "./Table.styl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import CheckBox from "../CheckBox/CheckBox";
import Notifier from "../Notifier/Notifier";
import TableHeader from "../TableHeader/TableHeader";
import Tooltip from "../Tooltip/Tooltip";
import { ColumnString } from "./TableColumns";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCol: this.props.sortCol,
      sortAsc: this.props.sortAsc,
      selectedRows: this.props.selectedRows,
      allSelected: this.props.allSelected
    };
  }

  componentsWillReceiveProps(props) {
    this.setState({
      selectedRows: props.selectedRows,
      allSelected: props.allSelected
    });
  }

  sortTable = () => {
    const { sortCol, sortAsc, data } = this.props;
    if (!data[0]) return [];
    const keys = Object.keys(data[0]);
    return data.sort((a, b) =>
      a[keys[sortCol]] > b[keys[sortCol]]
        ? sortAsc
          ? 1
          : -1
        : sortAsc
        ? -1
        : 1
    );
  };

  render() {
    const makeColumns = (row, rIndex) => {
      const rowKeys = this.props.headings.map(h => h.key);
      return rowKeys.map((key, keyIndex) => {
        const column = row[key];
        let ColumnComponent = this.props.columns[keyIndex];
        if (!ColumnComponent) ColumnComponent = ColumnString;
        return (
          <td
            key={keyIndex}
            className={key}
            onClick={() => this.props.rowClick(row.id)}
          >
            <ColumnComponent index={rIndex} className="col" {...row}>
              {column}
            </ColumnComponent>
          </td>
        );
      });
    };

    const checkColumn = (rowData, selected) => {
      if (this.props.selectable) {
        return (
          <td className="check_col">
            <CheckBox
              checked={selected}
              onClick={() => {
                if (this.props.toggleHighlight)
                  this.props.toggleHighlight(rowData.id);
              }}
            />
          </td>
        );
      } else {
        return "";
      }
    };

    const {
      selectable,
      page,
      limit,
      selectedRows,
      afterRow,
      rowNotices,
      dispatch
    } = this.props;

    const allData = this.sortTable();
    const data = allData.slice((page - 1) * limit, (page - 1) * limit + limit);

    const rows = data.map((rowData, rIndex) => {
      const selected = selectedRows && selectedRows[rowData.id];
      let classes = "row ";
      classes += selected ? "selected " : "";
      classes += rowData.notify ? "notifyHighlight" : "";
      const afterRowHtml = rowData.showAfterRow ? afterRow : "";
      return (
        <tr key={rIndex} className={classes}>
          <td>
            {rowNotices && rowNotices(rowData, dispatch)}
            <div>
              <table>
                <tbody>
                  <tr>
                    {checkColumn(rowData, selected)}
                    {makeColumns(rowData, rIndex)}
                  </tr>
                </tbody>
              </table>
            </div>
            {afterRowHtml}
          </td>
        </tr>
      );
    });
    const numRowsSelected = selectedRows ? Object.keys(selectedRows).length : 0;

    const {
      allSelected,
      headings,
      sortAsc,
      sortCol,
      setSort,
      rowClick,
      toggleSelectAll,
      headingActions
    } = this.props;

    return (
      <table className="Table" key={0}>
        <tbody>
          <TableHeader
            numRowsSelected={numRowsSelected}
            allSelected={allSelected}
            selectable={selectable}
            headings={headings}
            sortAsc={sortAsc}
            sortCol={sortCol}
            toggleSelectAll={toggleSelectAll}
            headingActions={headingActions}
            setSort={setSort}
          />
          {rows}
        </tbody>
      </table>
    );
  }
}
Table.defaultProps = {
  headings: [],
  data: [],
  columns: [],
  sortCol: 0,
  page: 1,
  limit: 1000,
  sortAsc: false,
  selectable: true,
  rowClick: () => {}
};
Table.propTypes = {
  /** Array of Heading */
  page: PropTypes.number.isRequired,
  /** How large the table gets before it needs multiple pages */
  limit: PropTypes.number.isRequired,
  /** Array of Headings, in order */
  headings: PropTypes.array.isRequired,
  /** Array of Custom Column Components, in order */
  columns: PropTypes.array.isRequired,
  /** bool, does this table include the check column on the left */
  selectable: PropTypes.bool,
  /** Data for the table in array of objects */
  data: PropTypes.array.isRequired
};

export default connect(state => state)(Table);
