import React from "react";
import PropTypes from "prop-types";
import "./QuoteDetailHeader.styl";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Button from "../../components/Button/Button";

const QuoteName = props => {
  return (
    <div className="QuoteName">
      <h1>{props.name || "Quote Name"}</h1>
    </div>
  );
};

const SendQuoteButton = props => {
  return (
    <div className="SendQuoteButton">
      <Button
        disabled={true}
        size="large"
        label="Send Quote"
        icon="sign-in-alt"
      />
    </div>
  );
};

const SubNav = props => (
  <div className="desktopOnly">
    <Breadcrumbs
      trail={[
        {
          link: "/",
          label: "Home"
        },
        {
          link: "/quotes",
          label: "Quotes"
        },
        {
          link: null,
          label: props.quoteId || "QUO-893-2R89219"
        }
      ]}
    />
  </div>
);

class QuoteDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="QuoteDetailHeader">
        <div className="inner">
          <SubNav quoteId={this.props.quote.quote_id} />
          <div className="col2">
            <div className="left">
              <QuoteName name={this.props.quote.quote_name} />
            </div>
            <div className="right">
              <SendQuoteButton />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
QuoteDetailHeader.defaultProps = {};
QuoteDetailHeader.propTypes = {};
export default QuoteDetailHeader;
