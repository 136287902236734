import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import SelectMenu from "../SelectMenu/SelectMenu";
import DatePicker from "../DatePicker/DatePicker";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import "./QuoteSearch.styl";
import {addFilter} from "../../actions/filters";

// Is the Input UI Combined or not?
const uiStates = {
  keyword: true,
  quote_name: true,
  quote_id: true,
  created_by: true,
  end_user: true,
  total_price: false,
  last_updated: false,
  exp_date: false,
  status: false,
  default: true
};

class QuoteSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "default",
      value: ""
    };
  }

  changeInput(input) {
    this.setState({input, value: ""});
  }

  changeValue(value) {
    this.setState({value});
  }

  performSearch() {
    if (this.props.dispatch) 
      this.props.dispatch(addFilter(this.state));
    else 
      console.log("Searched!", this.state);
    }
  
  keyPress(e) {
    if (e.keyCode === 13) {
      this.performSearch();
    }
  }

  getInput() {
    const {input} = this.state;
    switch (input) {
      case "total_price":
        return (<div className="totalPriceCont">
          <TextInput className="min focusAfter" placeholder="min" type="number"/>
          <TextInput className="max" placeholder="max" type="number"/>
          <SelectMenu isSearchable={false} className="cur" defaultValue={{
              value: "USD",
              label: "USD"
            }} options={[{
                value: "USD",
                label: "USD"
              }
            ]}/>
        </div>);
      case "status":
        return (<SelectMenu isSearchable={false} onChange={e => this.changeValue(e.value)} options={[
            {
              value: 1,
              label: "New Quote"
            }, {
              value: 2,
              label: "Quote Sent"
            }, {
              value: 3,
              label: "Approved"
            }, {
              value: 4,
              label: "Rejected"
            }, {
              value: 5,
              label: "Closed"
            }
          ]}/>);
      case "last_updated":
      case "exp_date":
        return "";
      default:
        return (<TextInput className="default focusAfter" value={this.state.value} onKeyDown={e => this.keyPress(e)} onChange={e => this.changeValue(e.target.value)}/>);
    }
  }

  render() {
    const {input} = this.state;
    // Should the Select and TextInput be combined or not?
    // See uiStates object.
    const uiCombined = uiStates[input];
    return (<div className={`QuoteSearch ${input}`}>
      <div className="multiSearch">
        <SelectMenu className="noRightSide" isSearchable={false} onChange={e => {
            this.changeInput(e.value);
            setTimeout(() => {
              const next = document.querySelector(".focusAfter");
              if (next) 
                next.focus();
              }
            , 1);
          }} options={[
            {
              value: "keyword",
              label: "Keyword"
            }, {
              value: "quote_name",
              label: "Quote Name"
            }, {
              value: "quote_id",
              label: "Quote ID"
            }, {
              value: "created_by",
              label: "Created By"
            }, {
              value: "end_user",
              label: "End User"
            }, {
              value: "total_price",
              label: "Total Price"
            }, {
              value: "last_updated",
              label: "Last Updated"
            }, {
              value: "exp_date",
              label: "Exp Date"
            }, {
              value: "status",
              label: "Status"
            }
          ]}/> {this.getInput()}
      </div>
      <DatePicker multi={true}/>
      <Button label="Search" kind="secondary" size={this.props.desktop
          ? "small"
          : "fullwidth"} onClick={() => this.performSearch()}/>
    </div>);
  }
}
QuoteSearch.defaultProps = {
  desktop: true
};
QuoteSearch.propTypes = {
  /** something  */
  desktop: PropTypes.bool
};
export default connect(state => state)(QuoteSearch);
export const component = QuoteSearch;
