import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.styl";

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: this.props.on
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({on: newProps.on})
  }

  toggle = () => {
    if (this.props.onToggle) 
      this.props.onToggle();
    else {
      this.setState({
        on: !this.state.on
      })
    }
  }

  render() {
    return (<div className={`ToggleSwitch ${this.state.on
        ? "on"
        : "off"}`} onClick={this.props.onToggle || this.toggle}>
      <div className="switch"/>
    </div>);
  }
}
ToggleSwitch.propTypes = {
  /** on: is it in the "on" state? (true/false)  */
  on: PropTypes.bool,
  // /** on: is it in the "on" state? (true/false)  */
  // onToggle: PropTypes.func
};

export default ToggleSwitch;
