import React from "react";
import PropTypes from "prop-types";
import RadioElement from "../RadioElement/RadioElement";
import Table from "../Table/Table";
import { ColumnQty, ColumnString } from "../Table/TableColumns";
import Button from "../Button/Button";
import "./SelectWarehousePanel.styl";

// Filler data @Remove when pulling real stuff.
const warehouses = [
  {
    warehouse: "Vancouver",
    stock: 10,
    on_order: 1,
    lead_time: "5 days"
  },
  {
    warehouse: "Toronto",
    stock: 66,
    on_order: 1,
    lead_time: "--"
  }
];

const ColumnRadio = ({ selected, children }) => {
  return (
    <div className="col">
      <RadioElement checked={selected} label={children} />
    </div>
  );
};

class SelectWarehousePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="SelectWarehousePanel">
        <h2>
          <span>Select Warehouse</span>
        </h2>
        <div className="inner">
          <p>Your price includes the following discounts</p>
          <Table
            className="WarehouseList"
            selectable={false}
            headings={[
              {
                label: "Warehouse",
                key: "warehouse"
              },
              {
                label: "Stock",
                key: "stock",
                align: "right"
              },
              {
                label: "On Order",
                key: "on_order",
                align: "right"
              },
              {
                label: "Lead time *",
                key: "lead_time"
              }
            ]}
            data={warehouses}
            columns={[ColumnRadio, ColumnString, ColumnString, ColumnString]}
          />
          <p>
            * Lead time does not include weekends or holidays. Orders placed
            after order CutOff times will be shipped the next business day.
            Minimum Order Fee.
          </p>
          <div className="buttons">
            <Button
              label="Cancel"
              kind="secondary"
              size="small"
              onClick={this.props.closeClick}
            />
            <Button
              label="Save"
              kind="primary"
              size="small"
              onClick={this.props.closeClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
SelectWarehousePanel.defaultProps = {};
SelectWarehousePanel.propTypes = {};
export default SelectWarehousePanel;
