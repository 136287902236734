import {
  SET_LIMIT,
  PREV,
  NEXT,
  GOTO_END,
  GOTO_START,
  GOTO_PAGE
} from "../actions/pagination";

const paginationReducer = (state = { currentPage: 1, limit: 20 }, action) => {
  switch (action.type) {
    case NEXT:
      return {
        ...state,
        currentPage: state.currentPage + 1
      };
    case PREV:
      return {
        ...state,
        currentPage: state.currentPage > 1 ? state.currentPage - 1 : 1
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      };
    case GOTO_START:
      return {
        ...state,
        currentPage: 1
      };
    case GOTO_END:
    case GOTO_PAGE:
      return {
        ...state,
        currentPage: action.page
      };
    default:
      return state;
  }
};

export default paginationReducer;
