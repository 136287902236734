import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { Currency } from "../../lib/formatters";
import "./CheckoutTotal.styl";

class CheckoutTotal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      shippableSubtotal,
      backorderedSubtotal,
      numItems,
      total,
      checkoutClick,
      addToQuoteClick,
      shippingPrice,
      readOnly
    } = this.props;

    const shipping =
      shippingPrice > 0 ? (
        <Currency>{total}</Currency>
      ) : (
        <div className="free">FREE</div>
      );

    const action = readOnly ? (
      ""
    ) : (
      <Button kind="tertiary" label="Add Coupon Code" icon="tag" size="large" />
    );

    return (
      <div className="CheckoutTotal">
        <div className="inner">
          <div className="col2 shipTotal">
            <div className="left">Shippable Product Subtotal</div>
            <div className="right">
              <Currency>{shippableSubtotal}</Currency>
            </div>
          </div>
          <div className="col2 backorderTotal">
            <div className="left">*Backordered Product Subtotal</div>
            <div className="right">
              <Currency>{backorderedSubtotal}</Currency>
            </div>
          </div>
          <div className="col2">
            <div className="left">Subtotal</div>
            <div className="right">
              <Currency>{total}</Currency>
            </div>
          </div>
          <div className="col2">
            <div className="left">Shipping</div>
            <div className="right">{shipping}</div>
          </div>
          <div className="col2 total">
            <div className="left">Total</div>
            <div className="right">
              <Currency>{total}</Currency>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CheckoutTotal.defaultProps = {};
CheckoutTotal.propTypes = {
  /** shippableSubtotal: number  */
  shippableSubtotal: PropTypes.number.isRequired,
  /** backorderedSubtotal: number  */
  backorderedSubtotal: PropTypes.number.isRequired,
  /** total: number  */
  total: PropTypes.number.isRequired,
  /** checkoutClick: function  */
  checkoutClick: PropTypes.func,
  /** addToQuoteClick: function  */
  addToQuoteClick: PropTypes.func
};
export default CheckoutTotal;
