import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import SummaryItem from "../SummaryItem/SummaryItem";
import "./SummaryItems.styl";

class SummaryItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {data, readOnly} = this.props;
    return (<div className="SummaryItems">
      {
        data.map((c, i) => {
          return <SummaryItem key={i} {...c} readOnly={readOnly}/>;
        })
      }
    </div>);
  }
}
SummaryItems.defaultProps = {};
SummaryItems.propTypes = {
  /** data: string  */
  // data: PropTypes.array.isRequired,
};
export default connect(state => state)(SummaryItems);
