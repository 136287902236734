import React from "react";
import Button from "../../components/Button/Button";
import { connect } from "react-redux";
import "./CartActionButtons.styl";
import {
  switchCartPanel,
  cartSettingsPanel,
  quickOrderPanel
} from "../../actions/panel";

const CartActionButtons = props => (
  <div className="CartActionButtons">
    <Button
      icon="sync-alt"
      label="Switch Cart"
      kind="tertiary"
      size="fullwidth"
      onClick={() => props.dispatch(switchCartPanel())}
    />
    <Button
      icon="shopping-cart"
      label="Create Quote"
      kind="tertiary"
      size="fullwidth"
      href="/quotes/create"
    />
    <Button
      icon="cog"
      label="Cart Settings"
      kind="tertiary"
      size="fullwidth"
      onClick={() => props.dispatch(cartSettingsPanel())}
    />
    <Button
      label="Quick Order Entry"
      icon="list"
      kind="tertiary"
      size="fullwidth"
      onClick={() => props.dispatch(quickOrderPanel())}
    />
  </div>
);
export default connect(state => state)(CartActionButtons);
