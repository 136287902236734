import API_BASE_URL from "../lib/apiEndpoint";

export const NOTIFICATIONS_RESPONSE = "NOTIFICATIONS_RESPONSE";

export const notificationsResponse = data => ({
  type: NOTIFICATIONS_RESPONSE,
  notifications: data
});

export const getNotifications = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/notifications?_sort=created_on&_order=desc`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(notificationsResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};
