import React from "react";
import moment from "moment";

export const TimeAgo = props => {
  const date = moment(props.children).fromNow();
  return <div>{date}</div>;
};

export const DateFormat = props => {
  return moment(props.children).format("l");
};

export const Currency = ({ className, children }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  if (isNaN(children)) return "";
  const dollars = formatter.format(children);
  return <span className={`currency ${className || ""}`}>{dollars}</span>;
};

export const NumberFormat = props => {
  if (!props.children) return "";
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0
  });
  const number = formatter.format(props.children);
  return number;
};
