// Should be the same as colors.styl
module.exports = {
  black: "#000",
  cBlue: "#2375BB",
  cOrange: "#E1500B",
  cRed: "#C33D39",
  cGreen: "#0B7630",
  highlight: "#F9F4EC",
  xBlue: "#005394",
  xOrange: "#CB4708",
  xGreen: "#085C26",
  xRed: "#C3241F",
  xYellow: "#EBA90C",
  xPurple: "#970985",
  grey0: "#333333",
  grey1: "#454545",
  grey2: "#999999",
  grey3: "#DDDDDD",
  grey4: "#EEEEEE",
  grey5: "#F8F8F8",
  white: "#FFFFFF",

  fGreen: "#B9E18B",
  fRed: "#FFBDBB",
  fOrange: "#FBC3A9",
  fYellow: "#F8E1AA",
  fBlue: "#CBE0F9",

  tGreen: "rgba(11,118,48,.1)",
  tBlue: "rgba(35,117,183,.1)",
  tRed: "rgba(195,61,57,.1)",
  tOrange: "rgba(183,133,57,0.1)",
  tPurple: "rgba(110,89,120,.1)",
  mBlue: "#E9F2F9",
  mOrange: "#F9F5EC"
};
