const FLAGS = [
  {
    label: "Web Promo",
    color: "red"
  },
  {
    label: "Web Promo",
    color: "red"
  },
  {
    label: "Special Pricing",
    color: "green"
  },
  {
    label: "New",
    color: "blue"
  }
];

const items = [
  {
    imageSrc: "/images/products/product1.png",
    name:
      "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless"
  },
  {
    imageSrc: "/images/products/product2.png",
    name:
      "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless"
  },
  {
    imageSrc: "/images/products/product3.png",
    name:
      "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless"
  },
  {
    imageSrc: "/images/products/product4.png",
    name:
      "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless"
  },
  {
    imageSrc: "/images/products/product1.png",
    name:
      "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless"
  }
];

module.exports = {
  FLAGS,
  carts: [
    {
      name: "My Cart",
      total_price: 70112.32,
      end_user: "Roxy Schultz",
      active: true,
      items: items
    },
    {
      name: "My Cart 2",
      total_price: 10430.32,
      end_user: "Bobby Mac",
      items: items
    },
    {
      name: "My Cart 3",
      total_price: 70112.32,
      end_user: "Roxy Schultz",
      items: items
    },
    {
      name: "My Cart 4",
      total_price: 10430.32,
      end_user: "Bobby Mac",
      items: items
    },
    {
      name: "My Cart 5",
      total_price: 70112.32,
      end_user: "Roxy Schultz",
      items: items
    },
    {
      name: "My Cart 6",
      total_price: 10430.32,
      end_user: "Bobby Mac",
      items: items
    }
  ],
  quote_lines: [
    {
      imageSrc: "/images/products/product1.png",
      name: "V7 LCD MONITORS 27IN ADS-IPS 2560X1440P 16:9 2K WQHD HDMI/DP",
      qty: 115,
      quote_qty: 5,
      sku: "JH8932HJDJ",
      vpn: "DSHKF92303",
      markup: 81.72,
      user_price: 600
    },
    {
      imageSrc: "/images/products/product2.png",
      name: "DELL LATITUDE LATITUDE 7480 I7 6-6600U8GB 1DIMMS 256GB X SATA SS",
      qty: 120,
      quote_qty: 5,
      sku: "HG3290903B",
      vpn: "38293HJHGH",
      markup: 200,
      user_price: 1881
    },
    {
      imageSrc: "/images/products/product3.png",
      name: "Lenovo ThinkPad T480s 20L7002CUS 14″ LCD Notebook",
      qty: 600,
      quote_qty: 15,
      sku: "J390299110",
      vpn: "883H2J8292",
      markup: 130,
      user_price: 1630
    },
    {
      imageSrc: "/images/products/product4.png",
      name: "Qualcomm Ethernet Router",
      qty: 6237,
      quote_qty: 2,
      sku: "J930299110",
      vpn: "20L50054US",
      markup: 20,
      user_price: 120
    }
  ],
  quotes: [
    {
      created_on: "2018-11-04T00:29:49.130Z",
      exp_date: "2018-11-04T00:29:49.130Z",
      quote_name: "Alvarez Industries",
      quote_id: "1ITqE3dOPKJLQp",
      total_price: 116463,
      status: 1,
      end_user: "Holt Computing",
      created_by: "SHancock"
    },
    {
      created_on: "2018-12-26T19:34:14.060Z",
      exp_date: "2018-11-04T00:29:49.130Z",
      quote_name: "Frazee Brothers",
      quote_id: "ztGcNNmsR34mXz",
      total_price: 205032,
      status: 4,
      end_user: "Calo Computing",
      created_by: "TMartin"
    },
    {
      created_on: "2018-12-02T07:18:39.085Z",
      exp_date: "2018-11-04T00:29:49.130Z",
      quote_name: "Minihane Global",
      quote_id: "AcWagYcSBLNnCY",
      total_price: 245559,
      status: 5,
      end_user: "Craig Technologies",
      created_by: "BNordby"
    }
  ],
  options: [
    {
      value: "chocolate",
      label: "Select One"
    },
    {
      value: "strawberry",
      label: "Select Two"
    },
    {
      value: "vanilla",
      label: "Select Three"
    },
    {
      value: "butter pecan",
      label: "Select Four"
    }
  ]
};
