import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../NumberInput/NumberInput";
import AddToCart from "../AddToCart/AddToCart";
import {Currency} from "../../lib/formatters";
import "./ProductSuggestionAdder.styl";

class ProductSuggestionAdder extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft
    }
  }

  clickAdd = () => {
    const maxtime = 30.0;
    const original = this.ref.current;
    const clone = original.cloneNode(true)
    document.body.appendChild(clone);
    clone.classList.add("bullet");
    const productPos = this.offset(original)
    const cart = document.getElementById("shoppingCartIcon");
    const cartPos = this.offset(cart)
    let top = productPos.top
    let left = productPos.left
    let opacity = 1;
    let scale = 1.0;
    let start = null;
    const topStep = (top - cartPos.top) / maxtime;
    const leftStep = (cartPos.left - left) / maxtime;
    let progressFrame = 0;
    const frame = (time) => {
      // debugger
      top = top - topStep;
      left = left + leftStep;
      // opacity = opacity - .01667;
      scale = scale - 1.0 / maxtime;
      clone.style.top = top + "px";
      clone.style.left = left + "px";
      clone.style.opacity = opacity;
      clone.style.transform = `scale(${scale})`;
      if (progressFrame >= maxtime) {
        clone.parentNode.removeChild(clone)
      } else {
        progressFrame = progressFrame + 1;
        window.requestAnimationFrame(frame)
      }
    }

    window.requestAnimationFrame(frame)
    this.props.addClick()
  }

  render() {
    const {
      imageSrc,
      vpn,
      sku,
      name,
      user_price,
      qty
    } = this.props;

    return (<div className="ProductSuggestionAdder" ref={this.ref}>
      <div className="group">
        <div className="image-wrap">
          <img src={imageSrc} alt={name}/>
        </div>
      </div>
      <div className="info">
        <p className="product-name small">{name}</p>
        <div className="id-numbers">
          <span>VPN:&nbsp;</span>
          <span>{vpn}</span>
          <span className="spacer">|</span>
          <span>SKU:&nbsp;</span>
          <span>{sku}</span>
        </div>
      </div>
      <div className="price">
        <Currency>{user_price}</Currency>
      </div>
      <div className="group number-input">
        <div className="col2">
          <NumberInput value={qty}/>
          <AddToCart onClick={this.clickAdd} label="Add" size="small" color="blue"/>
        </div>
      </div>
    </div>);
  }
}
ProductSuggestionAdder.defaultProps = {
  addClick: () => {}
};
ProductSuggestionAdder.propTypes = {
  /** imageSrc: string  */
  imageSrc: PropTypes.string.isRequired,
  /** name: string  */
  name: PropTypes.string.isRequired,
  /** vpn: string  */
  vpn: PropTypes.string.isRequired,
  /** sku: string  */
  sku: PropTypes.string.isRequired,
  /** user_price: number  */
  user_price: PropTypes.number.isRequired,
  /** qty: string  */
  qty: PropTypes.string.isRequired,
  /** addClick: func  */
  addClick: PropTypes.func
};
export default ProductSuggestionAdder;
