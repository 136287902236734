import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MobileNav from "../MobileNav/MobileNav";
import MobileNavIcon from "../MobileNavIcon/MobileNavIcon";
import NotificationCount from "../NotificationCount/NotificationCount";
import MobileMenu from "../MobileMenu/MobileMenu";
import TextInput from "../TextInput/TextInput";
import SlidePanel from "../SlidePanel/SlidePanel";
import Icon from "../Icon/Icon";
import "./MobileHeader.styl";
const logo = require("../../../statics/images/logo.svg");

class MobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavPanelOpen: false
    };
  }

  toggleNavPanel = () => {
    this.setState({
      mobileNavPanelOpen: !this.state.mobileNavPanelOpen
    });
  };

  render() {
    const { products, cart_items, notifications, quote_lines } = this.props;
    return (
      <div className="MobileHeader">
        <div className="inner">
          <div
            className="logo"
            dangerouslySetInnerHTML={{
              __html: logo
            }}
          />
          <div className="icons">
            <Icon face="user-circle" color="white" kind="reg" />
            <div className="navIcon" id="shoppingCartIcon">
              <Icon
                face="shopping-cart"
                color="white"
                kind="reg"
                onClick={() => {
                  window.location.href = "/cart";
                }}
              />
              <NotificationCount count={cart_items.length} />
            </div>
            <MobileNavIcon
              open={this.state.mobileNavPanelOpen}
              onClick={this.toggleNavPanel}
            />
          </div>
        </div>
        <div className="MobileSearch">
          <Icon face="search" color="grey3" />
          <TextInput placeholder="Search" />
        </div>
        <SlidePanel
          leftSide={true}
          open={this.state.mobileNavPanelOpen}
          closeClick={this.toggleNavPanel}
          className="mobileSlider"
        >
          <MobileNav>
            <MobileMenu />
          </MobileNav>
        </SlidePanel>
      </div>
    );
  }
}
MobileHeader.defaultProps = {};
MobileHeader.propTypes = {};
export default connect(state => state)(MobileHeader);
