import React from "react";
import PropTypes from "prop-types";
import "./InStock.styl";
import { NumberFormat } from "../../lib/formatters";

const InStock = props => {
  const inner =
    props.qty > 0 ? (
      <span className="in-stock">
        <NumberFormat>{props.qty}</NumberFormat>
        &nbsp;In Stock
      </span>
    ) : (
      <span className="out-stock">Out of Stock</span>
    );

  return <span className="InStock">{inner}</span>;
};

export default InStock;
