import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../NumberInput/NumberInput";
import InStock from "../InStock/InStock";
import { Currency, NumberFormat } from "../../lib/formatters";
import Notifier from "../Notifier/Notifier";
import Icon from "../Icon/Icon";
import "./SummaryItem.styl";

class SummaryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.numberFormatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0
    });
    this.currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
  }

  render() {
    const {
      imageSrc,
      qty,
      vpn,
      user_price,
      sku,
      upc,
      name,
      readOnly
    } = this.props;

    const action = true ? (
      ""
    ) : (
      <a className="action" href="#">
        Remove
      </a>
    );

    return (
      <div className="SummaryItem">
        <div className="inner">
          <div className="group">
            <div className="image-wrap">
              <img src={imageSrc} alt={name} />
            </div>
          </div>
          <div className="group">
            <p className="product-name small">{name}</p>

            <div className="id-numbers">
              <div className="line">
                <span>SKU:&nbsp;</span>
                <span>{sku}</span>
                <span className="spacer">|</span>
              </div>
              <div className="line">
                <span>VPN:&nbsp;</span>
                <span>{vpn}</span>
                <span className="spacer">|</span>
              </div>
              <div className="line">
                <span>UPC:&nbsp;</span>
                <span className="line">{upc}</span>
              </div>
            </div>
          </div>
          <div className="group">
            <span className="price">
              <Currency>{user_price}</Currency>
            </span>
            <span className="qty">
              Qty&nbsp;
              <NumberFormat>{qty}</NumberFormat>
            </span>
            {action}
          </div>
        </div>
      </div>
    );
  }
}
SummaryItem.defaultProps = {};
SummaryItem.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default SummaryItem;
