import React from "react";
import Icon from "../../Icon/Icon";

export default function Navbar({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils
}) {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  return (
    <div className={className}>
      <Icon
        face="chevron-left"
        color="blue"
        className="prevMonth"
        onClick={() => onPreviousClick()}
      />
      <Icon
        face="chevron-right"
        color="blue"
        className="nextMonth"
        onClick={() => onNextClick()}
      />
    </div>
  );
}
