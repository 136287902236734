import {QUOTES_RESPONSE} from "../actions/quotes";

const quotesReducer = (state = [], action) => {
  switch (action.type) {
    case QUOTES_RESPONSE:
      return action.quotes;
    default:
      return state;
  }
};

export default quotesReducer;
