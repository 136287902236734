import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "../Tabs/Tabs";
import Icon from "../Icon/Icon";
import TextInput from "../TextInput/TextInput";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Button from "../Button/Button";
import "./QuickOrderPanel.styl";

const EntryLine = props => {
  const { number } = props;
  return (
    <div className="entryLine">
      <div className="lineNumber">{number}</div>
      <TextInput placeholder="SKU or VPN" />
      <div className="times">&times;</div>
      <TextInput placeholder="Qty" />
    </div>
  );
};

const QuickEntry = props => {
  const { numLines, addRow } = props;
  let entries = [];
  for (var i = 0; i < numLines; i++) {
    entries.push(<EntryLine number={i + 1} key={i} />);
  }
  return (
    <div className="QuickEntry">
      {entries}
      <div className="addNewRow">
        <a href="#" onClick={addRow}>
          Add new row
        </a>
      </div>
      <div className="buttons">
        <Button
          label="Cancel"
          size="small"
          kind="secondary"
          onClick={props.closeClick}
        />
        <Button label="Add to Cart" size="large" onClick={props.closeClick} />
      </div>
    </div>
  );
};

class QuickOrderPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { numLines: 4 };
  }

  addRow = () => {
    const { numLines } = this.state;
    this.setState({ numLines: numLines + 1 });
  };

  render() {
    const { numLines } = this.state;
    const { closeClick } = this.props;
    return (
      <div className="QuickOrderPanel">
        <h2>
          <span>Quick Orders</span>
        </h2>
        <div className="inner">
          <p className="info">
            Please use the Upload Spreadsheet option if you have more than 10
            products to add.
          </p>
          <ErrorBoundary>
            <Tabs activeTab={0} tabs={["Quick Entry", "Upload Spreadsheet"]}>
              <QuickEntry
                numLines={numLines}
                closeClick={closeClick}
                addRow={this.addRow}
              />
              <div />
            </Tabs>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}
QuickOrderPanel.defaultProps = {};
QuickOrderPanel.propTypes = {};
export default connect(state => state)(QuickOrderPanel);
