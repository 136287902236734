import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import TextInput from "../TextInput/TextInput";
import classnames from "classnames";
import "./SearchElement.styl";

const FAKEWAIT = 2000; // @Remove when you setup real data fetching.

const getSuggestions = (data, value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : data.filter(lang => lang.name.toLowerCase().search(inputValue) != -1);
};

class SearchElement extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: "",
      suggestions: [],
      active: false
    };
    this.timeout = null;
  }

  onChange = e => {
    const { value } = e.target;
    const active = value.length >= 2;
    this.setState({ value, suggestions: [], active });
    setTimeout(() => {
      this.setState({ suggestions: this.props.data });
    }, FAKEWAIT);
  };

  deactivate = e => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ active: false });
    }, 400);
  };

  activate = () => {
    clearTimeout(this.timeout);
    this.setState({ active: true });
  };

  render() {
    const { data } = this.props;
    const { value, suggestions, active } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange
    };
    const Suggestions = this.props.children;

    return (
      <div
        className={`SearchElement ${active ? "active" : ""}`}
        onMouseOut={this.deactivate}
      >
        <div className="inner">
          <TextInput {...inputProps} />
          <Icon face="search" color="grey2" />
        </div>
        <div className="suggestions" onMouseOver={this.activate}>
          <Suggestions data={suggestions} />
        </div>
      </div>
    );
  }
}
SearchElement.defaultProps = {};
SearchElement.propTypes = {
  /** data to search against  */
  data: PropTypes.array.isRequired
};
export default SearchElement;
