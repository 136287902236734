import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  nextPage,
  prevPage,
  setLimit,
  gotoEnd,
  gotoStart,
  gotoPage
} from "../../actions/pagination";
import "./Pagination.styl";
import SelectMenu from "../SelectMenu/SelectMenu";
import { NumberFormat } from "../../lib/formatters";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const { currentPage, perPage, totalResults } = props;
    const maxPages = Math.ceil(this.props.totalResults / perPage);
    let cp = currentPage <= maxPages ? currentPage : maxPages;
    this.keyPress = this.keyPress.bind(this);
    this.state = {
      currentPage: cp,
      perPage,
      gotoPageValue: cp
    };
  }

  numbers() {
    const pp = this.props.dispatch ? this.props.perPage : this.state.perPage;
    const max = Math.ceil(this.props.totalResults / pp);
    const cp = this.props.dispatch
      ? this.props.currentPage
      : this.state.currentPage;
    let num = [];
    // Start of pages
    if (cp === 1) {
      num.push(cp);
      num.push(cp + 1);
      num.push(cp + 2); // End of pages
    } else if (cp === max) {
      num.push(cp - 2);
      num.push(cp - 1);
      num.push(cp + 1 > max ? max : cp + 1); // In the middle
    } else {
      num.push(cp - 1);
      num.push(cp);
      num.push(cp + 1 > max ? max : cp + 1);
    }
    // Only show up to max pages
    num = num.slice(0, max);
    let numbers = num.map((n, i) => {
      const active = cp === n ? "active" : "";
      return (
        <span key={i} className={active} onClick={() => this.gotoPage(n)}>
          {n}
        </span>
      );
    });

    return <div className="numbers">{numbers}</div>;
  }

  showing() {
    const { currentPage, perPage } = this.props.dispatch
      ? this.props
      : this.state;
    const { totalResults } = this.props;

    let currentNumerator = (currentPage - 1) * perPage + 1;

    // Can't be less than 1
    if (currentNumerator < 1 || currentPage === 1) {
      currentNumerator = 1;
    }
    // Can't be more than totalResults
    if (currentNumerator > totalResults) {
      currentNumerator = totalResults;
    }

    let currentDenomenator = currentNumerator + (perPage - 1);

    // Can't be more than totalResults
    if (currentDenomenator > totalResults) {
      currentDenomenator = totalResults;
    }
    return (
      <div className="showing">
        <span>Showing&nbsp;</span>
        <span>
          {currentNumerator}-{currentDenomenator}&nbsp;
        </span>
        <span>of&nbsp;</span>
        <span>
          <NumberFormat>{totalResults}</NumberFormat>
        </span>
        <span>&nbsp;results</span>
      </div>
    );
  }

  changePerPage(e) {
    const limit = parseInt(e.value) || 10;
    if (this.props.dispatch) {
      this.props.dispatch(setLimit(limit));
    } else {
      this.setState({ perPage: limit });
    }
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      this.gotoPage();
    }
  }

  maxPages() {
    const { perPage } = this.props.dispatch ? this.props : this.state;
    return Math.ceil(this.props.totalResults / perPage);
  }

  gotoPage = page => {
    const maxPages = this.maxPages();
    let value;
    if (page) {
      value = page;
    } else {
      value = parseInt(this.state.gotoPageValue) || 1;
    }
    if (value > maxPages) value = maxPages;
    if (value < 1) value = 1;
    if (this.props.dispatch) this.props.dispatch(gotoPage(value));
    else {
      if (value) {
        this.setState({ currentPage: value, gotoPageValue: value });
      }
    }
  };

  prev = () => {
    if (this.props.dispatch) this.props.dispatch(prevPage());
    else {
      const currentPage = this.state.currentPage - 1;
      this.setState({
        currentPage: currentPage < 1 ? 1 : currentPage,
        gotoPageValue: currentPage < 1 ? 1 : currentPage
      });
    }
  };

  gotoStart = () => {
    if (this.props.dispatch) this.props.dispatch(gotoStart());
    else {
      this.setState({ currentPage: 1, gotoPageValue: 1 });
    }
  };

  next = () => {
    const { currentPage } = this.props.dispatch ? this.props : this.state;
    if (currentPage + 1 > this.maxPages()) return;
    if (this.props.dispatch) this.props.dispatch(nextPage());
    else {
      const cp = currentPage + 1;
      this.setState({ currentPage: cp, gotoPageValue: cp });
    }
  };

  gotoEnd = () => {
    const maxPages = this.maxPages();
    if (this.props.dispatch) this.props.dispatch(gotoEnd(maxPages));
    else {
      this.setState({ currentPage: maxPages, gotoPageValue: maxPages });
    }
  };

  changeGotoPageValue = e => {
    this.setState({ gotoPageValue: e.target.value });
  };

  componentWillReceiveProps(props) {
    this.setState({ gotoPageValue: props.currentPage });
  }

  render() {
    const maxPages = this.maxPages();
    const gotoPageElement = (
      <div className="gotoPage">
        <span>Page</span>
        <TextInput
          value={`${this.state.gotoPageValue}`}
          onChange={e => this.changeGotoPageValue(e)}
          onKeyDown={e => this.keyPress(e)}
        />
        <span>
          out of&nbsp;
          <NumberFormat>{maxPages}</NumberFormat>
        </span>
      </div>
    );
    return (
      <div className="Pagination">
        {this.showing()}
        <div>
          <div className="perPage">
            <span>Items&nbsp;per&nbsp;page:&nbsp;</span>
            <SelectMenu
              placeholder={this.props.perPage + ""}
              isSearchable={false}
              onChange={e => this.changePerPage(e)}
              options={[
                {
                  label: "10",
                  value: 10
                },
                {
                  label: "20",
                  value: 20
                },
                {
                  label: "30",
                  value: 30
                },
                {
                  label: "50",
                  value: 50
                },
                {
                  label: "100",
                  value: 100
                }
              ]}
            />
          </div>
        </div>
        <div className="pageNav">
          <div className="prevAll pageButton" onClick={this.gotoStart}>
            <Icon face="chevron-left" />
            <Icon face="chevron-left" />
          </div>

          <div className="prev pageButton" onClick={this.prev}>
            <Icon face="chevron-left" />
          </div>

          {this.props.gotoPage ? gotoPageElement : this.numbers()}

          <div className="next pageButton" onClick={this.next}>
            <Icon face="chevron-right" />
          </div>

          <div className="nextAll pageButton" onClick={this.gotoEnd}>
            <Icon face="chevron-right" />
            <Icon face="chevron-right" />
          </div>
        </div>
      </div>
    );
  }
}
Pagination.defaultProps = {
  perPage: 10,
  currentPage: 1,
  totalResults: 100,
  gotoPage: true
};
Pagination.propTypes = {
  /** bool: show gotoPage control  */
  gotoPage: PropTypes.bool.isRequired,
  /** number: how many items to display per page  */
  perPage: PropTypes.number.isRequired,
  /** number: which page are we on?  */
  currentPage: PropTypes.number.isRequired,
  /** number: Length of results array */
  totalResults: PropTypes.number.isRequired
};
export default connect(state => state)(Pagination);
export const component = Pagination;
