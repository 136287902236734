import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import "./Notifier.styl";

const statusFaces = {
  success: "check",
  warn: "exclamation-circle",
  error: "exclamation-triangle",
  info: "info-circle"
};

class Notifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
  }

  closeNotifier = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { heading, message, status } = this.props;
    const face = statusFaces[status];

    const headingDiv = heading ? <div className="heading">{heading}:</div> : "";
    const messageDiv = message ? <div className="message">{message}</div> : "";

    return (
      <div className={`Notifier ${open ? "open" : ""} ${status}`}>
        <div className="innerNotifier">
          <Icon face={face} />
          <div className="text">
            {headingDiv}
            {messageDiv}
          </div>
          <div className="close" onClick={this.closeNotifier}>
            &times;
          </div>
        </div>
      </div>
    );
  }
}
Notifier.defaultProps = {
  open: true,
  fadeOut: false
};
Notifier.propTypes = {
  /** bool, fadeOut  */
  fadeOut: PropTypes.bool,
  /** enum: status  */
  status: PropTypes.oneOf(["success", "error", "warn", "info"]).isRequired,
  /** string: heading  */
  heading: PropTypes.string,
  /** string: message  */
  message: PropTypes.string
};
export default Notifier;
