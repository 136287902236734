import React from "react";
import { connect } from "react-redux";
import Icon from "../Icon/Icon";
import SelectMenu from "../SelectMenu/SelectMenu";
import "./SearchResultsTop.styl";

const FilterChoice = ({ label }) => {
  return (
    <div className="FilterChoice">
      <span>{label}</span>
      <Icon face="times" />
    </div>
  );
};

class SearchResultsTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: { label: "Relevance", value: "relevance" }
    };
  }

  render() {
    const { view, products, history, viewChange } = this.props;
    const { sortBy } = this.state;
    return (
      <div className="SearchResultsTop">
        <div className="info">
          <div className="count">Showing 1 - 12 of 20,456</div>
          <div className="sortBy">
            <label>Sort by</label>
            <SelectMenu
              options={[
                { label: "Relevance", value: "relevance" },
                { label: "Lowest Price", value: "lowPrice" },
                { label: "Highest Price", value: "hiPrice" },
                { label: "Alphabetically", value: "alpha" }
              ]}
              onChange={e => this.setState({ sortBy: e })}
              label=""
              value={sortBy}
            />
          </div>
          <div className="viewTypes">
            <Icon
              face="list"
              onClick={() => viewChange("list")}
              className="listView"
              color={view === "grid" ? "blue" : "grey2"}
            />
            <Icon
              face="th-large"
              onClick={() => viewChange("grid")}
              className="gridView"
              color={view === "list" ? "blue" : "grey2"}
            />
          </div>
        </div>
        <hr />
        <div className="AppliedFilters">
          <h3>Category:</h3>
          <FilterChoice label="Computer" />
          <h3>Sub Category:</h3>
          <FilterChoice label="Computer Systems" />
          <h3>Vendor:</h3>
          <FilterChoice label="Apple" />
          <h3>Screen Size:</h3>
          <FilterChoice label="15 screen" />
        </div>
      </div>
    );
  }
}

export default connect(state => state)(SearchResultsTop);
