import React from "react";
import PropTypes from "prop-types";
import "./QuoteTotals.styl";
import TextInput from "../TextInput/TextInput";
import Accordion from "../Accordion/Accordion";

const SubTotal = props => {
  return (<div className="Total">
    <div className="totalLine col2">
      <div className="left">Discounts</div>
      <div className="right">
        <TextInput value="-500.00"/>
      </div>
    </div>
    <div className="totalLine col2">
      <div className="left">Shipping</div>
      <div className="right">
        <TextInput value="100.00"/>
      </div>
    </div>
    <div className="totalLine col2">
      <div className="left">Tax (%)</div>
      <div className="right">
        <TextInput placeholder="0.00"/>
      </div>
    </div>
  </div>);
};

const YourTotal = props => {
  return (<div className="totalButton">
    <div className="left">
      <b>Your Total</b>
    </div>
    <div className="right">
      <b>$35,386.21</b>
    </div>
  </div>);
};

const ProfitTotal = props => {
  return (<div className="Total">
    <div className="totalLine col2">
      <div className="left">Discounts</div>
      <div className="right">
        <TextInput placeholder="0.00"/>
      </div>
    </div>
    <div className="totalLine col2">
      <div className="left">Shipping</div>
      <div className="right">
        <TextInput placeholder="500.00"/>
      </div>
    </div>
    <div className="totalLine col2">
      <div className="left">Tax (%)</div>
      <div className="right">
        <TextInput placeholder="0.00"/>
      </div>
    </div>
  </div>);
};

const EndUserTotal = props => {
  return (<div className="totalButton">
    <div className="left">
      <b>End User Total</b>
    </div>
    <div className="right">
      <b>$46,608.47</b>
    </div>
  </div>);
};

class QuoteTotals extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="QuoteTotals">
      <div className="totalLine col2 doublePadd">
        <div className="left">
          <b>Your Subtotal</b>
        </div>
        <div className="right">
          <b>$35,386.21</b>
        </div>
      </div>
      <Accordion visible={<YourTotal />} hidden={<SubTotal />} flipped={true} open={true}/>
      <div className="totalLine col2 doublePadd">
        <div className="left">
          <b>Total Profit</b>
        </div>
        <div className="right">
          <b>$8,410.00</b>
        </div>
      </div>
      <Accordion visible={<EndUserTotal />} hidden={<ProfitTotal />} flipped={true} open={true}/>
    </div>);
  }
}
QuoteTotals.defaultProps = {};
QuoteTotals.propTypes = {};
export default QuoteTotals;
