import React from "react";
import PropTypes from "prop-types";
import "./CartHeader.styl";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Accordion from "../../components/Accordion/Accordion";
import Button from "../../components/Button/Button";
import CartActionButtons from "../../components/CartActionButtons/CartActionButtons";
import Icon from "../../components/Icon/Icon";

const CartName = props => {
  return (
    <div className="CartName">
      <h1>
        <Icon face="cart" />
        <span>{props.name || "Cart Name"}</span>
      </h1>
    </div>
  );
};

const SendCartButton = props => {
  return (
    <div className="SendCartButton">
      <Button
        disabled={true}
        size="large"
        label="Send Cart"
        icon="sign-in-alt"
      />
    </div>
  );
};

const SubNav = props => (
  <div className="desktopOnly">
    <Breadcrumbs
      trail={[
        {
          link: "/",
          label: "Home"
        },
        {
          link: null,
          label: "Cart"
        }
      ]}
    />
  </div>
);

const DesktopCartHeader = props => {
  return (
    <div className="DesktopCartHeader col2">
      <div className="left">
        <Icon face="shopping-cart" kind="light" className="cartIcon" />
        <CartName name={props.name} />
      </div>
      <div className="right">
        <div className="cartHeaderActions">
          <Button label="Download" icon="download" kind="tertiary" />
          <Button label="Print" icon="print" kind="tertiary" />
        </div>
        <CartActionButtons />
      </div>
    </div>
  );
};

const MobileCartHeader = props => {
  return (
    <div className="MobileCartHeader">
      <Accordion
        visible={
          <div className="top">
            <Icon face="shopping-cart" kind="light" className="cartIcon" />
            <CartName name={props.name} />
          </div>
        }
        hidden={<CartActionButtons />}
      />
    </div>
  );
};

class CartHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="CartHeader">
        <div className="inner">
          <SubNav />
          <DesktopCartHeader {...this.props} />
          <MobileCartHeader {...this.props} />
        </div>
      </div>
    );
  }
}
CartHeader.defaultProps = {};
CartHeader.propTypes = {};
export default CartHeader;
