import React from "react";
import PropTypes from "prop-types";
import "./Personalization.styl";

class Personalization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="Personalization">PERSONALIZATION</div>;
  }
}
Personalization.defaultProps = {};
Personalization.propTypes = {};
export default Personalization;
