export const QUOTE_ACTIVITY_PANEL = "QUOTE_ACTIVITY_PANEL";
export const quoteActivityPanel = () => ({
  type: QUOTE_ACTIVITY_PANEL
});

export const QUICK_ORDER_PANEL = "QUICK_ORDER_PANEL";
export const quickOrderPanel = () => ({
  type: QUICK_ORDER_PANEL
});

export const ACCESSORIES_PANEL = "ACCESSORIES_PANEL";
export const accessoriesPanel = index => ({
  type: ACCESSORIES_PANEL,
  index
});

export const NOTIFICATION_PANEL = "NOTIFICATION_PANEL";
export const notificationPanel = () => ({
  type: NOTIFICATION_PANEL
});

export const NOTES_PANEL = "NOTES_PANEL";
export const notesPanel = () => ({
  type: NOTES_PANEL
});

export const ADDRESS_PANEL = "ADDRESS_PANEL";
export const addressPanel = id => ({
  type: ADDRESS_PANEL,
  id
});

export const SWITCH_CART_PANEL = "SWITCH_CART_PANEL";
export const switchCartPanel = () => ({
  type: SWITCH_CART_PANEL
});

export const SELECT_WAREHOUSE_PANEL = "SELECT_WAREHOUSE_PANEL";
export const selectWarehousePanel = () => ({
  type: SELECT_WAREHOUSE_PANEL
});

export const SELECT_REPLACEMENT_PANEL = "SELECT_REPLACEMENT_PANEL";
export const selectReplacementPanel = () => ({
  type: SELECT_REPLACEMENT_PANEL
});

export const SPLIT_LINE_PANEL = "SPLIT_LINE_PANEL";
export const splitLinePanel = () => ({
  type: SPLIT_LINE_PANEL
});

export const CART_SETTINGS_PANEL = "CART_SETTINGS_PANEL";
export const cartSettingsPanel = () => ({
  type: CART_SETTINGS_PANEL
});
