import API_BASE_URL from "../lib/apiEndpoint";

export const BUNDLES_RESPONSE = "BUNDLES_RESPONSE";

export const bundlesResponse = data => ({
  type: BUNDLES_RESPONSE,
  bundles: data
});

export const getBundles = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/bundles`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(bundlesResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};
