import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import { NumberFormat } from "../../lib/formatters";
import "./TableHeader.styl";
import CheckBox from "../CheckBox/CheckBox";

class TableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      headings,
      sortCol,
      sortAsc,
      allSelected,
      numRowsSelected,
      setSort,
      version,
      selectable
    } = this.props;
    const sorter = i => {
      const h = headings[i];
      if (h.sorted) {
        const ts = typeof h.sorted;
        let direction = "";
        if (sortCol === i) {
          direction = sortAsc ? "up" : "down";
        }
        const classes = `sort ${direction}`;
        return (
          <div className={classes}>
            <div className="up-arrow" />
            <div className="down-arrow" />
          </div>
        );
      }
    };

    const headingsHtml = headings.map((h, i) => {
      const classes = `col ${h.align || ""} ${h.sorted ? "sorted" : ""}`;
      const right = h.align === "right";
      const infoIcon = (
        <div className="infoIconCont">
          <Icon face="info" className="infoIcon" />
        </div>
      );

      const id = `infoIcon_${h.key}-${version || ""}`;

      return (
        <th
          className={h.key}
          key={i}
          onClick={e => {
            if (h.sorted) setSort(i);
          }}
          id={h.info ? id : ""}
        >
          <div className={classes}>
            {right && h.info ? infoIcon : ""}
            <span>{h.label}</span>
            {!right && h.info ? infoIcon : ""}
            {sorter(i)}
            <Tooltip theme="dark" triggerElement={`#${id}`}>
              {h.info}
            </Tooltip>
          </div>
        </th>
      );
    });

    const Panel = this.props.headingActions;
    const actionPanel = this.props.headingActions ? (
      <Panel numRowsSelected={numRowsSelected} version={version || ""} />
    ) : null;

    const selectHeading = selectable ? (
      <th className="check_col">
        <CheckBox checked={allSelected} onClick={this.props.toggleSelectAll} />
        {actionPanel}
      </th>
    ) : null;

    return (
      <tr className="heading TableHeader">
        {selectHeading}
        {headingsHtml}
      </tr>
    );
  }
}
TableHeader.defaultProps = {};
TableHeader.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired */
};
export default TableHeader;
