import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import SelectMenu from "../SelectMenu/SelectMenu";
import TextInput from "../TextInput/TextInput";
import RadioElement from "../RadioElement/RadioElement";
import "./CartSettingsPanel.styl";

const options = [
  {
    value: "college",
    label: "College/University"
  },
  {
    value: "standard",
    label: "Standard"
  },
  {
    value: "silver_partner",
    label: "Silver Partner"
  },
  {
    value: "gold_partner",
    label: "Gold Partner"
  }
];

class CartSettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      security: "private",
      warehouse: "default"
    };
  }

  render() {
    const { security, warehouse } = this.state;
    return (
      <div className="CartSettingsPanel">
        <h2>
          <span>Cart Settings</span>
        </h2>
        <div className="inner">
          <TextInput label="Cart Name" />
          <TextInput area={true} label="Cart Notes" />
          <fieldset className="securityLevel">
            <label>Security Level</label>
            <div className="col2">
              <RadioElement
                label="Private"
                checked={security === "private"}
                onClick={() => this.setState({ security: "private" })}
              />
              <RadioElement
                label="Shared"
                checked={security === "shared"}
                onClick={() => this.setState({ security: "shared" })}
              />
            </div>
          </fieldset>
          <fieldset className="shipFrom">
            <label>Ship From</label>
            <div className="col2">
              <RadioElement
                label="Default Warehouse"
                checked={warehouse === "default"}
                onClick={() => this.setState({ warehouse: "default" })}
              />
              <RadioElement
                label="Let Me Choose"
                checked={warehouse === "choose"}
                onClick={() => this.setState({ warehouse: "choose" })}
              />
            </div>
          </fieldset>
          <fieldset className="pricing">
            <SelectMenu options={options} label="Pricing" />
          </fieldset>
          <Button
            label="Delete Cart"
            icon="trash-alt"
            size="large"
            kind="tertiary"
          />
          <div className="buttons">
            <Button
              label="Cancel"
              size="small"
              kind="secondary"
              onClick={this.props.closeClick}
            />
            <Button label="Save" size="small" onClick={this.props.closeClick} />
          </div>
        </div>
      </div>
    );
  }
}
CartSettingsPanel.defaultProps = {};
CartSettingsPanel.propTypes = {};
export default CartSettingsPanel;
