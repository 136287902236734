import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MobileCart from "../MobileCart/MobileCart";
import Icon from "../Icon/Icon";
import TextInput from "../TextInput/TextInput";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Button from "../Button/Button";
import "./AddressSearchPanel.styl";
import { getCompanies } from "../../actions/companies";
import { cartItemsAddEndUser } from "../../actions/cart_items";
import AddressSearchSuggestions from "../AddressSearchSuggestions/AddressSearchSuggestions";

const AddressList = ({ data, itemClick, selectedIndex }) => {
  if (!data) {
    return "";
  }
  return (
    <div className="AddressList">
      {data.map((address, i) => {
        return (
          <div
            className={`address ${selectedIndex === i ? "selected" : ""}`}
            key={i}
            onClick={() => itemClick(address, i)}
          >
            <p className="name">{address.company_name}</p>
            <p>{address.address}</p>
            <p>
              {address.city}, {address.state}
            </p>
            <p>{address.zip}</p>
          </div>
        );
      })}
    </div>
  );
};

class AddressSearchPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
  }
  componentDidMount() {
    this.props.dispatch(getCompanies());
  }

  itemClick = (address, i) => {
    if (this.state.selected === i) this.setState({ selected: null });
    else {
      this.setState({ selected: i });
      this.props.dispatch(cartItemsAddEndUser(address));
      // this.props.closeClick();
    }
  };

  render() {
    const { companies } = this.props;
    const { selected } = this.state;
    return (
      <div className="AddressSearchPanel">
        <h2>
          <span>Address Book</span>
        </h2>
        <div className="inner">
          <ErrorBoundary>
            <div className="addressSearch">
              <TextInput placeholder="Search by contact name, company name, etc" />
              <Button label="search" kind="secondary" />
            </div>
            <AddressList
              data={companies}
              itemClick={this.itemClick}
              selectedIndex={selected}
            />
            <div className="fixedBottom">
              <Button icon="home" label="New Address" />
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}
AddressSearchPanel.defaultProps = {};
AddressSearchPanel.propTypes = {};
export default connect(state => state)(AddressSearchPanel);
