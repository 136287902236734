import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./MobileCart.styl";
import NumberInput from "../NumberInput/NumberInput";
import CartItem from "../CartItem/CartItem";
import CartTotal from "../CartTotal/CartTotal";

class MobileCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data } = this.props;
    return (
      <div className="MobileCart">
        {data.map((c, i) => {
          return <CartItem key={i} {...c} />;
        })}
        <CartTotal
          checkoutClick={() => {}}
          shippableSubtotal={100}
          backorderedSubtotal={900}
          total={1000}
        />
      </div>
    );
  }
}
MobileCart.defaultProps = {};
MobileCart.propTypes = {
  /** data: string  */
  // data: PropTypes.array.isRequired,
};
export default connect(state => state)(MobileCart);
