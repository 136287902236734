import React from "react";
import PropTypes from "prop-types";
import "./Carousel.styl";
import Slider from "react-slick";
import Icon from "../Icon/Icon";

const NextArrow = props => {
  return (<Icon {...props} face="chevron-right" className={props.className} kind="solid"/>);
};

const PrevArrow = props => {
  return <Icon {...props} face="chevron-left" className={props.className}/>;
};

const Carousel = props => {
  const settings = {
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: true
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ],
    speed: 500,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (<div className="Carousel">
    <Slider {...props} {...settings}>
      {props.children}
    </Slider>
  </div>);
};

Carousel.propTypes = {
  /** something  */
  // children: PropTypes.elements.isRequired
};
export default Carousel;
