import React from "react";
import PropTypes from "prop-types";
import SearchElement from "../../components/SearchElement/SearchElement";
import Icon from "../../components/Icon/Icon";
import TextInput from "../../components/TextInput/TextInput";
import QuoteLinesSuggestions from "../../components/QuoteLinesSuggestions/QuoteLinesSuggestions";
import NumberInput from "../../components/NumberInput/NumberInput";
import MarkupInput from "../../components/MarkupInput/MarkupInput";
import QuoteItem from "../../components/QuoteItem/QuoteItem";
import Table from "../../components/Table/Table";
import "./QuoteLines.styl";
import {ColumnCurrency, ColumnMarkup, ColumnQty} from "../../components/Table/TableColumns";
import moment from "moment";
import {labels, icons, colors} from "../../lib/statuses";
import {TimeAgo, Currency, DateFormat} from "../../lib/formatters";

const Status = props => {
  // Props children must be an int between 1 and 5.
  const index = props.children - 1; // Child is a number 1-5
  return (<div className={`icon ${index}`}>
    <Icon face={icons[index]} color={colors[index]} className="statusIcon"/>
  </div>);
};

const MobileTop = props => (<div className="top">
  <QuoteItem {...props}/>
</div>);

const MobileBottom = props => (<div className="bottom">
  <hr/>
  <div className="infoItem">
    <span className="left">User Price:</span>
    <span className="right">
      <Currency>{props.user_price}</Currency>
    </span>
  </div>
  <div className="infoItem">
    <span className="left">Line Price:</span>
    <span className="right">
      <Currency>{props.line_price}</Currency>
    </span>
  </div>
  <div className="infoItem">
    <span className="left">Markup:</span>
    <span className="right">
      <MarkupInput value={props.markup}/>
    </span>
  </div>
  <div className="infoItem">
    <span className="left">Qty:</span>
    <span className="right">
      <NumberInput value={props.qty}/>
    </span>
  </div>
</div>);

class QuoteLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const lines = this.props.lines.map(l => ({
      ...l,
      line_price: l.user_price + l.markup
    }));
    return (<div className="QuoteLines">
      <label>Add Items</label>
      <SearchElement id="quoteLinesId" data={lines} placeholder="Search by keyword">{QuoteLinesSuggestions}</SearchElement>
      <Table limit={20} page={1} headings={[
          {
            label: "Item Description",
            key: "item_desc"
          }, {
            label: "Quanity",
            key: "qty",
            align: "right"
          }, {
            label: "User Price",
            key: "user_price",
            align: "right"
          }, {
            label: "Markup",
            key: "markup",
            align: "right"
          }, {
            label: "Line Price",
            key: "line_price",
            align: "right"
          }
        ]} columns={[QuoteItem, ColumnQty, ColumnCurrency, ColumnMarkup, ColumnCurrency]} data={lines} mobileTop={MobileTop} mobileBottom={MobileBottom}/>
      <div className="notes">
        <TextInput label="Notes" area={true}/>
      </div>
    </div>);
  }
}
QuoteLines.defaultProps = {};
QuoteLines.propTypes = {};
export default QuoteLines;
