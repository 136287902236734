import React from "react";
import { connect } from "react-redux";
import CheckoutHeader from "../../components/CheckoutHeader/CheckoutHeader";
import RadioElement from "../../components/RadioElement/RadioElement";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";
import SummaryItems from "../../components/SummaryItems/SummaryItems";
import ChangeBillingInfo from "../../components/ChangeBillingInfo/ChangeBillingInfo";
import CheckoutFooter from "../../components/CheckoutFooter/CheckoutFooter";
import CheckoutTotal from "../../components/CheckoutTotal/CheckoutTotal";
import CheckoutSection from "../../components/CheckoutSection/CheckoutSection";
import "./CartCheckout.styl";
import { getOneCart } from "../../actions/cart";
import { getCartItems } from "../../actions/cart_items";
import { Currency } from "../../lib/formatters";
const one_cart = {
  name: "My Cart"
};

class CartCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentEdit: false,
      paymentType: "Terms",
      missing: {},
      PONumber: ""
    };
  }

  switchPaymentType = type => {
    this.setState({ paymentType: type });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.dispatch(getCartItems());
    if (id) this.props.dispatch(getOneCart(id));
  }

  updatePONumber = value => {
    const PONumber = value ? value.trim() : "";
    this.setState({ PONumber });
  };

  render() {
    const { cart_items, history } = this.props;
    const numItems = cart_items.length;
    const { PONumber } = this.state;

    const editState = this.state.paymentEdit ? (
      <ChangeBillingInfo
        switchPaymentType={this.switchPaymentType}
        backAction={() => {
          this.setState({ paymentEdit: false });
        }}
      />
    ) : (
      <div className="inner" key={2}>
        <div className="paymentType">
          <h4>Payment Type</h4>
          <p>{this.state.paymentType}</p>
        </div>
        <a
          className="action"
          onClick={e => {
            e.preventDefault();
            this.setState({ paymentEdit: true });
          }}
        >
          Show other payment options
        </a>
      </div>
    );

    const optional = this.state.PONumber ? { href: "/confirm" } : {};

    return (
      <div className="CartCheckout">
        <CheckoutHeader
          history={history}
          heading="Checkout"
          backLabel="Return to Cart"
          backHref="/cart"
        />
        <main>
          <div className="left">
            <CheckoutSection>
              <div className="inner">
                <h1>Billing</h1>
              </div>
              <div className="inner" key={1}>
                <div className="addresses">
                  <h4>Billing Addresses</h4>
                  <p>
                    Company : INGRAM MICRO CAPS TEST ACCOUNT
                    <br />
                    1759 WEHRLE DR
                    <br />
                    WILLIAMSVILLE, NY 142217033
                    <br />
                    Country : United States
                  </p>
                  <a className="action" href="#">
                    Change
                  </a>
                </div>
              </div>
              {editState}
            </CheckoutSection>
            <CheckoutSection>
              <div className="inner">
                <h1>Shipping</h1>
              </div>
              <div className="inner">
                <div className="addresses">
                  <h4>Shipping Addresses</h4>
                  <p>
                    Company: INGRAM MICRO CAPS TEST ACCOUNT
                    <br />
                    1759 WEHRLE DR
                    <br />
                    WILLIAMSVILLE, NY 142217033
                    <br />
                    Country: United States
                  </p>
                  <a className="action" href="#">
                    Change
                  </a>
                </div>
              </div>
              <div className="inner">
                <div className="deliveryOption">
                  <h4>Delivery Option</h4>
                  <RadioElement
                    label="Deliver items when they become available"
                    checked={true}
                  />
                  <RadioElement label="Complete delivery" />
                </div>
              </div>
              <div className="inner">
                <div className="carrierMethod">
                  <h4>Carrier Shipping Method*</h4>
                  <p>Select an option for each warehouse</p>
                  <div className="box">
                    <p>Warehouse: Mira Loma, CA</p>
                    <p>Fedex Ground</p>
                    <p>Transit Days: 1</p>
                    <p>Cost: $28.00</p>
                  </div>
                  <div className="box">
                    <p>Warehouse: Millingyon, TN</p>
                    <p>(RG) RPS GRD</p>
                    <p>Transit Days: 3</p>
                    <p>Cost: $73.11</p>
                  </div>
                </div>
              </div>
            </CheckoutSection>
            <CheckoutSection>
              <div className="inner">
                <h1>Other Options</h1>
              </div>
              <div className="inner">
                <div className="others">
                  <h4>End Customer PO NUmber</h4>
                  <a className="action" href="#">
                    Add
                  </a>
                </div>
                <div className="others">
                  <h4>Cart Notes</h4>
                  <a className="action" href="#">
                    Add
                  </a>
                </div>
              </div>
              <div className="inner">
                <div className="others">
                  <h4>Email Notification</h4>
                  <p>
                    Email notification are always send to
                    <b>john.smoth@ingrammicro.com</b>
                  </p>
                  <a className="action" href="#">
                    Add Alternate Email Address
                  </a>
                </div>
                <div className="others">
                  <h4>Your Information</h4>
                  <p>
                    The information shown below is what Ingram Micro has on
                    record and will show as the person placing the order:
                  </p>
                </div>
                <div className="others">
                  <p>
                    John Smith
                    <br />
                    949-130-5911
                  </p>
                </div>
              </div>
            </CheckoutSection>
          </div>
          <div className="right">
            <CheckoutSection>
              <div className="inner summaryHeading">
                <h1>Summary ({numItems}&nbsp; items)</h1>
                <Currency>{22632}</Currency>
              </div>
              <div className="inner">
                <div className="buttons">
                  <TextInput
                    label="PO Number"
                    onChange={e => this.updatePONumber(e.target.value)}
                    value={PONumber}
                  />
                  <Button
                    color="orange"
                    label="Place Order"
                    {...optional}
                    disabled={!this.state.PONumber}
                    icon="lock"
                  />
                </div>
                <p className="terms">
                  By placing an order, you affirm that you have read,
                  understood, and consent to Ingram Micro’s&nbsp;
                  <a href="#">Sales, Terms and Conditions</a>.
                </p>
              </div>
              <div className="inner">
                <CheckoutTotal
                  shippableSubtotal={13343}
                  numItems={numItems}
                  backorderedSubtotal={9289}
                  total={22632}
                />
              </div>
              <div className="inner">
                <SummaryItems data={cart_items} />
              </div>
            </CheckoutSection>
          </div>
        </main>
        <CheckoutFooter />
      </div>
    );
  }
}

export default connect(state => state)(CartCheckout);
