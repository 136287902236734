import React from "react";
import PropTypes from "prop-types";
import "./QuoteItem.styl";

class QuoteItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.numberFormatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0
    });
  }
  render() {
    const qty = this.numberFormatter.format(this.props.qty);
    const { imageSrc, vpn, sku, name } = this.props;
    return (
      <div className="QuoteItem">
        <div className="group">
          <div className="image-wrap">
            <img src={imageSrc} alt={name} />
          </div>
        </div>
        <div className="group">
          <p className="product-name small">{name}</p>
          <span className="in-stock">{qty}&nbsp; In Stock</span>
          <div className="id-numbers">
            <div>
              <span>VPN:&nbsp;</span>
              <span>{vpn}</span>
            </div>
            <span className="spacer">|</span>
            <div>
              <span>SKU:&nbsp;</span>
              <span>{sku}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
QuoteItem.defaultProps = {};
QuoteItem.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default QuoteItem;
