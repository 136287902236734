import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import "./Breadcrumbs.styl";

const Breadcrumbs = props => {
  const crumbLink = (crumb, index) => {
    if (props.length !== index) {
      return [
        <a key={index} href={crumb.link}>
          {crumb.label}
        </a>,
        <span key={"a" + index}>{props.seperator}</span>
      ];
    } else {
      return <span>{crumb.label}</span>;
    }
  };

  return (<div className="Breadcrumbs">
    <i className="fas fa-arrow-left"/> {
      props.trail.map((crumb, index) => {
        return (<div key={index} className="crumb">
          {crumbLink(crumb, index)}
        </div>);
      })
    }
  </div>);
};
Breadcrumbs.defaultProps = {
  seperator: "/",
  trail: [
    {
      link: "/",
      label: "Home"
    }
  ]
};
Breadcrumbs.propTypes = {
  /** something  */
  trail: PropTypes.array.isRequired,
  seperator: PropTypes.string
};
export default Breadcrumbs;
