import React from "react";
import PropTypes from "prop-types";
import ProductSuggestion from "../ProductSuggestion/ProductSuggestion";
import "./SuggestedProducts.styl";

const Loading = () => {
  return (<div className="Loading">
    <div className="group">
      <div className="image-wrap">
        <span>&nbsp;</span>
      </div>
    </div>
    <div className="info">
      <p className="heading"/>
      <p className="heading-end"/>
      <div className="id-numbers">
        <span className="num">&nbsp;</span>
        <span className="spacer"></span>
        <span className="num">&nbsp;</span>
      </div>
    </div>
  </div>)
}

class SuggestedProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {data} = this.props;
    const loading = !data || data.length == 0;
    const list = !loading
      ? data.map(product => {
        return <ProductSuggestion {...product}/>;
      })
      : <div className="">
        <Loading/>
        <Loading/>
        <Loading/>
        <Loading/>
      </div>

    return (<div className="SuggestedProducts">
      <h3>Suggested Products</h3>
      <div className="list">
        {list}
      </div>
    </div>);
  }
}
SuggestedProducts.defaultProps = {};
SuggestedProducts.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default SuggestedProducts;
