import React from "react";
import PropTypes from "prop-types";
import RadioElement from "../RadioElement/RadioElement";
import TextInput from "../TextInput/TextInput";
import CheckBox from "../CheckBox/CheckBox";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import "./ChangeBillingInfo.styl";

const CCForm = props => {
  return (
    <div className="CCForm">
      <div className="row">
        <TextInput label="Credit card Number" />
      </div>
      <div className="row">
        <TextInput label="Expiration Date" />
        <TextInput label="Security Code" />
      </div>
      <div className="row">
        <TextInput label="First Name" />
        <TextInput label="Last Name" />
      </div>
      <div>
        <CheckBox label="Remember this card or future orders." />
      </div>
      <div className="billingAddress">
        <h4>Credit Card Billing Address</h4>
        <p>
          1759 WEHRLE DR, WILLAMSVILLE,
          <br />
          NY, 14221-7033, United States
        </p>
        <a href="#">Edit your billing address</a>
        <div className="buttons">
          <Button size="small" label="Add" onClick={() => props.backAction()} />
          <Button
            size="small"
            label="Cancel"
            kind="secondary"
            onClick={() => props.backAction()}
          />
        </div>
      </div>
    </div>
  );
};

class ChangeBillingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    };
  }

  selectPaymentType = (selected, typeLabel) => {
    this.setState({ selected });
    this.props.switchPaymentType(typeLabel);
  };

  render() {
    const { selected } = this.state;

    const paymentForm = selected === 4 ? <CCForm {...this.props} /> : "";

    return (
      <div className="ChangeBillingInfo inner">
        <div className="close" onClick={() => this.props.backAction()}>
          <Icon face="times" />
        </div>
        <h4>Payment Type</h4>
        <div className="billingChoices">
          <RadioElement
            onClick={() => this.selectPaymentType(0, "Terms")}
            label="Terms"
            checked={selected === 0}
          />
          <RadioElement
            onClick={() => this.selectPaymentType(1, "Flooring")}
            label="Flooring"
            checked={selected === 1}
          />
          <RadioElement
            onClick={() => this.selectPaymentType(2, "Visa ending in 4192")}
            label="Visa ending in 4192"
            checked={selected === 2}
          />
          <RadioElement
            onClick={() => this.selectPaymentType(3, "Visa ending in 2351")}
            label="Visa ending in 2351"
            checked={selected === 3}
          />
          <RadioElement
            onClick={() => this.selectPaymentType(4, "New Card")}
            label="Add New Card"
            checked={selected === 4}
          />
        </div>
        {paymentForm}
      </div>
    );
  }
}
ChangeBillingInfo.defaultProps = {};
ChangeBillingInfo.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default ChangeBillingInfo;
