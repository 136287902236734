import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SearchElement from "../SearchElement/SearchElement";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import SlidePanel from "../SlidePanel/SlidePanel";
import HeaderSuggestions from "../HeaderSuggestions/HeaderSuggestions";
import NotificationCount from "../NotificationCount/NotificationCount";
import CartPanel from "../CartPanel/CartPanel";
import SwitchCartPanel from "../SwitchCartPanel/SwitchCartPanel";
import NotificationPanel from "../NotificationPanel/NotificationPanel";
import Icon from "../Icon/Icon";
import MobileHeader from "./MobileHeader";

import { getCartItems } from "../../actions/cart_items";
import { getNotifications } from "../../actions/notifications";
import { getCompanies } from "../../actions/companies";
import { getQuoteLines } from "../../actions/quote_lines";
import {
  SWITCH_CART_PANEL,
  switchCartPanel,
  NOTIFICATION_PANEL,
  notificationPanel
} from "../../actions/panel";

import { carts } from "../../../data";

import "./Header.styl";

const logo = require("../../../statics/images/logo.svg");

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return { ...newProps };
  }

  componentDidMount() {
    this.props.dispatch(getCartItems());
    this.props.dispatch(getNotifications());
    this.props.dispatch(getQuoteLines());
  }

  render() {
    const { cart_items, notifications, quote_lines, history } = this.props;
    const { boom } = this.state;
    return (
      <div>
        <div className="Header">
          <div className="topLine">
            <div className="subNav">
              <div className="subNavLeft">
                <a className="navLink" href="#">
                  Shop
                </a>
                <a className="navLink" href="#">
                  Cloud
                </a>
                <a className="navLink" href="#">
                  Click2License
                </a>
                <a className="navLink" href="#">
                  Commerce &amp; Lifecycle
                </a>
              </div>
              <div className="subNavRight">
                <a className="navLink">
                  Region:&nbsp;
                  <span className="region">USA</span>
                </a>
              </div>
            </div>
          </div>
          <div className="midLine">
            <div className="subNav">
              <div
                className="logo"
                dangerouslySetInnerHTML={{
                  __html: logo
                }}
              />
              <SearchElement
                data={quote_lines}
                placeholder="Search by keyword(s), VPN or IM SKU"
              >
                {HeaderSuggestions}
              </SearchElement>
            </div>
          </div>
          <div className="botLine">
            <div className="subNav">
              <div className="subNavLeft">
                <Link className="navLink" to="/product/J930299110">
                  Products
                </Link>
                <a className="navLink" href="#">
                  Vendors
                </a>
                <a className="navLink" href="#">
                  Services
                </a>
              </div>
              <div className="subNavMid" />
              <div className="subNavRight">
                <Link className="navLink" to="/quotes">
                  Quotes
                </Link>
                <a className="navLink" href="#">
                  Orders
                </a>
                <div
                  className="navIcon"
                  onClick={() => this.props.dispatch(notificationPanel())}
                >
                  <NotificationCount count={notifications.length} />
                  <Icon face="bell" color="white" kind="reg" />
                </div>
                <div className="navIcon">
                  <Icon face="user-circle" color="white" kind="reg" />
                </div>
                <div
                  className="navIcon"
                  id="shoppingCartIcon"
                  onClick={() => {
                    window.location.href = "/cart";
                  }}
                >
                  <NotificationCount count={cart_items.length} />
                  <Icon face="shopping-cart" color="white" kind="reg" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileHeader />

        <SlidePanel
          open={this.props.activePanel.open === SWITCH_CART_PANEL}
          closeClick={() => this.props.dispatch(switchCartPanel())}
        >
          <SwitchCartPanel data={carts} />
        </SlidePanel>

        <SlidePanel
          open={this.props.activePanel.open === NOTIFICATION_PANEL}
          closeClick={() => this.props.dispatch(notificationPanel())}
        >
          <NotificationPanel data={notifications} />
        </SlidePanel>
      </div>
    );
  }
}
Header.defaultProps = {};
Header.propTypes = {};
export default connect(state => state)(Header);
export { Header };
