import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import MobileTable from "../Table/MobileTable";
import Icon from "../Icon/Icon";
import TextInput from "../TextInput/TextInput";
import ProductFlag from "../ProductFlag/ProductFlag";
import InStock from "../InStock/InStock";
import NumberInput from "../NumberInput/NumberInput";
import Button from "../Button/Button";
import Notifier from "../Notifier/Notifier";
import MarkupInput from "../MarkupInput/MarkupInput";
import Tooltip from "../Tooltip/Tooltip";
import MobileCartTable from "../MobileCartTable/MobileCartTable";
import CartItemInfo from "../CartItemInfo/CartItemInfo";
import CartItemPanel from "../CartItemPanel/CartItemPanel";
import FixedHeader from "../FixedHeader/FixedHeader";
import TableHeader from "../TableHeader/TableHeader";
import ProductAccessories from "../ProductAccessories/ProductAccessories";
import Table from "../Table/Table";
import "./CartItems.styl";
import { rowNotices } from "../../lib/rowNotices";

import {
  selectWarehousePanel,
  addressPanel,
  notesPanel,
  selectReplacementPanel,
  splitLinePanel
} from "../../actions/panel";
import {
  cartItemsChangeQty,
  cartItemsToggleAccessories,
  cartItemsAddEndUser
} from "../../actions/cart_items";
import cartTableHeadings from "../../../data/cart-list-headings.js";
import {
  ColumnCurrency,
  ColumnMarkup,
  ColumnString
} from "../Table/TableColumns";
import {
  TimeAgo,
  Currency,
  DateFormat,
  NumberFormat
} from "../../lib/formatters";
import { FLAGS } from "../../../data";

const ColumnQty = props => {
  const { children, sku, dispatch } = props;
  return (
    <div className="ColumnQty col">
      <NumberInput
        value={children}
        clickUp={qty => dispatch(cartItemsChangeQty(sku, qty))}
        clickDown={qty => dispatch(cartItemsChangeQty(sku, qty))}
        onChange={qty => dispatch(cartItemsChangeQty(sku, qty))}
      />
    </div>
  );
};

const ConnectedQty = connect(state => state)(ColumnQty);

const InStockColumnComponent = ({ children, id, dispatch }) => {
  return (
    <div className="col in-stock">
      <InStock qty={children && children.qty} />
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          dispatch(selectWarehousePanel());
        }}
      >
        {(children && children.name) || "Select Warehouse"}
      </a>
      <CartItemPanel id={id} />
    </div>
  );
};
const InStockColumn = connect(state => state)(InStockColumnComponent);

const PriceTrending = props => {
  const { children } = props;
  const trending = 0;
  const trendStates = [
    {
      dir: "up",
      color: "green"
    },
    {
      dir: "down",
      color: "red"
    }
  ];
  const { dir, color } = trendStates[trending];
  return (
    <div className="priceTrending">
      <Icon face={`arrow-${dir}`} color={color} />
      <Currency>{children}</Currency>
    </div>
  );
};

const CartItemsHeadingPanelComponent = props => {
  const { numRowsSelected, version, dispatch } = props;
  const shown = numRowsSelected > 0;
  return (
    <div className={`HeadingPanel ${shown ? "shown" : ""}`}>
      <div className="headingActions">
        <Icon face="trash-alt" id={`removeIcon${version}`} />
        <Icon
          face="user-plus"
          id={`userIcon${version}`}
          onClick={() => dispatch(addressPanel())}
        />
        <Icon face="file-alt" id={`notesIcon${version}`} />
      </div>
      <span className="numRows">
        <NumberFormat>{numRowsSelected}</NumberFormat>
        &nbsp;items selected
      </span>
      <Tooltip triggerElement={`#removeIcon${version}`} theme="dark">
        remove
      </Tooltip>
      <Tooltip triggerElement={`#userIcon${version}`} theme="dark">
        add end user
      </Tooltip>
      <Tooltip triggerElement={`#notesIcon${version}`} theme="dark">
        notes
      </Tooltip>
    </div>
  );
};
const CartItemsHeadingPanel = connect(state => state)(
  CartItemsHeadingPanelComponent
);

const CartItemInfoDescriptionComp = props => {
  const {
    imageSrc,
    qty,
    vpn,
    user_price,
    sku,
    upc,
    name,
    flags,
    dispatch
  } = props;

  const productFlags = flags.map((f, index) => (
    <ProductFlag {...FLAGS[f]} key={index} />
  ));

  const endUserLabel = props.end_user
    ? props.end_user.company_name
    : "Add End User";
  return (
    <div className="CartItemInfoDescription">
      <CartItemInfo {...props} />
      <div className="itemActions">
        <div className="buttons">
          <Button
            icon={props.end_user ? "user" : "user-plus"}
            kind="tertiary"
            label={endUserLabel}
            onClick={() => dispatch(addressPanel(sku))}
          />
          <Button
            icon={props.showAfterRow ? "minus" : "plus"}
            kind="tertiary"
            label={`${props.showAfterRow ? "Hide" : "View"} Accessories`}
            onClick={() => dispatch(cartItemsToggleAccessories(sku))}
          />
          <Button
            icon={props.showAfterRow ? "file" : "file-plus"}
            kind="tertiary"
            label={`${props.notes ? "" : "Add"} Notes`}
            onClick={() => dispatch(notesPanel(sku))}
          />
        </div>
      </div>
    </div>
  );
};
const CartItemInfoDescription = connect(state => state)(
  CartItemInfoDescriptionComp
);

class CartItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: {},
      allSelected: false
    };
  }

  toggleHighlight = id => {
    let selected = this.state.selectedRows;
    if (selected[id]) delete selected[id];
    else selected[id] = true;
    this.setState({ selectedRows: selected });
  };

  toggleSelectAll = () => {
    let selectedRows = {};
    if (this.state.allSelected) {
      this.setState({ allSelected: false, selectedRows });
    } else {
      this.props.lines.map(x => {
        selectedRows[x.id] = true;
      });
      this.setState({ selectedRows, allSelected: true });
    }
  };

  render() {
    let { lines, accessories } = this.props;
    const { selectedRows, allSelected } = this.state;
    const numRowsSelected = Object.keys(selectedRows).length;

    lines = lines.map(l => {
      l.line_price = l.user_qty * l.user_price;
      return l;
    });

    return (
      <div className="CartItems">
        <FixedHeader>
          <table className="Table">
            <tbody>
              <TableHeader
                numRowsSelected={numRowsSelected}
                allSelected={allSelected}
                selectable={true}
                headings={cartTableHeadings}
                headingActions={CartItemsHeadingPanel}
                version={"Fixed"}
                toggleSelectAll={this.toggleSelectAll}
              />
            </tbody>
          </table>
        </FixedHeader>
        <MobileCartTable data={lines} rowClick={() => {}} />
        <Table
          limit={20}
          page={1}
          headingActions={CartItemsHeadingPanel}
          headings={cartTableHeadings}
          columns={[
            CartItemInfoDescription,
            InStockColumn,
            ConnectedQty,
            PriceTrending,
            ColumnCurrency
          ]}
          data={lines}
          allSelected={allSelected}
          selectedRows={selectedRows}
          toggleHighlight={this.toggleHighlight}
          toggleSelectAll={this.toggleSelectAll}
          rowNotices={rowNotices}
          afterRow={<ProductAccessories accessories={accessories} />}
        />
      </div>
    );
  }
}
CartItems.defaultProps = {};
CartItems.propTypes = {};
export default CartItems;
