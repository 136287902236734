import React from "react";
import PropTypes from "prop-types";
import "./Tooltip.styl";

const inactive = {
  top: -9999,
  left: -9999,
  active: false
};

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.selfRef = React.createRef();
    this.state = {
      position: props.position,
      active: props.active,
      target: null
    };
  }

  componentDidMount() {
    const targets = Array.from(
      document.querySelectorAll(this.props.triggerElement)
    );
    const { triggerEvent, width, position, triggerDelay } = this.props;
    if (targets.length === 0) return;
    let timeout;
    if (triggerEvent === "hover") {
      targets.map(target => {
        target.addEventListener("mouseout", e => {
          clearTimeout(timeout);
          setTimeout(() => {
            this.setState(inactive);
          }, 0);
        });
        target.addEventListener("mouseover", e => {
          timeout = setTimeout(() => {
            const { top, left } = this.move();
            const active = {
              top,
              left,
              active: true
            };
            this.setState(active);
          }, triggerDelay);
        });
      });
    } else {
      // triggerEvent === "click"
      targets.map(target => {
        target.addEventListener(this.props.triggerEvent, e => {
          const { top, left } = this.move();
          const active = {
            top,
            left,
            active: true
          };
          this.setState(this.state.active ? inactive : active);
        });
      });
    }
  }

  move() {
    let top, left;
    const { position } = this.props;
    const target = document.querySelector(this.props.triggerElement);
    const { clientHeight, clientWidth } = this.selfRef.current;
    console.log("current", this.selfRef.current);
    console.log(
      "height:",
      clientHeight,
      "width:",
      clientWidth,
      "target:",
      target
    );
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = target;
    console.log(
      "offsetTop:",
      offsetTop,
      "offsetLeft:",
      offsetLeft,
      "offsetHeight:",
      offsetHeight,
      "offsetWidth:",
      offsetWidth
    );
    switch (position) {
      case "below":
        top = offsetTop + offsetHeight + 10;
        left = offsetLeft + offsetWidth / 2 - clientWidth / 2;
        break;

      case "above":
        top = offsetTop - clientHeight - 20;
        left = offsetLeft + offsetWidth / 2 - clientWidth / 2;
        break;

      case "right":
        top = offsetTop + offsetHeight / 2 - clientHeight / 2;
        left = offsetLeft + offsetWidth + 20;
        break;

      case "left":
        top = offsetTop + offsetHeight / 2 - clientHeight / 2;
        left = offsetLeft - clientWidth - 20;
        break;
    }
    return { top, left };
  }

  render() {
    const active = this.state.active ? "active" : "";
    let { top, left } = this.state;
    const { width, theme, className } = this.props;
    let classNames = `Tooltip ${theme} ${className || ""} `;
    const { position } = this.props;
    classNames += `${position} ${active}`;
    return (
      <div
        className={classNames}
        ref={this.selfRef}
        style={{ top, left, width }}
        onMouseLeave={() => {
          this.setState(inactive);
        }}
      >
        {this.props.children}
        <div className="cover" />
      </div>
    );
  }
}
Tooltip.defaultProps = {
  position: "below",
  theme: "light",
  active: false,
  triggerEvent: "hover",
  width: "auto",
  triggerDelay: 1000
};
Tooltip.propTypes = {
  /** bool: open or not  */
  active: PropTypes.bool,
  /** triggerElement, selector for the element on the page that shows this tooltip.  */
  triggerElement: PropTypes.string.isRequired,
  /** triggerEvent: what event should show the tooltip?  */
  triggerEvent: PropTypes.oneOf(["hover", "click"]),
  /** triggerDelay: how long to wait before hover triggers tooltip.  */
  triggerDelay: PropTypes.number,
  /** theme, light or dark.  */
  theme: PropTypes.oneOf(["light", "dark", "white"]),
  /** position of the tooltip relative to it's trigger element  */
  position: PropTypes.oneOf(["below", "above", "left", "right"]),
  /** string: width of tooltip  */
  width: PropTypes.string
};
export default Tooltip;
