import React from "react";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import PropTypes from "prop-types";

import "./CouponInput.styl";

class CouponInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.couponCode
    };
  }

  render() {
    const { code } = this.state;
    return (
      <div className="CouponInput">
        <form
          className="inner"
          action="#"
          onSubmit={e => {
            e.preventDefault();
            this.props.onApply(code);
            this.setState({ code: null });
          }}
        >
          <TextInput
            label="Apply a Coupon Code"
            placeholder="Code"
            value={code}
            onChange={e => {
              const { value } = e.target;
              this.setState({ code: value });
            }}
          />
          <Button label="Apply" kind="secondary" form={true} />
          <p className="small">One code per order</p>
        </form>
      </div>
    );
  }
}
CouponInput.defaultProps = {};
CouponInput.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default CouponInput;
