import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import "./CartItemPanel.styl";

class CartItemPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const id = this.props.id;
    return (
      <div className="CartItemPanel">
        <Icon face="heart" kind="light" id={`favoriteIcon${id}`} />
        <Icon face="trash-alt" id={`removeIcon${id}`} />

        <Tooltip triggerElement={`#favoriteIcon${id}`} theme="dark">
          favorite
        </Tooltip>
        <Tooltip triggerElement={`#removeIcon${id}`} theme="dark">
          remove
        </Tooltip>
      </div>
    );
  }
}
CartItemPanel.defaultProps = {};
CartItemPanel.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default CartItemPanel;
