import React from "react";
import PropTypes from "prop-types";
import {NumberFormat} from "../../lib/formatters";
import "./NotificationCount.styl";

class NotificationCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      up: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.count !== this.props.count && prevProps.count !== 0) {
      this.setState({up: false})
      setTimeout(() => {
        this.setState({up: true})
      }, 1);
    }
  }

  render() {
    const up = this.state.up
    const boom = (this.props.count > 10 && up)
    return (
      this.props.count > 0
      ? <div className={`NotificationCount`}>
        <div className={`explosion ${boom
            ? "boom"
            : ""}`}></div>
        <div className={`inner ${up
            ? "up"
            : ""}`}>
          <NumberFormat>{this.props.count}</NumberFormat>
        </div>
      </div>
      : "");
  }
}
NotificationCount.defaultProps = {};
NotificationCount.propTypes = {
  /** count: number  */
  count: PropTypes.number.isRequired
};
export default NotificationCount;
