import React from "react";
import PropTypes from "prop-types";
import "./SuggestedKeywords.styl";

class SuggestedKeywords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="SuggestedKeywords">
        <h3>Suggested Keywords</h3>
        <ol>
          <li>
            <span>macbookpro</span>
            in Laptops
          </li>
          <li>
            <span>macbookair</span>
            in Macbook Air
          </li>
          <li>
            <span>macbook</span>
            in Macbook Air
          </li>
          <li>
            <span>macbook 15</span>
            in Macbook pro
          </li>
          <li>
            <span>macbook 12&quot;</span>
            in Macbook pro
          </li>
          <li>
            <span>macbook touch</span>
            in Macbook pro
          </li>
        </ol>
      </div>
    );
  }
}
SuggestedKeywords.defaultProps = {};
SuggestedKeywords.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default SuggestedKeywords;
