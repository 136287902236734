import React from "react";
import PropTypes from "prop-types";
import "./AddQtyToCart.styl";
import Button from "../Button/Button";
import Loading from "../Loading/Loading";
import Icon from "../Icon/Icon";
import AddToCart from "../AddToCart/AddToCart";
import TextInput from "../TextInput/TextInput";

class AddQtyToCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: props.qty
    };
  }

  onClick() {
    this.setState({
      qty: 1
    });
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({
      qty: value
    });
  }

  render() {
    const { onClick, disabled, ...addProps } = this.props;
    return (
      <div className="AddQtyToCart">
        <TextInput
          height="short"
          disabled={disabled}
          value={this.state.qty}
          onChange={e => this.onChange(e)}
        />
        <AddToCart
          height="short"
          disabled={disabled}
          label="Add"
          onClick={() => this.onClick()}
          {...addProps}
        />
      </div>
    );
  }
}
AddQtyToCart.defaultProps = {};
export default AddQtyToCart;
