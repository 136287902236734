import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel/Carousel";
import SliderItem from "../Carousel/SliderItem";
import ProductListing from "../ProductListing/ProductListing";
import "./ProductAccessories.styl";

class ProductAccessories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { accessories } = this.props;
    return (
      <div className="ProductAccessories">
        <Carousel slidesToShow={5} slidesToScroll={1}>
          {accessories.map((p, i) => {
            p.price = p.user_price;
            return (
              <SliderItem key={i}>
                <ProductListing {...p} showFlags={false} />
              </SliderItem>
            );
          })}
        </Carousel>
      </div>
    );
  }
}
ProductAccessories.defaultProps = {};
ProductAccessories.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default ProductAccessories;
