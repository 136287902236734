import React from "react";
import PropTypes from "prop-types";
import "./CheckBox.styl";

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked
    };
  }

  render() {
    const label = this.props.label ? (
      <label>{this.props.label}</label>
    ) : (
      <label />
    );

    return (
      <div
        className="CheckBox"
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          } else {
            this.setState({ checked: !this.state.checked });
          }
        }}
      >
        <input
          name={this.props.name}
          type="checkbox"
          checked={this.props.checked || this.state.checked}
          className={this.props.className}
          onChange={() => {}}
        />
        {label}
      </div>
    );
  }
}
CheckBox.defaultProps = {
  name: ""
};
CheckBox.propTypes = {
  /** name of the element  */
  name: PropTypes.string.isRequired,
  /** label check to checkbox  */
  label: PropTypes.string,
  /** checked or not  */
  checked: PropTypes.bool,
  /** className to add to <input type="checkbox">  */
  className: PropTypes.string
};
export default CheckBox;
