import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import QuoteDetailHeader from "../../components/QuoteDetailHeader/QuoteDetailHeader";
import QuoteDetailActions from "../../components/QuoteDetailActions/QuoteDetailActions";
import Tabs from "../../components/Tabs/Tabs";
import SlidePanel from "../../components/SlidePanel/SlidePanel";
import QuoteLines from "../../components/QuoteLines/QuoteLines";
import Personalization from "../../components/Personalization/Personalization";
import QuoteInfo from "../../components/QuoteInfo/QuoteInfo";
import QuoteEndUser from "../../components/QuoteEndUser/QuoteEndUser";
import QuoteActivity from "../../components/QuoteActivity/QuoteActivity";
import QuoteTotals from "../../components/QuoteTotals/QuoteTotals";
import "./QuoteDetail.styl";
import { getQuoteLines } from "../../actions/quote_lines";
import { getOneQuote } from "../../actions/quotes";
import { Helmet } from "react-helmet";
import { QUOTE_ACTIVITY_PANEL, quoteActivityPanel } from "../../actions/panel";

class QuoteDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityPanelOpen: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.dispatch(getOneQuote(id));
  }

  render() {
    const { quote_lines, one_quote, history } = this.props;
    return (
      <div className="QuoteDetail">
        <Helmet title="Ingram Micro - Create Quote" />
        <Header history={history} />
        <SlidePanel
          open={this.props.activePanel.open === QUOTE_ACTIVITY_PANEL}
          closeClick={() => this.props.dispatch(quoteActivityPanel())}
        >
          <QuoteActivity />
        </SlidePanel>
        <QuoteDetailHeader quote={one_quote} />
        <QuoteDetailActions quote={one_quote} />
        <main className="col2">
          <div className="left">
            <Tabs tabs={["QUOTE LINES"]} activeTab={0}>
              <QuoteLines lines={quote_lines} />
            </Tabs>
          </div>
          <div className="right">
            <QuoteInfo quote={one_quote} />
            <QuoteEndUser />
            <QuoteTotals />
            <Button label="Send Quote" icon="sign-in-alt" />
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(state => state)(QuoteDetail);
