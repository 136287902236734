import API_BASE_URL from "../lib/apiEndpoint";

export const QUOTES_RESPONSE = "QUOTES_RESPONSE";
export const ONE_QUOTE_RESPONSE = "ONE_QUOTE_RESPONSE";

export const quotesResponse = data => ({ type: QUOTES_RESPONSE, quotes: data });

export const oneQuoteResponse = data => ({
  type: ONE_QUOTE_RESPONSE,
  one_quote: data
});

export const getQuotes = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/quotes?_sort=created_on&_order=desc`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(quotesResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};

export const getOneQuote = id => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/quotes?quote_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(oneQuoteResponse(data[0]));
      })
      .catch(err => Promise.reject(err));
  };
};
