import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import AddToCart from "../AddToCart/AddToCart";
import NumberInput from "../NumberInput/NumberInput";
import InStock from "../InStock/InStock";
import { Currency } from "../../lib/formatters";
import "./ProductInfo.styl";

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user_price, user_qty, qty, msrp, original_price } = this.props;
    return (
      <div className="ProductInfo">
        <div className="specialPricing">
          <span>
            Special Pricing for <a href="#">User</a>
          </span>
          <Icon fontSize={14} face="times" color="black" />
        </div>
        <div className="col3">
          <h2>
            <Currency>{user_price}</Currency>
          </h2>
          <div className="qty">
            <span>Quantity:</span>
            <NumberInput value={user_qty} />
          </div>
          <AddToCart color="orange" />
        </div>
        <div className="col3">
          <div className="productNumbers">
            <div>
              MSRP:&nbsp;<Currency>{msrp}</Currency>&nbsp;(excl. tax)
            </div>
            <div>
              Original&nbsp;Price:&nbsp;<Currency>{original_price}</Currency>
              &nbsp;(excl. tax)
            </div>
          </div>
          <InStock qty={qty} />
        </div>
      </div>
    );
  }
}

export default ProductInfo;
