import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon/Icon";
import MobileNotifications from "../../components/MobileNotifications/MobileNotifications";
import "./NotificationPanel.styl";

class NotificationPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="NotificationPanel">
      <div className="heading">
        <h2>
          <Icon face="bell" kind="reg" color="orange" className="bellNotify"/>
          Notifications</h2>
      </div>
      <div className="inner">
        <div className="verticalLine"/>
        <div className="times"/>
        <MobileNotifications data={this.props.data}/>
      </div>
    </div>);
  }
}
NotificationPanel.defaultProps = {};
NotificationPanel.propTypes = {};
export default NotificationPanel;
