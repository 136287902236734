import React from "react";
import PropTypes from "prop-types";
import "./Icon.styl";

const Icon = props => {
  const {
    face,
    color,
    height,
    width,
    fontSize,
    className,
    onClick,
    backgroundColor,
    rounded,
    id,
    kind
  } = props;
  const kinds = {
    light: "fal",
    reg: "far",
    solid: "fas",
    brand: "fab"
  };
  const fa = kinds[kind];
  return (
    <div
      className={`Icon ${rounded ? "rounded" : ""} ${className || ""}`}
      onClick={onClick}
      id={id}
      style={{
        ...props.style,
        height,
        width,
        fontSize,
        backgroundColor,
        width
      }}
    >
      <i className={`${fa} fa-${face} ${color || ""}`} />
    </div>
  );
};
Icon.defaultProps = {
  kind: "solid"
};
Icon.propTypes = {
  /** css id  */
  id: PropTypes.string,
  /** face from font awesome  */
  face: PropTypes.string.isRequired,
  /** style from font awesome  */
  kind: PropTypes.oneOf(["light", "reg", "solid", "brand"]),
  /** colors from style guide  */
  color: PropTypes.string,
  /** height in px (ie 60px)  */
  height: PropTypes.number,
  /** width in px (ie 60px)  */
  width: PropTypes.number,
  /** icon Size in css fontSize (ie 1.2em or 14px)  */
  fontSize: PropTypes.number,
  /** should the Icon be rounded  */
  rounded: PropTypes.bool,
  /** extra classes for your icon (circle-shadow)  */
  className: PropTypes.string,
  /** background color.  Valid css value  */
  backgroundColor: PropTypes.string
};
export default Icon;
