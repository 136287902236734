//         _..._
//       .'     '.      _     DESIGN SYSTEM TEAM
//      /    .-""-\   _/ \    ==================
//    .-|   /:.   |  |   |    Project Manager: Valentin Khechinashvili
//    |  \  |:.   /.-'-./     Designer: Joyce Xu
//    | .-'-;:__.'    =/      Designer: Mitch Lenzen
//    .'=  *=|     _.='       Designer: Kurt Pennypacker
//   /   _.  |    ;           Developer: Lawrence Whiteside
//  ;-.-'|    \   |
// /   | \    _\  _\  \__/'._;.  ==' ==\           \    \   |
//          /    /   /
//          /-._/-._/
//          \   `\  \            `-._/._/

require("@babel/polyfill");
require("@babel/register");
require("./app");
require("./DemoApp/DemoApp.styl");
