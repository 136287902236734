import React from "react";
import PropTypes from "prop-types";
import "./Button.styl";
import classnames from "classnames";
import Icon from "../Icon/Icon";
import Loading from "../Loading/Loading";

const Button = props => {
  const iconComponent = icon => {
    switch (typeof icon) {
      case "string":
        return <Icon kind={props.iconStyle} face={icon} />;
      case "object":
        return icon;
    }
  };

  let {
    disabled,
    kind,
    icon,
    height,
    label,
    color,
    size,
    form,
    rounded,
    className,
    iconStyle,
    ...htmlProps
  } = props;

  let classNames = `${kind} ${kind}-`;
  classNames += color;

  classNames += " " + (size === "fullwidth" ? "fullwidth" : size);
  classNames += " " + (height === "short" ? "short" : "");

  classNames += classnames({ rounded, disabled });

  classNames += className ? ` ${className}` : "";

  const children = props.children ? (
    props.children
  ) : props.icon ? (
    [
      <div key={0} className="Button-icon">
        {iconComponent(props.icon)}
      </div>,
      <label key={1}>{props.label}</label>
    ]
  ) : (
    <label>{props.label}</label>
  );

  if (disabled) delete htmlProps.onClick;

  const link = (
    <a {...htmlProps} className={`Button ${classNames}`}>
      {children}
    </a>
  );

  const button = (
    <button type="submit" {...htmlProps} className={`Button ${classNames}`}>
      {children}
    </button>
  );
  return form ? button : link;
};

Button.defaultProps = {
  color: "blue",
  kind: "primary",
  label: "Click",
  size: "fullwidth",
  height: "normal",
  iconStyle: "solid",
  form: false
};

Button.propTypes = {
  /** string: primary/secondary  */
  color: PropTypes.oneOf(["blue", "orange", "green", "red", "grey"]),
  /** string: primary/secondary/tertiary  */
  kind: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  /** style from font awesome  */
  iconStyle: PropTypes.oneOf(["light", "reg", "solid", "brand"]),
  /** string: size of the button  */
  size: PropTypes.oneOf(["fullwidth", "large", "small", "square"]),
  /** string: height of the button  */
  height: PropTypes.oneOf(["normal", "short"]),
  /** className adds additional classes for styling the button  */
  className: PropTypes.string,
  /** label for the button text  */
  label: PropTypes.string.isRequired,
  /** is button for a form or a link? */
  form: PropTypes.bool,
  /** is button disabled or not  */
  disabled: PropTypes.bool,
  /** is button rounded or not  */
  rounded: PropTypes.bool,
  /** element: children */
  children: PropTypes.element
};
export default Button;
