export default [
  {
    label: "Item Description",
    key: "item_desc"
  },
  {
    label: "Stock/Warehouse",
    key: "warehouse",
    info: "Select from where you'd like this item."
  },
  {
    label: "Quantity",
    key: "user_qty",
    align: "right"
  },
  {
    label: "Unit Price",
    key: "user_price",
    align: "right"
  },
  {
    label: "Line Price",
    key: "line_price",
    align: "right",
    info: "Quantity x Unit Price"
  }
];
