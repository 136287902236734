import API_BASE_URL from "../lib/apiEndpoint";

export const COMPANIES_RESPONSE = "COMPANIES_RESPONSE";

export const companiesResponse = data => ({
  type: COMPANIES_RESPONSE,
  cart_items: data
});

export const getCompanies = input => {
  return dispatch => {
    fetch(`${API_BASE_URL}/companies?_sort=created_on&_order=desc`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(companiesResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};
