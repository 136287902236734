import React from "react";
import PropTypes from "prop-types";

const MenuItem = props => {
  return (
    <li className="MenuItem">
      <a href={props.link}>{props.label}</a>
      {props.children}
    </li>
  );
};
MenuItem.defaultProps = {};
MenuItem.propTypes = {
  /** string: the menu item text  */
  label: PropTypes.string.isRequired,
  /** string: the clickable link to elsewhere. Optional. */
  link: PropTypes.string
};
export default MenuItem;
