import React from "react";
import PropTypes from "prop-types";
import "./Tabs.styl";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab
    };
  }

  render() {
    const tab = (label, index) => {
      let classes = "tab ";
      classes = classes + (index === this.state.activeTab ? "active" : "");
      return (
        <li
          className={classes}
          key={index}
          onClick={e => {
            e.preventDefault();
            this.setState({ activeTab: index });
          }}
        >
          <a href={`#tabs-large-${index}`}>{label}</a>
        </li>
      );
    };

    const tabPanel = (content, index) => {
      let classes = "tab-panel ";
      classes = classes + (index === this.state.activeTab ? "active" : "");
      return (
        <div key={index} id={`tabs-large-${index}`} className={classes}>
          {content}
        </div>
      );
    };

    const tabs = () =>
      this.props.tabs.map((tabLabel, index) => tab(tabLabel, index));

    const tabContents = () =>
      this.props.tabs.map((tabLabel, index) => {
        const { children } = this.props;
        return tabPanel(children[index] || children, index);
      });

    return (
      <div className="Tabs">
        <div className="tabs tabs-large">
          <ul className="tab-titles">{tabs()}</ul>
          <div className="tabs-container">{tabContents()}</div>
        </div>
      </div>
    );
  }
}
Tabs.propTypes = {
  /** number: activeTab is the tab that is currently shown  */
  activeTab: PropTypes.number.isRequired,
  /** array: A list of tab labels in order that the children are included */
  tabs: PropTypes.array.isRequired
  /** array: Child components with the same length and order as the tabs prop */
  // children: PropTypes.array.isRequired
};
export default Tabs;
