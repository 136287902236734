import React from "react";
import PropTypes from "prop-types";
import "./Menu.styl";

class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <ul className="Menu">{this.props.children}</ul>;
  }
}
Menu.propTypes = {
  /** element: should the menus work on hover or click.  */
  children: PropTypes.array.isRequired
};
Menu.defaultProps = {};
export default Menu;
