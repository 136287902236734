import React from "react";
import "./CartFinePrint.styl";

const CartFinePrint = () => {
  return (
    <div className="CartFinePrint">
      <p>
        * The backordered product subtotal is estimated and is subject to change
        once the backordered products become available.
        <br />
        ** This total does not include shipping, taxes, or other applicable
        fees.
      </p>
      <h4>Sales Terms and Conditions</h4>
      <p>
        <b>Note:&nbsp;</b>The prices and availabilities displayed may not
        represent the most recent price or availability. Availability represents
        the total number of products available from all warehouses. Ingram Micro
        honors the real-time price for electronic orders. Verify prices on the
        Order View screen.
      </p>
      <p>
        <b>Warning:&nbsp;</b>
        If you receive special pricing from Ingram Micro based on a
        manufacturer's pricing program AND you are requesting
        government/education pricing, the price displayed on the website will
        not be accurate or honored. Please call your Ingram Micro sales rep for
        a valid price quote. End-User
      </p>
      <p>
        <b>Information:&nbsp;</b>To expedite your order and ensure the most
        accurate system pricing, please include and select the necessary
        end-user information. Web Only
      </p>
      <p>
        <b>Pricing:&nbsp;</b>These discounts are only valid for online orders.
      </p>
      <p>
        <b>Shipping Days:&nbsp;</b>
        Number of transit days is determined by the carrier business days.
        Orders placed after order cutoff times will be shipped the next business
        day.
      </p>
      <p>
        <b>Free Freight Order Minimums:&nbsp;</b>
        Orders may be subject to a five dollar minimum order fee depending upon
        your service agreement.
      </p>
      <p>
        Ingram Micro or its content providers grant you a limited,
        non-exclusive, non-transferable, non-sublicensable license to access and
        use the content contained on its website for the exclusive purpose of
        purchasing products and services (collectively "Products") from Ingram
        Micro for subsequent sale of such Products by you to your end user
        customers. This license does not include a right to resell the content
        i.e. Product listings, descriptions, or prices nor the right to make any
        derivative use of the content. Any downloading, copying, or other use of
        account information for the benefit of any third party or any use of
        data mining, robots, or similar data gathering and extraction tools is
        strictly prohibited.
      </p>
    </div>
  );
};

export default CartFinePrint;
