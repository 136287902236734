import React from "react";
import PropTypes from "prop-types";
import ProductSuggestion from "../ProductSuggestion/ProductSuggestion";
import SuggestedCategories from "../SuggestedCategories/SuggestedCategories";
import SuggestedKeywords from "../SuggestedKeywords/SuggestedKeywords";
import SuggestedProducts from "../SuggestedProducts/SuggestedProducts";
import "./HeaderSuggestions.styl";

class HeaderSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {data} = this.props;
    const loading = !data || data.length == 0;
    return (<div className={`HeaderSuggestions ${loading
        ? "loading"
        : ""}`}>
      <div className="inner">
        <div className="left">
          <SuggestedCategories/>
          <SuggestedKeywords/>
          <div className="seperator"/>
        </div>
        <div className="right">
          <SuggestedProducts data={this.props.data}/>
          <div className="seeAllResults">See All Results</div>
        </div>
        <div className="loading"/>
      </div>
    </div>);
  }
}
HeaderSuggestions.defaultProps = {};
HeaderSuggestions.propTypes = {
  /** data: array  */
  data: PropTypes.array.isRequired
};
export default HeaderSuggestions;
