import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import SlidePanel from "../../components/SlidePanel/SlidePanel";
import CartHeader from "../../components/CartHeader/CartHeader";
import CartItems from "../../components/CartItems/CartItems";
import CartTotal from "../../components/CartTotal/CartTotal";
import CartActions from "../../components/CartActions/CartActions";
import AddressSearchPanel from "../../components/AddressSearchPanel/AddressSearchPanel";
import QuickOrderPanel from "../../components/QuickOrderPanel/QuickOrderPanel";
import AccessoriesPanel from "../../components/AccessoriesPanel/AccessoriesPanel";
import SelectWarehousePanel from "../../components/SelectWarehousePanel/SelectWarehousePanel";
import SelectReplacementPanel from "../../components/SelectReplacementPanel/SelectReplacementPanel";
import SplitLinePanel from "../../components/SplitLinePanel/SplitLinePanel";
import NotesPanel from "../../components/NotesPanel/NotesPanel";
import CartSettingsPanel from "../../components/CartSettingsPanel/CartSettingsPanel";
import CartFinePrint from "../../components/CartFinePrint/CartFinePrint";
import { Helmet } from "react-helmet";
import "./Cart.styl";

import { getOneCart } from "../../actions/cart";
import {
  ADDRESS_PANEL,
  addressPanel,
  NOTES_PANEL,
  notesPanel,
  QUICK_ORDER_PANEL,
  quickOrderPanel,
  ACCESSORIES_PANEL,
  accessoriesPanel,
  QUOTE_ACTIVITY_PANEL,
  quoteActivityPanel,
  SELECT_WAREHOUSE_PANEL,
  selectWarehousePanel,
  SELECT_REPLACEMENT_PANEL,
  selectReplacementPanel,
  SPLIT_LINE_PANEL,
  splitLinePanel,
  CART_SETTINGS_PANEL,
  cartSettingsPanel
} from "../../actions/panel";

const one_cart = {
  name: "My Cart"
};

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) this.props.dispatch(getOneCart(id));
  }

  render() {
    const { cart_items, history, products, activePanel, dispatch } = this.props;
    let shippableSubtotal = 0,
      backOrderedSubtotal = 0;
    const numItems = cart_items.length;
    const mid = Math.ceil(numItems / 2.0);
    if (mid > 0) {
      const backOrderItems = cart_items.slice(0, mid);
      const shippableItems = cart_items.slice(mid, numItems);
      shippableSubtotal = shippableItems.reduce(
        (acc, item) => acc + item.line_price,
        0
      );
      backOrderedSubtotal = backOrderItems.reduce(
        (acc, item) => acc + item.line_price,
        0
      );
    }

    return (
      <div className="Cart">
        <Helmet title="Ingram Micro - Cart" />
        <Header history={history} />
        <CartHeader {...one_cart} />
        <CartActions
          {...one_cart}
          subtotal={shippableSubtotal + backOrderedSubtotal}
        />
        <main>
          <CartItems lines={cart_items} accessories={cart_items} />
          <CartTotal
            checkoutClick={() => {
              window.location.href = "/checkout";
            }}
            couponCode={this.state.couponCode}
            numItems={numItems}
            shippableSubtotal={shippableSubtotal}
            backorderedSubtotal={backOrderedSubtotal}
            total={shippableSubtotal + backOrderedSubtotal}
          />
          <CartFinePrint />
          <SlidePanel
            open={activePanel.open === ADDRESS_PANEL}
            closeClick={() => dispatch(addressPanel())}
          >
            <AddressSearchPanel closeClick={() => dispatch(addressPanel())} />
          </SlidePanel>
          <SlidePanel
            open={activePanel.open === QUICK_ORDER_PANEL}
            closeClick={() => dispatch(quickOrderPanel())}
          >
            <QuickOrderPanel closeClick={() => dispatch(quickOrderPanel())} />
          </SlidePanel>
          <SlidePanel
            open={activePanel.open === SELECT_WAREHOUSE_PANEL}
            closeClick={() => dispatch(selectWarehousePanel())}
          >
            <SelectWarehousePanel
              closeClick={() => dispatch(selectWarehousePanel())}
            />
          </SlidePanel>
          <SlidePanel
            open={activePanel.open === SELECT_REPLACEMENT_PANEL}
            closeClick={() => dispatch(selectReplacementPanel())}
          >
            <SelectReplacementPanel />
          </SlidePanel>

          <SlidePanel
            open={activePanel.open === SPLIT_LINE_PANEL}
            closeClick={() => dispatch(splitLinePanel())}
          >
            <SplitLinePanel closeClick={() => dispatch(splitLinePanel())} />
          </SlidePanel>

          <SlidePanel
            open={activePanel.open === CART_SETTINGS_PANEL}
            closeClick={() => dispatch(cartSettingsPanel())}
          >
            <CartSettingsPanel
              closeClick={() => dispatch(cartSettingsPanel())}
            />
          </SlidePanel>
          <SlidePanel
            open={activePanel.open === ACCESSORIES_PANEL}
            closeClick={() => dispatch(accessoriesPanel())}
          >
            <AccessoriesPanel />
          </SlidePanel>
          <SlidePanel
            open={activePanel.open === NOTES_PANEL}
            closeClick={() => dispatch(notesPanel())}
          >
            <NotesPanel closeClick={() => dispatch(notesPanel())} />
          </SlidePanel>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(state => state)(Cart);
