import React from "react"
import PropTypes from "prop-types"
import "./CheckoutSection.styl"

class CheckoutSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="CheckoutSection">
      {this.props.children}
    </div>)
  }
}
CheckoutSection.defaultProps = {}
CheckoutSection.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
}
export default CheckoutSection
