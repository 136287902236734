import { ADD_FILTER } from "../actions/filters";

const initialState = {
  input: null,
  value: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      const { input, value } = action;
      return { ...state, input, value };
    default:
      return state;
  }
};
