import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import "./Footer.styl";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="Footer">
        <div className="inner">
          <div className="info">
            <h3>Company Information</h3>
            <div className="footerLinks">
              <ul>
                <li>
                  <a href="#">About Ingram Micro</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Financial Information</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="#">Newsroom</a>
                </li>
                <li>
                  <a href="#">Social Responsibility</a>
                </li>
                <li>
                  <a href="#">Solutions</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="shop">
            <h3>Shop Ingram Micro</h3>
            <div className="footerLinks">
              <ul>
                <li>
                  <a href="#">Products</a>
                </li>
                <li>
                  <a href="#">Vendors</a>
                </li>
                <li>
                  <a href="#">Showcases</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="#">Vendor Boutiques</a>
                </li>
                <li>
                  <a href="#">Help</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="social">
            <div className="icons">
              <a
                className="facebook linkOut"
                target="_blank"
                href="https://www.facebook.com/IngramMicro/"
              >
                <Icon face="facebook-square" kind="brand" />
              </a>
              <a
                className="twitter linkOut"
                target="_blank"
                href="https://twitter.com/IngramMicroInc"
              >
                <Icon face="twitter" kind="brand" />
              </a>
              <a
                className="youtube linkOut"
                target="_blank"
                href="https://www.youtube.com/ingrammicroinc"
              >
                <Icon face="youtube" kind="brand" />
              </a>
              <a
                className="linkedin linkOut"
                target="_blank"
                href="https://www.linkedin.com/company/ingram-micro"
              >
                <Icon face="linkedin" kind="brand" />
              </a>
            </div>
          </div>
        </div>
        <div className="botLine">
          <div className="copyright">
            Copyright &copy; Ingram Micro. All rights reserved.
          </div>
          <div className="botLinks">
            <a href="/privacy">Privacy</a>
            <span>|</span>
            <a href="/terms">Terms of Use</a>
          </div>
        </div>
      </div>
    );
  }
}
Footer.defaultProps = {};
Footer.propTypes = {};
export default Footer;
