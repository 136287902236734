import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import SelectMenu from "../SelectMenu/SelectMenu";
import TextInput from "../TextInput/TextInput";
import RadioElement from "../RadioElement/RadioElement";
import "./NotesPanel.styl";

class NotesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { security, warehouse } = this.state;
    return (
      <div className="NotesPanel">
        <h2>
          <span>Add Note</span>
        </h2>
        <div className="inner">
          <TextInput area={true} label="" placeholder="Start typing here" />
          <div className="buttons">
            <Button
              label="Cancel"
              size="small"
              kind="secondary"
              onClick={this.props.closeClick}
            />
            <Button label="Save" size="small" onClick={this.props.closeClick} />
          </div>
        </div>
      </div>
    );
  }
}
NotesPanel.defaultProps = {};
NotesPanel.propTypes = {};
export default NotesPanel;
