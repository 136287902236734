import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon/Icon";
import classnames from "classnames";
import "./MobileMenu.styl";

const MobileMenuLink = props => {
  const { label, href, face, className } = props;
  return (
    <a className={`MobileMenuLink ${className || ""}`} href={href}>
      {label}
    </a>
  );
};

const MobileMenuItem = props => {
  const { label, icon, face, className, href } = props;

  const labelDiv = icon ? (
    <div className="inner">
      <Icon face={icon} />
      My Account
    </div>
  ) : (
    label
  );
  const onClick = href ? e => (window.location.href = href) : () => {};
  return (
    <div className={`MobileMenuItem ${className || ""}`} onClick={onClick}>
      {labelDiv}
      <Icon face="chevron-right" className="menuLink" />
    </div>
  );
};

const MobileMenuHeading = props => {
  const { label, icon, face, className } = props;
  const labelDiv = icon ? (
    <div className="inner">
      <Icon face={icon} />
      My Account
    </div>
  ) : (
    label
  );

  return (
    <div
      className={`MobileMenuHeading ${className || ""}`}
      onClick={e => props.onClick()}
    >
      <Icon face="chevron-left" className="menuBack" /> {labelDiv}
    </div>
  );
};

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 1,
      panel: "home"
    };
  }
  render() {
    const { level, panel } = this.state;
    return (
      <div className={`MobileMenu level-${level} panel-${panel}`}>
        <div className="panel level1 home">
          <MobileMenuItem
            label="My Account"
            icon="user-circle"
            className="account"
            onClick={e => {
              this.setState({ level: 2, panel: "account" });
            }}
          />
          <MobileMenuItem label="Home" href="/" />
          <MobileMenuItem label="Products" href="/product/J930299110" />
          <MobileMenuItem label="Vendors" />
          <MobileMenuItem label="Services" />
          <MobileMenuItem label="Quotes" href="/quotes" />
          <MobileMenuLink label="Shop" href="#" />
          <MobileMenuLink label="Cloud" href="#" />
          <MobileMenuLink label="Click2License" href="#" />
          <MobileMenuLink label="Commerce &amp; Lifecycle" href="#" />
          <MobileMenuLink label="Region: United States" href="#" />
        </div>

        <div className="panel level2 account">
          <MobileMenuHeading
            label="My Account"
            className="navBack"
            onClick={() => {
              this.setState({ level: 1, panel: "home" });
            }}
          />
          <MobileMenuItem label="My Profile" />
          <MobileMenuItem label="Company Profile" />
          <MobileMenuItem label="Saved Searches" />
          <MobileMenuItem label="Favorite Products" />
          <MobileMenuLink label="Carts" href="#" />
          <MobileMenuLink label="Invoice Gateway" href="#" />
          <MobileMenuLink label="BillTrust" href="#" />
          <MobileMenuLink label="Order List" href="#" />
          <MobileMenuLink label="Quotes" href="/quotes" />
          <MobileMenuLink label="Return Managements" href="#" />
          <MobileMenuLink label="Vender Bids" href="#" />
          <MobileMenuLink label="Quick Order Entry" href="#" />
          <MobileMenuLink label="Expert Entry" href="#" />
          <MobileMenuLink label="Logout" href="#" />
        </div>
      </div>
    );
  }
}
MobileMenu.defaultProps = {};
MobileMenu.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default MobileMenu;
