import API_BASE_URL from "../lib/apiEndpoint";
export const QUOTELINES_RESPONSE = "QUOTELINES_RESPONSE";

export const quoteLinesResponse = data => ({
  type: QUOTELINES_RESPONSE,
  quote_lines: data
});

export const getQuoteLines = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/quote_lines?_sort=created_on&_order=desc`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(quoteLinesResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};
