import React from "react";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import "./Accordion.styl";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
  }

  onClick() {
    this.setState({
      open: !this.state.open
    });
  }

  componentWillReceiveProps(props) {
    this.setState({ open: props.open });
  }

  render() {
    const { open } = this.state;
    const {
      flipped,
      className,
      visibleButton,
      justButton,
      closeButton,
      openButton,
      visible,
      hidden,
      alignment
    } = this.props;

    const toggleButton = visibleButton ? (
      <div
        className="toggleButton"
        onClick={() => {
          if (justButton) this.onClick();
        }}
      >
        {open ? closeButton : openButton}
      </div>
    ) : (
      ""
    );

    const classes = `Accordion ${open ? "open" : ""} ${
      flipped ? "flipped" : ""
    } ${alignment}
        ${visibleButton ? "" : "hideButton"}
        ${className || ""}`;
    return (
      <div className={classes}>
        <div
          className="visible"
          onClick={() => {
            if (!justButton) this.onClick();
          }}
        >
          {visible}
          {toggleButton}
        </div>
        <div className="hidden">{hidden}</div>
      </div>
    );
  }
}
Accordion.defaultProps = {
  open: false,
  justButton: false,
  visibleButton: true,
  flipped: false,
  alignment: "rightAlign",
  openButton: <Icon face="chevron-down" color="blue" />,
  closeButton: <Icon face="chevron-up" color="blue" />
};
Accordion.propTypes = {
  /** open: bool, does it start open?  */
  open: PropTypes.bool.isRequired,
  /** flipped: bool, puts the hidden on top, (drawer unfolds upwards)  */
  flipped: PropTypes.bool,
  /** does it toggle with just clicking the button or when clicking the entire visible area */
  justButton: PropTypes.bool,
  /** should the button be visible? */
  visibleButton: PropTypes.bool,
  /** react element that serves as the open button */
  openButton: PropTypes.element,
  /** react element that serves as the close button */
  closeButton: PropTypes.element,
  /** string that decides which side to put the openButton on. */
  alignment: PropTypes.oneOf(["leftAlign", "rightAlign"])
};
export default Accordion;
