export const NEXT = "NEXT";
export const nextPage = () => ({
  type: NEXT
});

export const SET_LIMIT = "SET_LIMIT";
export const setLimit = limit => ({
  type: SET_LIMIT,
  limit
});

export const PREV = "PREV";
export const prevPage = () => ({
  type: PREV
});

export const GOTO_END = "GOTO_END";
export const gotoEnd = page => ({
  type: GOTO_END,
  page: page
});

export const GOTO_START = "GOTO_START";
export const gotoStart = () => ({
  type: GOTO_START
});

export const GOTO_PAGE = "GOTO_PAGE";
export const gotoPage = page => ({
  type: GOTO_PAGE,
  page: page
});
