import React from "react";
import PropTypes from "prop-types";
import NumberInput from "../NumberInput/NumberInput";
import InStock from "../InStock/InStock";
import { Currency } from "../../lib/formatters";
import Notifier from "../Notifier/Notifier";
import Icon from "../Icon/Icon";
import "./CartItem.styl";

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.numberFormatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0
    });
    this.currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
  }

  render() {
    const { imageSrc, qty, vpn, user_price, sku, upc, name } = this.props;
    const notification =
      qty > 0 ? (
        ""
      ) : (
        <Notifier
          status="error"
          message="This item is currently out of stock.  Please remove it before proceeding to checkout."
        />
      );

    return (
      <div className="CartItem">
        <div className="inner">
          <div className="group">
            <div className="image-wrap">
              <img src={imageSrc} alt={name} />
            </div>
          </div>
          <div className="group">
            <p className="product-name small">{name}</p>
            <div className="id-numbers">
              <span>VPN:&nbsp;</span>
              <span>{vpn}</span>
              <span className="spacer">|</span>
              <span>SKU:&nbsp;</span>
              <span>{sku}</span>
              <span className="spacer">|</span>
              <span>UPC:&nbsp;</span>
              <span>{upc}</span>
            </div>
            <div className="col3">
              <InStock qty={qty} />
              <div className="middle">
                <NumberInput />
                <Icon face="trash" color="grey1" className="deleteButton" />
              </div>
              <span className="price">
                <Currency>{user_price}</Currency>
              </span>
            </div>
          </div>
        </div>
        {notification}
      </div>
    );
  }
}
CartItem.defaultProps = {};
CartItem.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default CartItem;
