import React from "react";
import PropTypes from "prop-types";

const SliderItem = props => {
  return <div className="SliderItem">{props.children}</div>;
};
SliderItem.defaultProps = {};
SliderItem.propTypes = {
  /** element: something to go in the slider  */
  children: PropTypes.element.isRequired
};
export default SliderItem;
