import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CheckBox from "../../components/CheckBox/CheckBox";
import Icon from "../../components/Icon/Icon";
import SearchResultsTop from "../../components/SearchResultsTop/SearchResultsTop";
import SearchResultsBot from "../../components/SearchResultsBot/SearchResultsBot";
import ProductRecommendations from "../../components/ProductRecommendations/ProductRecommendations";
import TextInput from "../../components/TextInput/TextInput";
import Pagination from "../../components/Pagination/Pagination";
import SelectMenu from "../../components/SelectMenu/SelectMenu";
import ProductListing from "../../components/ProductListing/ProductListing";
import Button from "../../components/Button/Button";
import SearchFilters from "../../components/SearchFilters/SearchFilters";
import Tabs from "../../components/Tabs/Tabs";
import SlidePanel from "../../components/SlidePanel/SlidePanel";
import Carousel from "../../components/Carousel/Carousel";
import "./Search.styl";
import { getOneProduct, getProducts } from "../../actions/products";

const CheckFilters = ({ filters }) => {
  return (
    <div className="CheckFilters">
      {filters.map(f => (
        <CheckBox label={f} />
      ))}
    </div>
  );
};

const Accessories = props => {
  const { products } = props;
  return (
    <div className="Accessories">
      <Carousel slidesToShow={4} slidesToScroll={1}>
        {products.map((product, i) => {
          return (
            <SliderItem key={i}>
              <ProductListing {...product} />
            </SliderItem>
          );
        })}
      </Carousel>
    </div>
  );
};

const MobileFilters = props => {
  return (
    <div className="MobileFilters">
      <Button
        label="Filter"
        kind="secondary"
        onClick={e => props.toggleMobileFilters()}
      />
      <SelectMenu
        placeholder="Sort by"
        options={[
          { label: "Relevance", value: "relevance" },
          { label: "Lowest Price", value: "lowPrice" },
          { label: "Highest Price", value: "hiPrice" },
          { label: "Alphabetically", value: "alpha" }
        ]}
        label=""
      />
      <div className="viewTypes">
        <Icon
          face="list"
          onClick={() => props.viewChange("list")}
          className="listView"
          color="blue"
        />
        <Icon
          face="th-large"
          onClick={() => props.viewChange("grid")}
          className="gridView"
          color="blue"
        />
      </div>
    </div>
  );
};

const SearchHeader = () => (
  <div className="SearchHeader">
    <div className="left">
      <h1>
        Search Results for <span>"Apple"</span>
      </h1>
      <a href="#" className="clearFilters">
        Clear all filters
      </a>
    </div>
    <div className="right">
      <Button
        className="saveSearch"
        kind="tertiary"
        label="Save Search"
        icon="bookmark"
        iconStyle="light"
      />
    </div>
  </div>
);

const MobileSearchHeader = () => (
  <div className="MobileSearchHeader">
    <h1>
      Search Results for <span>"Apple"</span>
    </h1>
    <div className="count">Showing 1 - 12 of 20,456</div>
  </div>
);

const catFilters = [
  "Accessories",
  "Storage Devices",

  "Physical Security",
  "System Components",
  "Home Applicances",
  "Computer",
  "Computer Systems"
];

const vendorFilters = [
  "Apple",
  "Startech.com",
  "INCASE",
  "Tucano",
  "Compulocks",
  "STM Goods",
  "Mobile Edge",
  "Rocstor"
];

const statusFilters = [
  "In Stock",
  "Authorize to Purchase",
  "On Order",
  "Direct Ship",
  "Non-purchasable",
  "Discontinued"
];

const techSpecsBody = (
  <div className="SelectMenuFilters">
    <SelectMenu placeholder="Select one" label="Product Type" options={[]} />
    <SelectMenu
      placeholder="Select one"
      label="Application/Usage"
      options={[]}
    />
    <SelectMenu placeholder="Select one" label="Color" options={[]} />
    <SelectMenu placeholder="Select one" label="Screen Size" options={[]} />
    <SelectMenu placeholder="Select one" label="Standard Memory" options={[]} />
    <SelectMenu placeholder="Select one" label="Processor Type" options={[]} />
    <a href="#">See all</a>
  </div>
);

const catBody = <CheckFilters filters={catFilters} />;
const vendorBody = (
  <div>
    <CheckFilters filters={vendorFilters} />
    <a href="#">See all</a>
  </div>
);
const statusBody = (
  <div>
    <CheckFilters filters={statusFilters} />
    <a href="#">See all</a>
  </div>
);
const priceBody = (
  <div className="PriceBody">
    <div className="inner">
      <span>$</span>
      <TextInput label="" placeholder="Min" />
      <span>-</span>
      <TextInput label="" placeholder="Min" />
      <Button label="Apply" />
    </div>
  </div>
);

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "grid",
      filtersPanelOpen: false
    };
  }

  componentDidMount() {
    this.props.dispatch(getProducts());
  }

  toggleMobileFilters = () => {
    this.setState({
      filtersPanelOpen: !this.state.filtersPanelOpen
    });
  };

  render() {
    const { products, history } = this.props;
    const { view, filtersPanelOpen } = this.state;
    return (
      <div className={`Search ${view}View`}>
        <Helmet title="Ingram Micro - Search" />
        <Header history={history} />
        <main>
          <SearchHeader />
          <MobileSearchHeader />
          <div className="SearchMain">
            <div
              className={`SearchFilterPanel ${filtersPanelOpen ? "open" : ""}`}
            >
              <div className="actionButtons">
                <Button
                  label="Reset"
                  kind="secondary"
                  onClick={() => this.toggleMobileFilters()}
                />
                <Button
                  label="Apply"
                  onClick={() => this.toggleMobileFilters()}
                />
              </div>
              <div className="inner">
                <SearchFilters
                  header={
                    <div className="inner">
                      <a href="#">Clear</a>
                      <h3>Categories</h3>
                    </div>
                  }
                  body={catBody}
                />
                <SearchFilters
                  header={
                    <div className="left">
                      <h3>Vendors</h3>
                    </div>
                  }
                  body={vendorBody}
                />
                <SearchFilters
                  header={
                    <div className="left">
                      <h3>Tech Specs</h3>
                    </div>
                  }
                  body={techSpecsBody}
                />
                <SearchFilters
                  header={
                    <div className="left">
                      <h3>Price</h3>
                    </div>
                  }
                  body={priceBody}
                />
                <SearchFilters
                  header={
                    <div className="left">
                      <h3>Status</h3>
                    </div>
                  }
                  body={statusBody}
                />
              </div>
            </div>
            <div className="SearchResults">
              <SearchResultsTop
                view={view}
                viewChange={v => this.setState({ view: v })}
              />
              <MobileFilters
                mobileFiltersOpen={filtersPanelOpen}
                toggleMobileFilters={this.toggleMobileFilters}
                viewChange={view => this.setState({ view })}
              />
              <div className="inner">
                {products.map(p => (
                  <ProductListing {...p} />
                ))}
              </div>
              <Pagination />
              <SearchResultsBot />
              <ProductRecommendations
                heading="Recently Viewed"
                products={products}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(state => state)(Search);
