export const labels = [
  "New Quote", // 1
  "Quote Sent", // 2
  "Approved", // 3
  "Rejected", // 4
  "Closed" // 5
];
export const icons = [
  "file-plus",
  "sign-in-alt",
  "check-circle",
  "ban",
  "exclamation-circle"
];
export const colors = ["blue", "yellow", "green", "red", "purple"];
