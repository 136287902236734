import React from "react";
import PropTypes from "prop-types";
import "./TextInput.styl";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  onChange = e => {
    if (this.props.onChange) this.props.onChange(e);
    else e => this.setState({ value: e.target.value });
  };

  render() {
    let {
      label,
      area,
      error,
      disabled,
      className,
      height,
      ...inputProps
    } = this.props;
    let value = this.props.value || this.state.value;
    let errorElement = "";
    if (error) {
      errorElement = <p className="error-description">{error}</p>;
    }
    const inputElement = area ? (
      <textarea
        {...inputProps}
        value={value}
        onChange={e => this.onChange(e)}
        className={`input ${error ? "error " : ""}${className || ""}`}
      />
    ) : (
      <input
        {...inputProps}
        value={value}
        onChange={e => this.onChange(e)}
        className={`input ${error ? "error " : ""}${className || ""}`}
      />
    );

    const labelElement = label ? <label>{label}</label> : "";
    return (
      <div className={`TextInput ${height ? height + " " : ""}`}>
        {labelElement}
        {inputElement}
        {errorElement}
      </div>
    );
  }
}
TextInput.defaultProps = {
  disabled: false,
  type: "text",
  height: "normal",
  area: false
};
TextInput.propTypes = {
  /** value of the input  */
  value: PropTypes.string,
  /** label of the input  */
  label: PropTypes.string,
  /** height of the input  */
  height: PropTypes.oneOf(["short", "normal"]),
  /** error of the input  */
  error: PropTypes.string,
  /** placeholder of the input  */
  placeholder: PropTypes.string,
  /** is the input disabled  */
  disabled: PropTypes.bool,
  /** Is this a textarea element  */
  area: PropTypes.bool,
  /** extra styling  */
  className: PropTypes.string,
  /** type  */
  type: PropTypes.oneOf(["text", "email", "password", "number"]),
  /** name, used in form data  */
  name: PropTypes.string
};
export default TextInput;
