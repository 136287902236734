import React from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux";
import "./QuoteLinesSuggestions.styl"
import ProductSuggestionAdder from "../../components/ProductSuggestionAdder/ProductSuggestionAdder";
import {addCartItem} from "../../actions/cart_items";

const Loading = () => {
  return (<div className="LoadingSkeleton">
    <div className="group">
      <div className="image-wrap"></div>
    </div>
    <div className="info">
      <div className="heading"></div>
      <div className="heading"></div>
      <div className="heading"></div>
      <div className="id-numbers">
        <span className="num"></span>
        <span className="spacer"></span>
        <span className="num"></span>
      </div>
    </div>
    <div className="price">
      <span></span>
    </div>
    <div className="group number-input">
      <div className="col2">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>)
}

class QuoteLinesSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    const loading = !this.props.data || this.props.data.length == 0;
    const {data} = this.props;
    const list = !loading
      ? data.map(product => {
        return <ProductSuggestionAdder {...product} addClick={() => this.props.dispatch(addCartItem(product))}/>;
      })
      : <div className="">
        <Loading/>
        <Loading/>
        <Loading/>
        <Loading/>
      </div>

    return (<div className={`QuoteLinesSuggestions ${loading
        ? "loading"
        : ""}`}>{list}
    </div>)
  }
}
QuoteLinesSuggestions.defaultProps = {}
QuoteLinesSuggestions.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
}
export default connect(state => state)(QuoteLinesSuggestions);
