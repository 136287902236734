import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker, {DateUtils} from "react-day-picker";
import {formatDate, parseDate} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "./DatePicker.styl";
import CalendarNav from "./lib/CalendarNav";
import TextInput from "../TextInput/TextInput";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: undefined,
      to: undefined
    };
  }

  handleDayClick = day => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  };

  showFromMonth() {
    const {from, to} = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  handleFromChange = from => {
    this.setState({from});
  };
  handleToChange = to => {
    this.setState({
      to
    }, this.showFromMonth);
  };

  render() {
    const {from, to} = this.state;
    const {multi} = this.props;
    const modifiers = {
      start: from,
      end: to
    };
    const singleDayPickerProps = {
      numberOfMonths: 1,
      navbarElement: CalendarNav
    };
    const startDayPickerProps = {
      selectedDays: [
        from, {
          from,
          to
        }
      ],
      disabledDays: {
        after: to
      },
      toMonth: to,
      modifiers,
      numberOfMonths: 2,
      onDayClick: () => this.to.getInput().focus(),
      navbarElement: CalendarNav
    };
    const endDayPickerProps = {
      selectedDays: [
        from, {
          from,
          to
        }
      ],
      disabledDays: {
        before: from
      },
      modifiers,
      month: from,
      fromMonth: from,
      numberOfMonths: 2,
      navbarElement: CalendarNav
    };

    const dayPicker = multi
      ? (<div className="InputFromTo">
        <div className="mobileOnly">
          <input type="date" placeholder="Start Date"/>
          <input type="date" placeholder="End Date"/>
        </div>
        <div className="desktopOnly">
          <DayPickerInput value={from} placeholder="mm/dd/yyyy" format="LL" className="afterFocus" formatDate={formatDate} parseDate={parseDate} dayPickerProps={startDayPickerProps} onDayChange={this.handleFromChange}/>
          <span className="to">to</span>
          <span className="InputFromTo-to">
            <DayPickerInput ref={el => (this.to = el)} value={to} placeholder="mm/dd/yyyy" format="LL" formatDate={formatDate} parseDate={parseDate} dayPickerProps={endDayPickerProps} onDayChange={this.handleToChange}/>
          </span>
        </div>
      </div>)
      : (<DayPickerInput value={from} placeholder="Select Date" format="LL" formatDate={formatDate} parseDate={parseDate} dayPickerProps={singleDayPickerProps} onDayChange={this.handleFromChange}/>);
    return <div className="DatePicker">{dayPicker}</div>;
  }
}
DatePicker.defaultProps = {
  multi: false
};
DatePicker.propTypes = {
  /** bool: should this be a multi-calendar start-end date input  */
  multi: PropTypes.bool
};
export default DatePicker;
