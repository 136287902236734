import React from "react";
import PropTypes from "prop-types";
import NotificationItem from "../NotificationItem/NotificationItem";
import "./MobileNotifications.styl";

class MobileNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {data} = this.props;
    return (<div className="MobileNotifications">
      {data.map((n, i) => (<NotificationItem {...n} key={i}/>))}
    </div>);
  }
}
MobileNotifications.defaultProps = {};
MobileNotifications.propTypes = {
  /** something: string  */
  // something: PropTypes.string.isRequired,
};
export default MobileNotifications;
