import API_BASE_URL from "../lib/apiEndpoint";

export const PRODUCTS_RESPONSE = "PRODUCTS_RESPONSE";
export const ONE_PRODUCT_RESPONSE = "ONE_PRODUCT_RESPONSE";

export const productsResponse = data => ({
  type: PRODUCTS_RESPONSE,
  products: data
});

export const oneProductResponse = data => ({
  type: ONE_PRODUCT_RESPONSE,
  one_product: data
});

export const getProducts = input => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/products`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(productsResponse(data));
      })
      .catch(err => Promise.reject(err));
  };
};

export const getOneProduct = id => {
  return (dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/products?sku=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          return Promise.reject(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        dispatch(oneProductResponse(data[0]));
      })
      .catch(err => Promise.reject(err));
  };
};
