import React from "react";
import { connect } from "react-redux";
import Icon from "../Icon/Icon";
import "./SearchResultsBot.styl";

const FilterTag = ({ label }) => (
  <div className="FilterTag">
    <span>{label}</span>
    <Icon face="times" />
  </div>
);

class SearchResultsBot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { products, history } = this.props;
    return (
      <div className="SearchResultsBot">
        <h3>Previous searches:</h3>
        <FilterTag label="macpro pro 512 ssd 32mb ram 15" />
        <FilterTag label="canon laser printer" />
        <FilterTag label="iphone Xs 256gb storage" />
        <FilterTag label="27 LCD monitor" />
        <FilterTag label="macbook pro 512 ssd 32mb ram 15" />
        <FilterTag label="canon color laser printer" />
      </div>
    );
  }
}

export default connect(state => state)(SearchResultsBot);
