import React from "react";
import PropTypes from "prop-types";
import "./RadioElement.styl";

class RadioElement extends React.Component {
  constructor(props) {
    super(props);
    const { checked } = this.props;
    this.state = {
      checked
    };
  }

  componentWillReceiveProps({ checked }) {
    this.setState({ checked });
  }

  render() {
    const { name, label, className, onClick } = this.props;
    return (
      <div
        className="RadioElement"
        onClick={e => {
          if (onClick) {
            onClick();
          } else {
            this.setState({
              checked: !this.state.checked
            });
          }
        }}
      >
        <input
          name={name}
          type="radio"
          defaultChecked={this.state.checked}
          className={className}
        />
        <label>{label}</label>
      </div>
    );
  }
}
RadioElement.propTypes = {
  /** string: name for form data  */
  name: PropTypes.string,
  /** function: what happens when clicked  */
  onClick: PropTypes.func,
  /** string: label text  */
  label: PropTypes.string.isRequired,
  /** boolean: checked on?  */
  checked: PropTypes.bool,
  /** string: className to add to input */
  className: PropTypes.string
};
export default RadioElement;
