import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./MobileSwitchCarts.styl";
import Button from "../Button/Button";
import { Currency } from "../../lib/formatters";
import Icon from "../Icon/Icon";

const MobileStickyBottom = () => {
  return (
    <div className="MobileStickyBottom">
      <Button icon="shopping-cart" label="Manage Carts" />
    </div>
  );
};

const Thumbs = ({ thumbs }) => {
  const thumbsHtml = thumbs.map((t, i) => {
    return (
      <div className="image" key={i}>
        <img src={t.imageSrc} alt={t.name} />
      </div>
    );
  });

  return (
    <div className="Thumbs">
      {thumbsHtml}
      <div className="plusMore">
        <span>+12 more</span>
      </div>
    </div>
  );
};

const SwitchCartItem = ({ name, total_price, end_user, items, active, id }) => {
  return (
    <div
      className={`SwitchCartItem ${active ? "active" : ""}`}
      onClick={() => (window.location.href = `/cart`)}
    >
      <div className="topLine">
        <div className="name">{name}</div>
        <div className="cartPrice">
          <Currency>{total_price}</Currency>
        </div>
      </div>
      <div className="midLine">
        <div className="endUser">
          <Icon face="user" color="grey2" fontSize={12} kind="light" />
          <span>{end_user}</span>
        </div>
      </div>
      <div className="botLine">
        <Thumbs thumbs={items} />
      </div>
    </div>
  );
};

class MobileSwitchCarts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data } = this.props;
    return (
      <div className="MobileSwitchCarts">
        {data.map((c, i) => {
          return <SwitchCartItem key={i} {...c} />;
        })}
        <MobileStickyBottom />
      </div>
    );
  }
}
MobileSwitchCarts.defaultProps = {};
MobileSwitchCarts.propTypes = {
  /** data: string  */
  // data: PropTypes.array.isRequired,
};
export default connect(state => state)(MobileSwitchCarts);
