import API_BASE_URL from "../lib/apiEndpoint";

export const ONE_CART_RESPONSE = "ONE_CART_RESPONSE";

export const cartResponse = data => ({type: ONE_CART_RESPONSE, cart_items: data});

export const getOneCart = input => {
  return(dispatch, getStore) => {
    const store = getStore();
    fetch(`${API_BASE_URL}/carts?id=${input}`, {
      method: "GET",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      if (!res.ok) {
        return Promise.reject(res.statusText);
      }
      return res.json();
    }).then(data => {
      dispatch(cartItemsResponse(data));
    }).catch(err => Promise.reject(err));
  };
};
