import React from "react";
import Accordion from "../Accordion/Accordion";
import Icon from "../Icon/Icon";
import CheckBox from "../CheckBox/CheckBox";

import "./SearchFilters.styl";

const FilterHeader = ({ children }) => (
  <div className="filterHeader">{children}</div>
);

export default props => {
  return (
    <Accordion
      className="SearchFilters"
      visible={<FilterHeader>{props.header}</FilterHeader>}
      hidden={props.body}
      open={true}
      alignment="leftAlign"
      openButton={<Icon face="chevron-down" fontSize={14} />}
      closeButton={<Icon face="chevron-up" fontSize={14} />}
    />
  );
};
