import React from "react";
import Icon from "../Icon/Icon";
import CheckBox from "../CheckBox/CheckBox";
import Tooltip from "../Tooltip/Tooltip";
import TextInput from "../TextInput/TextInput";
import NumberInput from "../NumberInput/NumberInput";
import MarkupInput from "../MarkupInput/MarkupInput";
import { Currency, NumberFormat } from "../../lib/formatters";
import { labels, icons, colors } from "../../lib/statuses";
import moment from "moment";

export const ColumnQty = props => {
  return (
    <div className="ColumnQty col">
      <NumberInput value={props.children} />
    </div>
  );
};

export const ColumnDate = props => {
  const date = moment(props.children).format("l");
  const bell = props.notify ? (
    <Icon
      face="bell"
      kind="reg"
      color="orange"
      className="bellNotify"
      id={`notification${props.index}`}
    />
  ) : (
    ""
  );

  return (
    <div className={`${props.className} right`}>
      {bell}
      <span>{date}</span>
      <Tooltip triggerElement={`#notification${props.index}`} triggerDelay={0}>
        This is a notification.
      </Tooltip>
    </div>
  );
};

export const ColumnTimeAgo = props => {
  const date = moment(props.children).fromNow();
  return <div className={`right ${props.className}`}>{date}</div>;
};

export const ColumnString = props => {
  const child =
    props.children == parseFloat(props.children) ? (
      <NumberFormat>{props.children}</NumberFormat>
    ) : (
      props.children
    );
  return <div className={props.className}>{child}</div>;
};

export const ColumnID = props => {
  return <div className={props.className}>{props.children}</div>;
};

export const ColumnCurrency = props => {
  return (
    <div className={`right ${props.className}`}>
      <Currency>{props.children}</Currency>
    </div>
  );
};

export const ColumnMarkup = props => {
  return (
    <div className={props.className}>
      <MarkupInput value={props.children} />
    </div>
  );
};
