import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel/Carousel";
import SliderItem from "../Carousel/SliderItem";

import "./ProductCarousel.styl";

class ProductCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: 1
    };
  }

  changeImage = index => {
    this.setState({
      clicked: index
    });
  };

  render() {
    const { clicked } = this.state;
    const activeImage = <img src={`/images/products/monitor${clicked}.jpg`} />;

    return (
      <div className="ProductCarousel">
        <div className="activeImage">{activeImage}</div>
        <Carousel slidesToShow={4} slidesToScroll={1}>
          <SliderItem>
            <img
              src="/images/products/monitor1.jpg"
              onMouseOver={() => this.changeImage(1)}
            />
          </SliderItem>
          <SliderItem>
            <img
              src="/images/products/monitor2.jpg"
              onMouseOver={() => this.changeImage(2)}
            />
          </SliderItem>
          <SliderItem>
            <img
              src="/images/products/monitor3.jpg"
              onMouseOver={() => this.changeImage(3)}
            />
          </SliderItem>
          <SliderItem>
            <img
              src="/images/products/monitor4.jpg"
              onMouseOver={() => this.changeImage(4)}
            />
          </SliderItem>
          <SliderItem>
            <img
              src="/images/products/monitor5.jpg"
              onMouseOver={() => this.changeImage(5)}
            />
          </SliderItem>
        </Carousel>
      </div>
    );
  }
}

export default ProductCarousel;
