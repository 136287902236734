import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import InStock from "../InStock/InStock";
import CartItemInfo from "../CartItemInfo/CartItemInfo";
import { Currency } from "../../lib/formatters";
import NumberInput from "../NumberInput/NumberInput";
import AddToCart from "../AddToCart/AddToCart";
import Button from "../Button/Button";
import "./SelectReplacementPanel.styl";

const ReplacementItem = props => {
  const { imageSrc, qty, vpn, user_price, sku, upc, name } = props;
  return (
    <div className="ReplacementItem">
      <div className="inner">
        <div className="group">
          <div className="image-wrap">
            <img src={imageSrc} alt={name} />
          </div>
        </div>
        <div className="group">
          <p className="product-name small">{name}</p>
          <div className="id-numbers">
            <span>VPN:&nbsp;</span>
            <span>{vpn}</span>
            <span className="spacer">|</span>
            <span>SKU:&nbsp;</span>
            <span>{sku}</span>
            <span className="spacer">|</span>
            <span>UPC:&nbsp;</span>
            <span>{upc}</span>
          </div>
          <div className="col2 replaceButton">
            <AddToCart label="Replace" added={false} />
            <InStock qty={qty} />
          </div>
        </div>
        <div className="group">
          <span className="price">
            <Currency>{user_price}</Currency>
          </span>
        </div>
      </div>
    </div>
  );
};

const ReplacementList = props => {
  const list = props.listItems.map((item, i) => {
    return <ReplacementItem {...item} key={i} />;
  });
  return <div className="ReplacementList">{list}</div>;
};

class SelectReplacementPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { cart_items } = this.props;
    return (
      <div className="SelectReplacementPanel">
        <h2>
          <span>Select Replacement</span>
        </h2>
        <p>
          We don't currently have the quantity of this product that you have on
          your order. Please consider these alternatives.
        </p>
        <ReplacementList listItems={cart_items.slice(0, 5)} />
      </div>
    );
  }
}
SelectReplacementPanel.defaultProps = {};
SelectReplacementPanel.propTypes = {};
export default connect(state => state)(SelectReplacementPanel);
