import React from "react";
import { connect } from "react-redux";
import CartItemInfo from "../CartItemInfo/CartItemInfo";
import Icon from "../Icon/Icon";
import InStock from "../InStock/InStock";
import NumberInput from "../NumberInput/NumberInput";
import Button from "../Button/Button";
import { rowNotices } from "../../lib/rowNotices";
import { Currency } from "../../lib/formatters";
import "./MobileCartTable.styl";
import {
  addressPanel,
  notesPanel,
  selectWarehousePanel
} from "../../actions/panel";
import { cartItemsChangeQty } from "../../actions/cart_items";

const MobileCartTable = props => {
  const { data, dispatch } = props;
  const items = data.map((d, index) => {
    const {
      imageSrc,
      qty,
      user_qty,
      vpn,
      user_price,
      sku,
      upc,
      name,
      end_user,
      flags,
      warehouse
    } = d;
    const line_price = user_qty * user_price;

    const endUserLabel = end_user ? end_user.company_name : "Add End User";

    return (
      <div className="MobileTableItem" key={index}>
        {rowNotices(d, dispatch)}
        <CartItemInfo {...d} dispatch={dispatch} />
        <div className="infoItem">
          <span className="left">
            <NumberInput
              value={user_qty}
              clickUp={uq => dispatch(cartItemsChangeQty(sku, uq))}
              clickDown={uq => dispatch(cartItemsChangeQty(sku, uq))}
              onChange={uq => dispatch(cartItemsChangeQty(sku, uq))}
            />
          </span>
          <span className="right">
            <Currency>{line_price}</Currency>
          </span>
        </div>
        <div
          className="infoPanel in-stock"
          onClick={e => {
            e.preventDefault();
            dispatch(selectWarehousePanel(sku, warehouse.name));
          }}
        >
          <Icon face="dolly" color="blue" />
          <span className="selectWarehouse">
            <span>{(warehouse && warehouse.name) || "Select Warehouse"}</span>
            <InStock qty={warehouse && warehouse.qty} />
          </span>
          <Icon face="chevron-right" color="blue" />
        </div>
        <div
          className="infoPanel"
          onClick={e => {
            e.preventDefault();
            dispatch(addressPanel(sku));
          }}
        >
          <Icon face={end_user ? "user" : "user-plus"} color="blue" />
          <span>{endUserLabel}</span>
          <Icon face="chevron-right" color="blue" />
        </div>
        <div
          className="infoPanel"
          onClick={e => {
            e.preventDefault();
            dispatch(notesPanel());
          }}
        >
          <Icon face="file-plus" color="blue" />
          <span>Add Notes</span>
          <Icon face="chevron-right" color="blue" />
        </div>
      </div>
    );
  });
  return <div className="MobileCartTable">{items}</div>;
};
export default connect(state => state)(MobileCartTable);
