import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { nextPage } from "../../actions/pagination";
import Icon from "../../components/Icon/Icon";
import Header from "../../components/Header/Header";
import Tooltip from "../../components/Tooltip/Tooltip";
import QuoteListHeader from "../../components/QuoteListHeader/QuoteListHeader";
import Footer from "../../components/Footer/Footer";
import QuoteSearch from "../../components/QuoteSearch/QuoteSearch";
import Pagination from "../../components/Pagination/Pagination";
import TableHeader from "../../components/TableHeader/TableHeader";
import FixedHeader from "../../components/FixedHeader/FixedHeader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import MobileTable from "../../components/Table/MobileTable";
import Table from "../../components/Table/Table";
import {
  ColumnID,
  ColumnDate,
  ColumnString,
  ColumnCurrency,
  ColumnTimeAgo
} from "../../components/Table/TableColumns";
import filterQuotes from "../../lib/quoteFilters";
import { getQuotes } from "../../actions/quotes";
import moment from "moment";
import { labels, icons, colors } from "../../lib/statuses";
import {
  NumberFormat,
  TimeAgo,
  Currency,
  DateFormat
} from "../../lib/formatters";
import "./QuoteList.styl";
import quoteTableHeadings from "../../../data/quote-list-headings.js";

const Status = props => {
  // Props children must be an int between 1 and 5.
  const index = props.children - 1; // Child is a number 1-5
  return (
    <div className={`icon ${index}`}>
      <Icon face={icons[index]} color={colors[index]} className="statusIcon" />
    </div>
  );
};

const RowPanel = props => {
  const id = props.id;
  return (
    <div className="rowPanel">
      <Icon face="copy" id={`copyIcon${id}`} />
      <Icon face="archive" id={`archiveIcon${id}`} />
      <Icon face="cart-plus" id={`cartIcon${id}`} />
      <Icon face="ban" id={`banIcon${id}`} />

      <Tooltip triggerElement={`#copyIcon${id}`} theme="dark">
        duplicate
      </Tooltip>
      <Tooltip triggerElement={`#cartIcon${id}`} theme="dark">
        add to cart
      </Tooltip>
      <Tooltip triggerElement={`#archiveIcon${id}`} theme="dark">
        archive
      </Tooltip>
      <Tooltip triggerElement={`#banIcon${id}`} theme="dark">
        delete
      </Tooltip>
    </div>
  );
};

export const ColumnStatus = props => {
  // Props children must be an int between 1 and 5.
  const index = props.children - 1;
  return (
    <div className={props.className}>
      <Icon face={icons[index]} color={colors[index]} className="statusIcon" />
      <span>{labels[index]}</span>
      <RowPanel id={props.index} />
    </div>
  );
};

const QuoteListHeadingPanel = props => {
  const { numRowsSelected, version } = props;
  const shown = numRowsSelected > 0;
  return (
    <div className={`HeadingPanel ${shown ? "shown" : ""}`}>
      <div className="headingActions">
        <Icon face="copy" id={`copyIcon${version}`} />
        <Icon face="calendar-alt" id={`calendarIcon${version}`} />
        <Icon face="archive" id={`archiveIcon${version}`} />
        <Icon face="ban" id={`banIcon${version}`} />
      </div>
      <span className="numRows">
        <NumberFormat>{numRowsSelected}</NumberFormat>
        &nbsp;items selected
      </span>
      <Tooltip triggerElement={`#copyIcon${version}`} theme="dark">
        duplicate
      </Tooltip>
      <Tooltip triggerElement={`#calendarIcon${version}`} theme="dark">
        expire
      </Tooltip>
      <Tooltip triggerElement={`#archiveIcon${version}`} theme="dark">
        archive
      </Tooltip>
      <Tooltip triggerElement={`#banIcon${version}`} theme="dark">
        delete
      </Tooltip>
    </div>
  );
};

export const MobileTop = props => (
  <div className="top">
    <div className="icon">
      <Status>{props.status}</Status>
    </div>
    <div className="info">
      <span className="date">
        <TimeAgo>{props.created_on}</TimeAgo>
      </span>
      <h2>{props.quote_name}</h2>
      <span className="quoteId">{props.quote_id}</span>
    </div>
  </div>
);

export const MobileBottom = props => (
  <div className="bottom" onClick={() => props.rowClick(props.quote_id)}>
    <hr />
    <div className="infoItem">
      <span className="left">End User:</span>
      <span className="right">{props.end_user}</span>
    </div>
    <div className="infoItem">
      <span className="left">Quote Value:</span>
      <span className="right">
        <Currency>{props.total_price}</Currency>
      </span>
    </div>
    <div className="infoItem">
      <span className="left">Date Created:</span>
      <span className="right">
        <DateFormat>{props.created_on}</DateFormat>
      </span>
    </div>
    <div className="infoItem">
      <span className="left">Expiration Date:</span>
      <span className="right">
        <TimeAgo>{props.exp_date}</TimeAgo>
      </span>
    </div>
    <div className="infoItem">
      <span className="left">Created By:</span>
      <span className="right">{props.created_by}</span>
    </div>
  </div>
);

class QuoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: props.quotes,
      page: 1,
      limit: 20,
      selectedRows: {},
      allSelected: false,
      sortAsc: true,
      sortCol: 0
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.dispatch(getQuotes());
    }, 2000);
  }

  setSort = index => {
    const { sortAsc } = this.state;
    this.setState({
      sortCol: index,
      sortAsc: !sortAsc
    });
  };

  toggleHighlight = id => {
    let selected = this.state.selectedRows;
    if (selected[id]) delete selected[id];
    else selected[id] = true;
    this.setState({ selectedRows: selected });
  };

  toggleSelectAll = () => {
    let selectedRows = {};
    if (this.state.allSelected) {
      this.setState({ allSelected: false, selectedRows });
    } else {
      this.props.quotes.map(x => {
        selectedRows[x.id] = true;
      });
      this.setState({ selectedRows, allSelected: true });
    }
  };

  render() {
    const { selectedRows, allSelected, sortAsc, sortCol } = this.state;
    const numRowsSelected = Object.keys(selectedRows).length;

    const { quotes, filter, history, pagination } = this.props;

    const filteredQuotes = filterQuotes(quotes, filter);

    // Todo: Do notifications a different way.
    if (filteredQuotes.length > 0) {
      for (var i = 0; i < 3; i++) {
        filteredQuotes[i].notify = true;
      }
    }

    const loading = !quotes;
    return (
      <div className={`QuoteList ${loading ? "loading" : ""}`}>
        <Header history={history} />
        <main>
          <div className="desktopOnly">
            <Breadcrumbs
              trail={[
                {
                  link: "/",
                  label: "Home"
                },
                {
                  link: null,
                  label: "Quotes"
                }
              ]}
            />
          </div>
          <QuoteListHeader history={history} />
          <FixedHeader>
            <table className="Table">
              <tbody>
                <TableHeader
                  numRowsSelected={numRowsSelected}
                  allSelected={allSelected}
                  headings={quoteTableHeadings}
                  setSort={this.setSort}
                  sortCol={sortCol}
                  sortAsc={sortAsc}
                  version={"Fixed"}
                  selectable={true}
                  headingActions={QuoteListHeadingPanel}
                  toggleSelectAll={this.toggleSelectAll}
                />
              </tbody>
            </table>
          </FixedHeader>
          <Table
            sortCol={sortCol}
            headingActions={QuoteListHeadingPanel}
            setSort={this.setSort}
            rowClick={id => history.push(`/quotes/create/${id}`)}
            page={pagination.currentPage}
            history={history}
            limit={pagination.limit}
            headings={quoteTableHeadings}
            columns={[
              ColumnDate,
              ColumnString,
              ColumnID,
              ColumnString,
              ColumnString,
              ColumnCurrency,
              ColumnTimeAgo,
              ColumnTimeAgo,
              ColumnStatus
            ]}
            data={filteredQuotes}
            selectedRows={selectedRows}
            toggleHighlight={this.toggleHighlight}
            sortAsc={sortAsc}
            allSelected={allSelected}
            toggleSelectAll={this.toggleSelectAll}
          />
          <MobileTable
            top={MobileTop}
            bottom={MobileBottom}
            data={filteredQuotes}
            sortAsc={sortAsc}
            page={pagination.currentPage}
            history={history}
            limit={pagination.limit}
          />
          <div className="paginationCont">
            <Pagination
              totalResults={filteredQuotes.length}
              currentPage={pagination.currentPage}
              perPage={pagination.limit}
            />
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

QuoteList.defaultProps = {
  quotes: [
    {
      created_on: "null",
      created_by: null,
      quote_name: null,
      quote_id: null,
      end_user: null,
      last_updated: null,
      exp_date: null,
      status: null
    }
  ]
};

export default connect(state => state)(QuoteList);
