import React from "react";
import "./ProductRecommendations.styl";
import Carousel from "../Carousel/Carousel";
import SliderItem from "../../components/Carousel/SliderItem";
import ProductListing from "../ProductListing/ProductListing";

const ProductRecommendations = ({ heading, products }) => {
  return (
    <div className="ProductRecommendations">
      <h2 className="productListingHeading">{heading}</h2>
      <Carousel slidesToShow={4} slidesToScroll={1}>
        {products.map((product, i) => {
          return (
            <SliderItem key={i}>
              <ProductListing {...product} />
            </SliderItem>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductRecommendations;
