import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CheckoutSection from "../../components/CheckoutSection/CheckoutSection";
import RadioElement from "../../components/RadioElement/RadioElement";
import CheckoutTotal from "../../components/CheckoutTotal/CheckoutTotal";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { Helmet } from "react-helmet";
import SummaryItems from "../../components/SummaryItems/SummaryItems";
import "./CheckoutConfirm.styl";

import { getOneCartCheckout } from "../../actions/cart";
import { getCartItems } from "../../actions/cart_items";

const one_cart = {
  name: "My Cart"
};

class CheckoutConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(getCartItems());
  }

  render() {
    const { cart_items, history } = this.props;
    const numItems = cart_items.length;
    return (
      <div className="CheckoutConfirm">
        <Helmet title="Ingram Micro - Confirm Success!" />
        <Header history={history} />
        <main>
          <div className="left">
            <CheckoutSection>
              <div className="inner">
                <h1 className="confirmGreen">
                  <Icon face="check" fontSize={36} color="green" />
                  Order Confirmation
                </h1>
              </div>
              <div className="inner">
                <p>
                  <b>Order Number(s):&nbsp;</b>
                  <a href="#">20TPMY411</a>
                </p>
                <p>
                  <b>PO Number(s):&nbsp;</b>
                  <span>20TPMY411</span>
                </p>
                <p>
                  <b>Order date:&nbsp;</b>
                  <span>3/26/2019</span>
                </p>
                <p>
                  <b>End Use PO number:&nbsp;</b>
                  <span>1348141</span>
                </p>
                <p>
                  <b>IP Address:&nbsp;</b>
                  <span>195.33.15.22</span>
                </p>
                <p>Thank you for your order! We apprciate your business.</p>
                <p>
                  An email confirmation has been sent to&nbsp;
                  <b>john.smoth@ingrammicro.com</b>
                </p>
                <div className="buttons">
                  <Button kind="secondary" label="View Orders" />
                  <Button kind="secondary" label="Check Order Status" />
                </div>
              </div>
            </CheckoutSection>

            <CheckoutSection>
              <div className="inner">
                <h1>Order Details</h1>
              </div>
              <div className="inner">
                <div className="paymentType">
                  <h4>Payment Type</h4>
                  <p>Terms</p>
                </div>
                <div className="spacer addresses">
                  <h4>Billing Addresses</h4>
                  <p>
                    Company : INGRAM MICRO CAPS TEST ACCOUNT
                    <br />
                    1759 WEHRLE DR
                    <br />
                    WILLIAMSVILLE, NY 142217033
                    <br />
                    Country : United States
                  </p>
                </div>
                <div className="spacer addresses">
                  <h4>Shipping Addresses</h4>
                  <p>
                    Company: INGRAM MICRO CAPS TEST ACCOUNT
                    <br />
                    1759 WEHRLE DR
                    <br />
                    WILLIAMSVILLE, NY 142217033
                    <br />
                    Country: United States
                  </p>
                </div>
                <div className="spacer shippingDetail">
                  <h4>Shipping</h4>
                  <p>
                    Items will be delivered as soon as theybecome available.
                  </p>
                  <p>
                    Your order will be shipping from your default warehouse.
                  </p>
                  <p className="spacer">
                    <b>Order confirmation:&nbsp;</b>Mira Loma, CA
                  </p>
                  <p>
                    <b>Shipping Carrier:&nbsp;</b>FEDX P1 SAT
                  </p>
                  <p>
                    <b>Expected Transit Days:&nbsp;</b>1
                  </p>
                </div>
              </div>
            </CheckoutSection>
          </div>
          <div className="right">
            <CheckoutSection>
              <div className="inner">
                <h1>Summary ({numItems}&nbsp; items)</h1>
              </div>
              <div className="inner">
                <CheckoutTotal
                  shippableSubtotal={13343}
                  numItems={numItems}
                  backorderedSubtotal={9289}
                  total={22632}
                  readOnly={true}
                />
              </div>
              <div className="inner">
                <SummaryItems data={cart_items} readOnly={true} />
              </div>
            </CheckoutSection>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(state => state)(CheckoutConfirm);
