import React from "react";
import PropTypes from "prop-types";
import Icon from "../../components/Icon/Icon";
import "./QuoteActivity.styl";

const data = [
  {
    face: "shopping-cart",
    color: "green",
    status: "Order Placed",
    time: "Today"
  }, {
    face: "check-circle",
    color: "grey2",
    status: "End user, Clifford Swanson for approval",
    time: "Today"
  }, {
    face: "arrow-circle-right",
    color: "grey2",
    status: "End user quote ready for approval",
    time: "Dec 20"
  }, {
    face: "check-circle",
    color: "grey2",
    status: "QUO-GVX678 approved by Nayah Tantoh",
    time: "Dec 19"
  }, {
    face: "exclamation-circle",
    color: "grey2",
    status: "Requested quote ready for approval",
    time: "Dec 18"
  }, {
    face: "arrow-circle-right",
    color: "grey2",
    status: "Quote request send to IM Sales",
    time: "Dec 17"
  }, {
    face: "plus-circle",
    color: "grey2",
    status: "Quote QUO-GVX678 created by Nayah Tantoh",
    time: "Dec 17"
  }
];

const Timeline = props => {
  const {face, status, time, color} = props;
  return (<div className="Timeline">
    <div className="left">
      <Icon face={face} color={color}/>
    </div>
    <div className="middle">{status}</div>
    <div className="right">{time}</div>
  </div>);
};

class QuoteActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (<div className="QuoteActivity">
      <h2>Quote Activity</h2>
      <div className="inner">
        <div className="verticalLine"/>
        <div className="times">
          {
            data.map((line, i) => {
              return <Timeline key={i} {...line}/>;
            })
          }
        </div>
      </div>
    </div>);
  }
}
QuoteActivity.defaultProps = {};
QuoteActivity.propTypes = {};
export default QuoteActivity;
