import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./CartActions.styl";
import Button from "../../components/Button/Button";
import CartActionButtons from "../../components/CartActionButtons/CartActionButtons";
import {
  switchCartPanel,
  cartSettingsPanel,
  quickOrderPanel
} from "../../actions/panel";
import { Currency } from "../../lib/formatters";

class CartActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { subtotal } = this.props;
    return (
      <div className="CartActions">
        <div className="inner">
          <div className="left">
            <CartActionButtons {...this.props} />
          </div>
          <div className="right mobileFixed">
            <div className="mobileTotal">
              <span className="subtotal">Subtotal&nbsp;</span>
              <Currency className="subtotal">{subtotal}</Currency>
            </div>
            <Button
              label="Checkout"
              icon="arrow-circle-right"
              kind="primary"
              color="orange"
              size="fullwidth"
              href="/checkout"
            />
          </div>
        </div>
      </div>
    );
  }
}
CartActions.defaultProps = {};
CartActions.propTypes = {};
export default connect(state => state)(CartActions);
