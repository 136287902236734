import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import Table from "../Table/Table";
import CartItemInfo from "../CartItemInfo/CartItemInfo";
import { ColumnQty, ColumnString } from "../Table/TableColumns";
import "./SplitLinePanel.styl";

const product = {
  imageSrc: "/images/products/product1.png",
  name:
    "Apple Mac mini MGEM2LL/A Desktop Computer - Intel Core i5 1.40 GHz - 4 GB LPDDR3 - 500 GB HDD - Wireless",
  qty: 115,
  user_qty: 5,
  sku: "JH8932HJDJ",
  vpn: "DSHKF92303",
  upc: "983799843798",
  markup: 81.72,
  user_price: 600.0,
  msrp: 1000.0,
  original_price: 1200.0,
  vendor_name: "Apple",
  flags: [1, 2, 3]
};

const headings = [
  {
    label: "Quantity",
    key: "quantity"
  },
  {
    label: "Warehouse",
    key: "warehouse"
  },
  {
    label: "Stock",
    key: "in_stock",
    align: "right"
  }
];

// Filler data @Remove when pulling real stuff.
const warehouses = [
  {
    warehouse: "Mira Loma, CA",
    in_stock: 10,
    quantity: 0
  },
  {
    warehouse: "Carol Stream, IL",
    in_stock: 71,
    quantity: 0
  },
  {
    warehouse: "Millington, TN",
    in_stock: 0,
    quantity: 0
  }
];

class SplitLinePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="SplitLinePanel">
        <h2>
          <span>Split Lines</span>
        </h2>
        <div className="inner">
          <p className="info">
            You can split this line across multiple warehouses.
          </p>
          <CartItemInfo {...product} />
          <Table
            className="WarehouseList"
            selectable={false}
            headings={headings}
            data={warehouses}
            columns={[ColumnQty, ColumnString, ColumnString]}
          />
          <div className="buttons">
            <Button
              label="Cancel"
              kind="secondary"
              size="small"
              onClick={this.props.closeClick}
            />
            <Button
              label="Update Cart"
              kind="primary"
              size="large"
              onClick={this.props.closeClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
SplitLinePanel.defaultProps = {};
SplitLinePanel.propTypes = {};
export default SplitLinePanel;
